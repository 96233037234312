import BaseStore from "./BaseStore";
import assign from "object-assign";
import Dispatcher from "../dispatcher/Dispatcher";
import Constants from "../constants/constants";
import GrundDatenStore from "./GrundDatenStore";
import _ from "lodash";

let imgDataUrl = "";
let data = {
  system: {
    selected: { label: "Einfeldscheibe", value: 1 },
    auswahl: [
      { label: "Einfeldscheibe", value: 1 },
      { label: "Kragscheibe", value: 2 },
      { label: "Innenfeld", value: 3 },
      { label: "Endfeld", value: 4 },
    ],
  },
  ausrichtung: {
    auswahl: [
      { label: "quer", value: 0 },
      { label: "längs", value: 1 },
    ],
    selected: { label: "quer", value: 0 },
  },
  sL: 45.0,
  sH: 11.0,
  az: 1.2,
  qd: 6.3,
  Md: 0.0,
  Vd: 0.0,
  z: 0.0,
  FEd1: 0.0,
  FEdmin1: 0.0,
  FEd2: 0.0,
  FEdmin2: 0.0,
  FEd3: 0.0,
  FEdmin3: 0.0,
  FEd4: 0.0,
  FEdmin4: 0.0,
  b0: 1.2,
  gewAs5d: 12,
  gewAs5n: 0,
  gewAs5: 1.13,
  ZP: 1.0,
  Asd1: 0.0,
  Asd2: 0.0,
  Asd3: 0.0,
  Asd4: 0.0,
  Asd5: 0.0,
  VRdmax: 0.0,
  cot: 0.0,
  VV: 0.0,
  vEd: 0.0,
  vRdct: 0.0,
};

function calculate() {
  let decke = GrundDatenStore.getSelectedDecke();
  let scheiben = GrundDatenStore.getScheibenData();
  let stype = data.system.selected.value;
  let heff = _.get(scheiben.spiegel, decke.Plattentype) / 100.0;
  data.h = parseFloat(decke.dicke);
  let LH = data.sL / data.sH;
  switch (stype) {
    case 1: {
      data.Md = data.qd * data.sL * data.sL * 0.125;
      data.Vd = data.qd * data.sL * 0.5;
      if (LH < 2.0) {
        if (LH > 1.0) data.z = 0.3 * data.sH * (3.0 - data.sH / data.sL);
        else data.z = 0.6 * data.sL;
      } else data.z = 0.75 * data.sH;
      break;
    }
    case 2: {
      data.Md = data.qd * data.sL * data.sL * 0.5;
      data.Vd = data.qd * data.sL;
      if (LH < 2.0) {
        if (LH > 1) data.z = 0.65 * data.sH + 0.1 * data.sL;
        else data.z = 0.85 * data.sL;
      } else data.z = 0.85 * data.sH;
      break;
    }
    case 3: {
      data.Md = data.qd * data.sL * data.sL * 0.107;
      data.Vd = data.qd * data.sL * 0.607;
      if (LH < 3.333) {
        if (LH > 1.0) data.z = 0.5 * data.sH * (1.8 - data.sH / data.sL);
        else data.z = 0.4 * data.sL;
      } else data.z = 0.75 * data.sH;
      break;
    }
    case 4: {
      data.Md = data.qd * data.sL * data.sL * 0.125;
      data.Vd = data.qd * data.sL * 0.625;
      if (LH < 2.5) {
        if (LH > 1.0) data.z = 0.5 * data.sH * (1.9 - data.sH / data.sL);
        else data.z = 0.45 * data.sL;
      } else data.z = 0.75 * data.sH;
      break;
    }
    // no default
  }
  data.a0 = Math.min(data.az, data.sL - data.az);
  data.tf = data.h * 0.008;
  // 1.
  data.FEd1 = data.Md / data.z;
  data.FEdmin1 = Math.max(70.0, 10 * data.sH);
  // FEdmin1 = min(70.0, 10 * sH);
  data.Asd1 = Math.max(data.FEdmin1, data.FEd1) / 43.5;
  data.cot = data.az / data.z;
  data.sin = Math.sin(Math.atan(1.0 / data.cot));
  data.VRdmax = heff * 0.7 * (20.0 / 1.5) * data.sin * 1000.0;
  data.VV = 0.0;
  if (data.VRdmax != 0.0) data.VV = data.Vd / data.VRdmax;
  // 2.

  if (data.ausrichtung.selected.value == 1 && data.az >= data.sL) {
    data.FEd2 = 0.0;
    data.FEdmin2 = 0.0;
  } else {
    data.FEd2 = (data.Vd - data.qd * data.a0) * data.ZP;
    data.FEdmin2 = Math.max(70.0, 10.0 * data.a0) * data.ZP;
    //FEdmin2 = max(20.0, 10.0 * a0) * ZP;WS
  }
  data.Asd2 = Math.max(data.FEdmin2, data.FEd2) / 43.5;
  // 3.

  if (data.ZP > 0.0) {
    data.FEd3 = (data.b0 * data.FEd2) / data.sH;
  } else {
    data.FEd3 = (data.b0 / data.sH) * (data.Vd - data.qd * data.b0);
  }
  // FEdmin3 = min(70.0, 70.0);
  data.FEdmin3 = Math.min(70.0, data.b0 * 20.0);
  data.Asd3 = Math.max(data.FEdmin3, data.FEd3) / 43.5;
  // 4.
  data.FEd4 = data.Vd;
  data.FEdmin4 = Math.max(70.0, 10.0 * data.sH);
  // FEdmin4 = min(70.0, 10.0 * sH);
  data.Asd4 = Math.max(data.FEdmin4, data.FEd4) / 43.5;
  // 5.
  data.vEd = data.Vd / data.sH;
  data.vRdct = Math.min(
    data.tf * 150.0,
    42.0 * 1.4 * Math.pow(20.0, 1.0 / 3.0) * data.tf
  );

  if (data.vEd > data.vRdct) {
    data.Asd5 = data.Vd / 43.5;
  } else {
    data.Asd5 = 0.0;
  }
  data.gewAs5n = Math.max(2, Math.ceil((data.Asd5 / data.gewAs5) * 0.5));
  toDataURL(
    "assets/images/scheiben/shell" +
      data.system.selected.value +
      "" +
      data.ausrichtung.selected.value +
      ".png",
    (du) => {
      imgDataUrl = du;
    }
  );
}

function toDataURL(src, callback) {
  var image = new Image();
  image.crossOrigin = "Anonymous";
  image.onload = function () {
    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    canvas.height = this.naturalHeight;
    canvas.width = this.naturalWidth;
    context.drawImage(this, 0, 0);
    var dataURL = canvas.toDataURL("image/jpeg");
    callback(dataURL);
  };
  image.src = src;
}
const ScheibenStore = assign({}, BaseStore, {
  getAll() {
    return { ...data };
  },
  getImage(){
    return imgDataUrl;
  },

  dispatcherIndex: Dispatcher.register(function handleAction(payload) {
    const action = payload.action;

    switch (action.type) {
      case Constants.ActionTypes.SCHEIBEN_EINGABE:
        if (action.path) {
          _.set(data, action.path, action.data);
        }
        calculate();
        ScheibenStore.emitChange();
        break;
      default:
        Dispatcher.waitFor([GrundDatenStore.dispatcherIndex]);

        calculate();
        ScheibenStore.emitChange();

      // add more cases for other actionTypes...

      // no default
    }
  }),
});

export default ScheibenStore;
