import PropTypes from 'prop-types';
import React, {Component} from 'react';
var ReactPropTypes = PropTypes;

export default class LNImage extends Component {
    render() {
        let {mode, src, height, width, style, ...props} = this.props;
        let modes = {
            'fill': 'cover',
            'fit': 'contain'
        };
        let size = modes[mode] || 'contain';

        let defaults = {
            height: height || 100,
            width: width || 100,
            //backgroundColor: 'gray'
        };

        let important = {
            backgroundImage: `url("${src}")`,
            backgroundSize: size,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat'
        };

        return <div {...props} style={{...defaults, ...style, ...important}} />;
    }
}
LNImage.propTypes = {
    width:ReactPropTypes.number,
    height:ReactPropTypes.number,
    src:ReactPropTypes.string,
    mode:ReactPropTypes.string,
    style:ReactPropTypes.object,
    children:ReactPropTypes.array
};
