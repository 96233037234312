import PropTypes from "prop-types";
import React from "react";
import GrundDatenView from "./GrundDaten";
import DataGrid from "../../components/common/DataGrid";
import LNImage from "../../components/common/LNImage";
import GrundDatenStore from "../../stores/GrundDatenStore";
import CalcStore from "../../stores/CalcStore";
import Utils from "../../utils/UtilsStore";
import ActionCreator from "../../actions/StatikActionCreator";
import { Switch, Route, withRouter } from "react-router-dom";
import Geometrie from "./Geometrie";
import Belastungen from "./Belastungen";
import ZusatzLasten from "./ZusatzLasten";
import Aussparungen from "./Aussparungen";
import Ausgabe from "./Ausgabe";

var ReactPropTypes = PropTypes;
class StatikCalc extends React.Component {
  constructor() {
    super();
    this._onChange = this._onChange.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._onSelectBewehrung = this._onSelectBewehrung.bind(this);
    this._formatValue = this._formatValue.bind(this);
    this._detailButtonClicked = this._detailButtonClicked.bind(this);
    this.selectedItem = null;
    this.state = {
      bewehrung: CalcStore.getBewehrungen(),
      skizzen: GrundDatenStore.getSkizzen(),
      decke: GrundDatenStore.getSelectedDecke()
    };
  }

  _handleChange() {}

  _onChange() {
    console.log("statik change");
    this.setState({
      bewehrung: CalcStore.getBewehrungen(),
      skizzen: GrundDatenStore.getSkizzen(),
      decke: GrundDatenStore.getSelectedDecke()
    });
    let selb = CalcStore.getSelectedBewehrung();
    // if(selb.length > 0){
    this.selectedItem = selb[0];
    // }
  }

  componentDidMount() {
    CalcStore.addChangeListener(this._onChange);
  }

  componentWillUnmount() {
    CalcStore.removeChangeListener(this._onChange);
  }
  _onSelectBewehrung() {}
  _formatValue(v) {
    if (typeof v === "number") {
      return Utils.round(v);
    }
    return v;
  }

  _trClass(item) {
    var cln =
      item.Error == ""
        ? item.warn3Percent == ""
          ? "success"
          : "warning"
        : "danger";
    /*if(item.selected){
        cln = 'info';
      }*/
    return cln;
  }
  _detailButtonClicked(item) {
    console.log("detail", item);
    if (item.Error != "") {
      // return;
    }
    let selb = CalcStore.getSelectedBewehrung()[0];

    if (selb && item !== selb) {
      selb.selected = false;
    }
    item.selected = true;
    // this.selectedItem = (item.selected)?item:undefined;
    // console.log("sel",item,item.selected);
    this.setState({
      bewehrung: CalcStore.getBewehrungen(),
      skizzen: GrundDatenStore.getSkizzen()
    });
    if (item.selected) {
      ActionCreator.selectBewehrung(this.state.bewehrung);
    }

    this.props.history.push("/calc/ausgabe");
  }

  render() {
    const { path, url } = this.props.match;
    return (
      <div className="pad-hor-15">
        <GrundDatenView />

        <div>
          <div className="row">
            <div className="col-md-6">
              <Switch>
                <Route path={`${path}/`}>
                  <Route path={`${path}/geometrie`} component={Geometrie} />
                  <Route path={`${path}/belastung`} component={Belastungen} />
                  <Route
                    path={`${path}/zusatzlasten`}
                    component={ZusatzLasten}
                  />
                  <Route
                    path={`${path}/aussparungen`}
                    component={Aussparungen}
                  />
                  <Route path={`${path}/ausgabe`} component={Ausgabe} />
                </Route>
              </Switch>
              {this.props.children}
            </div>
            <div className="col-md-6">
              <h3>Mögliche Bewehrungen</h3>
              <hr />
              <DataGrid
                headers={this.state.bewehrung.headers}
                buttonClick={this._detailButtonClicked}
                dataProvider={this.state.bewehrung.data}
                trClass={this._trClass}
                onSelect={this._onSelectBewehrung}
                valueFormatter={this._formatValue}
              />
              <h3>Deckentyp</h3>
              <hr />
              <small>
                Eigenlast Decke g:{" "}
                {("" + this.state.decke.SelfWeight || "0.0").replace(".", ",")}{" "}
                kN/m²
              </small>
              <LNImage
                width={Math.min(window.outerWidth, 500)}
                height={200}
                src={"assets/images/skizzen/" + this.state.skizzen.selected}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(StatikCalc);

StatikCalc.propTypes = {
  children: ReactPropTypes.object
};
