import React from "react";
//import ContentEditable from 'react-contenteditable';
import GrundDatenStore from "../../stores/GrundDatenStore";
import ActionCreator from "../../actions/StatikActionCreator";
import DropDown from "../../components/common/DropDown";
import LNImage from "../../components/common/LNImage";
import LNContentEditable from "../../components/common/LNContentEditable";
import _ from "lodash";
class GrundDaten extends React.Component {
  constructor(props) {
    super(props);
    this.bauvorhaben = React.createRef();
    this.bauposition = React.createRef();
    this.state = GrundDatenStore.getAll();
  }

  handleChange(evt) {
    //console.log(this.refs);
    console.log("c", evt.target);
    ActionCreator.setEingaben({
      bauvorhaben: this.bauvorhaben.current.htmlEl.innerText,
      bauposition: this.bauposition.current.htmlEl.innerText
    });
    // this.setState({bauvorhaben: this.refs.bauvorhaben.htmlEl.innerText,bauposition:this.refs.bauposition.htmlEl.innerText,baustatikseite:this.refs.baustatikseite.htmlEl.innerText});
  }

  _onChange() {
    let np = GrundDatenStore.getAll();
    this.setState(np);
  }

  componentDidMount() {
    GrundDatenStore.addChangeListener(this._onChange.bind(this));
  }

  componentWillUnmount() {
    GrundDatenStore.removeChangeListener(this._onChange.bind(this));
  }
  _onSelectDecke(item) {
    ActionCreator.selectDecke(item);
  }
  _onSelectDeckenTyp(item) {
    ActionCreator.selectDeckenTyp(item);
  }
  _onSelectExpo(item) {
    ActionCreator.selectExpo(item);
  }
  _onSelectDeckenDicke(item) {
    ActionCreator.selectDeckenDicke(item);
  }

  render() {
    let self = this;
    let deckenauswahl = this.state.deckenWerte.decken.auswahl.filter(function (
      d
    ) {
      return (
        d.Deckenart == self.state.deckenWerte.deckentyp.selected.Deckenart &&
        d.dicke == self.state.deckenWerte.deckendicke.selected.label
      );
    });
    deckenauswahl = _.uniqBy(deckenauswahl, "Plattentype");
    let seldeckentyp = this.state.deckenWerte.deckentyp.selected;
    return (
      <div className="row">
        <div className="row grundaten-oben">
          <div className="col-md-6">
            <div>
              <h3>Vorbemessung</h3>
            </div>

            <div className="row">
              <div className="col-md-2">
                <div className="eingabendiv">
                  <b>Bauvorhaben: </b>
                </div>
              </div>
              <div className="col-md-4">
                <LNContentEditable
                  ref={this.bauvorhaben}
                  className="eingabenoben"
                  inhtml={this.state.eingaben.bauvorhaben} // innerHTML of the editable div
                  disabled={false} // use true to disable edition
                  onChange={this.handleChange.bind(this)} // handle innerHTML change
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                {" "}
                <div className="eingabendiv">
                  <b>Position: </b>
                </div>
              </div>
              <div className="col-md-4">
                <LNContentEditable
                  ref={this.bauposition}
                  className="eingabenoben"
                  inhtml={this.state.eingaben.bauposition} // innerHTML of the editable div
                  disabled={false} // use true to disable edition
                  onChange={this.handleChange.bind(this)} // handle innerHTML change
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row mg-top-15">
              <div className="col-md-4">
                <LNImage
                  width={170}
                  height={120}
                  src={"assets/images/" + seldeckentyp.Bild}
                />
              </div>
              <div className="col-md-8">
                <div className="inline">{seldeckentyp.Beschreibung}</div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12">
                {seldeckentyp.Deckenart}
                <br />
                {this.state.deckenWerte.decken.selected.Plattentype}
                <br />
                Dicke: {this.state.deckenWerte.decken.selected.dicke}cm
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-12"
            dangerouslySetInnerHTML={{ __html: this.state.texte.subheader }}
          ></div>
        </div>
        <div className="row grundaten-unten">
          <div className="col-md-2">
            <strong>Grunddaten</strong>
          </div>
          <div className="col-md-2">
            Deckenart:
            <br />
            <DropDown
              styles={["topdropdown"]}
              id="decckenartselect"
              onSelect={this._onSelectDeckenTyp}
              labelField="Deckenart"
              selectedItem={this.state.deckenWerte.deckentyp.selected}
              dataProvider={this.state.deckenWerte.deckentyp.auswahl}
            />
          </div>
          <div className="col-md-2">
            Dicke(cm):
            <br />
            <DropDown
              styles={["topdropdown"]}
              id="deckendickenselect"
              onSelect={this._onSelectDeckenDicke}
              labelField="label"
              selectedItem={this.state.deckenWerte.deckendicke.selected}
              dataProvider={this.state.deckenWerte.deckendicke.auswahl}
            />
          </div>
          <div className="col-md-2">
            Plattentyp:
            <br />
            <DropDown
              styles={["topdropdown"]}
              id="deckenselect"
              onSelect={this._onSelectDecke}
              labelField="Plattentype"
              selectedItem={this.state.deckenWerte.decken.selected}
              dataProvider={deckenauswahl}
            />
          </div>
          <div className="col-md-2">
            Expositionsklasse:
            <br />
            <DropDown
              styles={["topdropdown"]}
              id="exposelect"
              onSelect={this._onSelectExpo}
              labelField="label"
              selectedItem={this.state.deckenWerte.expoklasse.selected}
              dataProvider={this.state.deckenWerte.expoklasse.auswahl}
            />
          </div>
          <div className="col-md-2">
            Feuerwiderstand:
            <br />
            <div className="topdropdown">
              <button
                disabled="true"
                className="btn btn-default btn-sm dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                F90 <span className="caret"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GrundDaten;
