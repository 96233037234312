/* eslint-disable import/no-anonymous-default-export */
import Dispatcher from "../dispatcher/Dispatcher";
import Constants from "../constants/constants";
import $ from "jquery";
import X2JS from "x2js";

/* eslint-disable no-console */

export default {
  addItem(text) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.TASK_ADDED,
      text: text
    });
  },

  setScheibenData(data, pfad) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.SCHEIBEN_EINGABE,
      data: data,
      path: pfad
    });
  },

  saveData() {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.SAVE_DATA_TO_FILE,
      data: null
    });
  },
  loadData(d) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.LOAD_DATA_FROM_FILE,
      data: d
    });
  },
  applyloadedData(d) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.FILE_LOADED,
      data: d
    });
  },

  switchNorm(norm) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.SWITCH_NORM,
      norm: norm
    });
  },
  selectDecke(decke) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.DECKE_SELECTED,
      decke: decke
    });
  },
  selectDeckenTyp(deckentyp) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.DECKENTYP_SELECTED,
      deckentyp: deckentyp
    });
  },
  selectDeckenDicke(dicke) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.DECKENDICKE_SELECTED,
      dicke: dicke
    });
  },
  selectExpo(expo) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.EXPO_SELECTED,
      expo: expo
    });
  },
  selectBewehrung(bew) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.BEWEHRUNG_SELECTED,
      bewehrung: bew
    });
  },
  setEingaben(eingaben) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.GRUNDDATEN_EINGABE,
      eingaben: eingaben
    });
  },
  setGeometrie(eingaben, pfad) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.GEOMETRIE_EINGABE,
      geometriewerte: eingaben,
      path: pfad
    });
  },
  setBelastung(eingaben) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.BELASTUNG_EINGABE,
      belastung: eingaben
    });
  },
  setPreset(eingaben) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.SET_PRESET,
      preset: eingaben
    });
  },
  setBelastungKategorie(eingaben) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.BELASTUNG_EINGABE_KATEGORIE,
      belastung: eingaben
    });
  },
  addZusatzLast(last) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.ADD_ZUSATZLAST,
      last: last
    });
  },
  editZusatzLast(last) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.EDIT_ZUSATZLAST,
      last: last
    });
  },
  triggerChange(last) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.TRIGGER_CHANGE,
      last: last
    });
  },
  removeZusatzLast(last) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.REMOVE_ZUSATZLAST,
      last: last
    });
  },
  addAussparung(aus) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.ADD_AUSSPARUNG,
      aus: aus
    });
  },
  removeAussparung(aus) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.REMOVE_AUSSPARUNG,
      aus: aus
    });
  },

  loadBasicData() {
    Dispatcher.handleServerAction({
      type: Constants.ActionTypes.DATA_LOADING
    });
    //http://www.dw-systembau.de/bin/data/werte.xml

    Promise.all([
      fetch("./assets/xml/grundwerte.xml").then((response) => response.text()),
      fetch("./assets/json/werte.json").then((response) => response.text()),
      fetch("./assets/xml/deckenarten.xml").then((response) => response.text()),
      fetch("./assets/json/skizzen.json").then((response) => response.text()),
      fetch("./assets/xml/kategoriebeiwerte.xml").then((response) =>
        response.text()
      ),
      fetch("./assets/xml/aussparungslagen.xml").then((response) =>
        response.text()
      ),
      fetch("./assets/xml/aussparungsgroessen.xml").then((response) =>
        response.text()
      ),
      fetch("./assets/json/biegeweich_daten.json").then((response) =>
        response.text()
      ),
      fetch("./assets/json/texte.json").then((response) => response.text()),
      fetch("./assets/json/scheiben.json").then((response) => response.text())
    ])
      .then(
        ([
          gwxml,
          wjson,
          artxml,
          sjson,
          katxml,
          alxml,
          agxml,
          bwjson,
          textejson,
          scheibenjson
        ]) => {
          console.log("success loading all data");
          var x2js = new X2JS();
          var gwjson, artjson, katjson, aljson, agjson;
          try {
            gwjson = x2js.dom2js(
              new DOMParser().parseFromString(gwxml, "text/xml")
            );
            artjson = x2js.dom2js(
              new DOMParser().parseFromString(artxml, "text/xml")
            );
            /* wjson = x2js.dom2js(
              new DOMParser().parseFromString(wxml, "text/xml")
            );*/
            katjson = x2js.dom2js(
              new DOMParser().parseFromString(katxml, "text/xml")
            );
            aljson = x2js.dom2js(
              new DOMParser().parseFromString(alxml, "text/xml")
            );
            agjson = x2js.dom2js(
              new DOMParser().parseFromString(agxml, "text/xml")
            );
          } catch (e) {
          } finally {
          }
          Dispatcher.handleServerAction({
            type: Constants.ActionTypes.DATA_LOADED,
            gwdata: {
              xml: new DOMParser().parseFromString(gwxml, "text/xml"),
              json: gwjson
            },
            artdata: {
              xml: new DOMParser().parseFromString(artxml, "text/xml"),
              json: artjson
            },
            wdata: {
              xml: null,
              json: JSON.parse(wjson)
            },
            skizzen: { json: JSON.parse(sjson) },
            katdata: {
              xml: new DOMParser().parseFromString(katxml, "text/xml"),
              json: katjson
            },
            aldata: {
              xml: new DOMParser().parseFromString(alxml, "text/xml"),
              json: aljson
            },
            agdata: {
              xml: new DOMParser().parseFromString(agxml, "text/xml"),
              json: agjson
            },
            biegeweich: { json: JSON.parse(bwjson) },
            texte: { json: JSON.parse(textejson) },
            scheiben: { json: JSON.parse(scheibenjson) }
          });
        }
      )
      .catch((e) => {
        console.error("error loading basic data", e);
      });
  },

  createPDF(opts) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CREATE_PDF,
      opts
    });
  },
  createPDFAndPrint(opts) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.PRINT_PDF,
      opts
    });
  }
};
