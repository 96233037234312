
import assign from 'object-assign';

const Utils = assign({}, {


  round(value) {
    let decimals = 2;
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
  },



});

export default Utils;
