/**
 * Created by joerg on 28.07.16.
 */
import Dispatcher from "../dispatcher/Dispatcher";
import Constants from "../constants/constants";
import BaseStore from "./BaseStore";
import assign from "object-assign";
import _ from "lodash";
import GrundDatenStore from "./GrundDatenStore";
// Facebook style store creation.

let auflagerMassivSelection = [{ label: "Biegesteif", key: "mauer" }];
let auflagerStandartSelection = [
  { label: "Biegesteif", key: "mauer" },
  { label: "50% Regel", key: "_50prozent" },
  { label: "StB-Träger", key: "stb_traeger" },
  { label: "Spannbet.-Träger", key: "spannbet_traeger" },
  { label: "Stahlträger", key: "stahltraeger" },
  { label: "Peikkoträger", key: "peikko" },
  { label: "Pfeiferträger", key: "pfeifer" },
  { label: "NPSträger", key: "tecnostrutture" }
];

var dataLoaded = false;
var initialsetupdone = false;

var geometrieWerte = {
  auflager: {
    AlAselected: { label: "Biegesteif", key: "mauer" },
    ALAauswahl: auflagerStandartSelection,
    AlBselected: { label: "Biegesteif", key: "mauer" },
    ALBauswahl: auflagerStandartSelection,
    auflagerA: 0.1,
    auflagerAKorr: 0.1,
    auflagerB: 0.1,
    auflagerBKorr: 0.1,
    mindestauflager: 0.07
  },
  feldweite: 5.0,
  feldbreite: 1.2,
  stuetzweite: 5.07,
  biegeweich: { auflagerA: {}, auflagerB: {} }
};

var biegeweich = {
  alTypes: {
    beton: { auswahl: [], selected: {} },
    stahl: { auswahl: [], selected: {} },
    peikko: { auswahl: [], selected: {} },
    pfeifer: { auswahl: [], selected: {} },
    tecnostrutture: { auswahl: [], selected: {} }
  },
  b: 0,
  h: 0,
  ib: 0,
  im: 0,
  breite: 0.12,
  fuelltiefe: {
    auswahl: [{ label: "Min. (50 mm)", value: 50, key: 50 }],
    selected: { label: "Min. (50 mm)", value: 50, key: 50 }
  },
  abstand: 0.0,
  ei: 0.0,
  traegerpos: "mitte",
  tragerunterstuetzung: false,
  verbundsteigernd: false,
  peikkoei: "ei_mittel",
  pfeiferei: "ei_mittel",
  tecnostruttureei: "ei_mittel"
};

geometrieWerte.biegeweich.auflagerA = _.cloneDeep(biegeweich);
geometrieWerte.biegeweich.auflagerB = _.cloneDeep(biegeweich);

function initbiegeweichData(bjsn) {
  let beton = bjsn.beton.map(function (el) {
    el.key = el.name;
    return el;
  });
  let stahl = bjsn.stahl.map(function (el) {
    el.key = el.name;
    return el;
  });
  let peikko = bjsn.peikko.map(function (el) {
    el.key = el.name;
    return el;
  });
  let pfeifer = bjsn.pfeifer.map(function (el) {
    el.key = el.name;
    return el;
  });
  let tecnostrutture = bjsn.tecnostrutture.map(function (el) {
    el.key = el.name;
    return el;
  });
  biegeweich.alTypes.beton.auswahl = beton;
  biegeweich.alTypes.beton.selected = beton[0];
  biegeweich.alTypes.stahl.auswahl = stahl;
  biegeweich.alTypes.stahl.selected = stahl[0];
  biegeweich.alTypes.peikko.auswahl = peikko;
  biegeweich.alTypes.peikko.selected = peikko[0];
  biegeweich.alTypes.pfeifer.auswahl = pfeifer;
  biegeweich.alTypes.pfeifer.selected = pfeifer[0];
  biegeweich.alTypes.tecnostrutture.auswahl = tecnostrutture;
  biegeweich.alTypes.tecnostrutture.selected = tecnostrutture[0];
  assign(geometrieWerte.biegeweich.auflagerB, _.cloneDeep(biegeweich));
  assign(geometrieWerte.biegeweich.auflagerA, _.cloneDeep(biegeweich));
  dataLoaded = true;
}

function getAuflagerCorrection(seite) {
  let altyp =
    seite == "a"
      ? geometrieWerte.auflager.AlAselected.key
      : geometrieWerte.auflager.AlBselected.key;
  let corrections = {
    mauer: 0.0,
    stb_traeger: 0.025,
    spannbet_traeger: 0.025,
    stahltraeger: 0.0,
    peikko: 0.0,
    pfeifer: 0.0,
    tecnostrutture: 0.0,
    _50prozent: 0.0
  };
  return corrections[altyp];
}

function calcStuetzweite() {
  var aufa = geometrieWerte.auflager.auflagerA; // parseFloat(geometrieWerte.auflager.auflagerA.replace(",","."));
  var aufb = geometrieWerte.auflager.auflagerB; //parseFloat(geometrieWerte.auflager.auflagerB.replace(",","."));
  let fw = geometrieWerte.feldweite; // parseFloat(geometrieWerte.feldweite.replace(",","."));
  var sw = fw + aufa / 3.0 + aufb / 3.0;
  // geometrieWerte.stuetzweite = sw;// sw.toFixed(2).toString().replace(".",",");
  let korrA = getAuflagerCorrection("a");
  aufa = aufa - korrA;
  let korrB = getAuflagerCorrection("b");
  aufb = aufb - korrB;
  sw = fw + korrA + aufa / 3.0 + korrB + aufb / 3.0;

  geometrieWerte.stuetzweite = sw;
  geometrieWerte.auflager.auflagerAKorr = aufa;
  geometrieWerte.auflager.auflagerBKorr = aufb;
  /*
   * a2
   Biegesteif: 30 mm oder 0,030 m
   Stahlbeton/Spannbeton: 25 mm oder 0,025 m
   Stahl: 0 mm oder 0,000 m

   Also:
   Beispiele
   Biegesteif (a2=25 mm)           Lichte Weite    5,00 m             Auflagertiefe A            10 cm  (ansetzbare Auflagertiefe a1 = 100 - 30 mm= 70 mm (0,07 m)
   Stahlträger(a2= 0 mm)                                                           Auflagertiefe B            12 cm (ansetzbare Auflagertiefe a2 = 120 - 0 mm = 120 mm (0,12 m)
   daraus folgt Stützweite Lst= 5,00 +0,03 (a2 Auflager A) + (0,1-0,03)/3 + 0,000 (a2 Auflager B) + (0,12-0,000)/3
   Lst = 5,09 m
   *
   * */
}

function filterAuflager(dtype) {
  if (dtype.Deckenart == "Massivdecke") {
    geometrieWerte.auflager.ALAauswahl = auflagerMassivSelection;
    geometrieWerte.auflager.ALBauswahl = auflagerMassivSelection;
  } else {
    geometrieWerte.auflager.ALAauswahl = auflagerStandartSelection;
    geometrieWerte.auflager.ALBauswahl = auflagerStandartSelection;
  }
  geometrieWerte.auflager.AlAselected = geometrieWerte.auflager.ALAauswahl[0];
  geometrieWerte.auflager.AlBselected = geometrieWerte.auflager.ALBauswahl[0];
}

function filterFuellTiefe(dicke) {
  var ft = parseInt(dicke.key) * 10;
  ft = ft <= 250 ? 250 : ft;

  geometrieWerte.biegeweich.auflagerA.fuelltiefe.auswahl = [
    { label: "Min. (50 mm)", value: 50, key: 50 },
    { label: "Max. (" + ft + ") mm", value: ft, key: ft }
  ];
  geometrieWerte.biegeweich.auflagerB.fuelltiefe.auswahl = [
    { label: "Min. (50 mm)", value: 50, key: 50 },
    { label: "Max. (" + ft + ") mm", value: ft, key: ft }
  ];
  geometrieWerte.biegeweich.auflagerA.fuelltiefe.selected =
    geometrieWerte.biegeweich.auflagerA.fuelltiefe.auswahl[0];
  geometrieWerte.biegeweich.auflagerB.fuelltiefe.selected =
    geometrieWerte.biegeweich.auflagerB.fuelltiefe.auswahl[0];
  /* geometrieWerte.biegeweich.auflagerA.fuelltiefe.selected =
    geometrieWerte.biegeweich.auflagerA.fuelltiefe.auswahl[
      geometrieWerte.biegeweich.auflagerA.fuelltiefe.selected.value != 50 ||
      !initialsetupdone
        ? 1
        : 0
    ];
  geometrieWerte.biegeweich.auflagerB.fuelltiefe.selected =
    geometrieWerte.biegeweich.auflagerB.fuelltiefe.auswahl[
      geometrieWerte.biegeweich.auflagerB.fuelltiefe.selected.value != 50 ||
      !initialsetupdone
        ? 1
        : 0
    ]; */
  initialsetupdone = true;
}
function calcBiegeweich() {
  let bwauflager = [];
  if (geometrieWerte.auflager.AlAselected.key != "mauer") {
    bwauflager.push({
      gwerte: geometrieWerte.biegeweich.auflagerA,
      alselected: geometrieWerte.auflager.AlAselected
    });
  }
  if (geometrieWerte.auflager.AlBselected.key != "mauer") {
    bwauflager.push({
      gwerte: geometrieWerte.biegeweich.auflagerB,
      alselected: geometrieWerte.auflager.AlBselected
    });
  }
  console.log("calcbw", bwauflager);
  bwauflager.forEach(function (itm) {
    let al = itm.gwerte;
    let sel = itm.alselected;
    if (sel.key == "stahltraeger") {
      al.ib = al.alTypes.stahl.selected.wert;
      al.ei = Math.round((al.im != 0 ? al.im : al.ib) * (2.1 / 1000));
    } else if (sel.key == "peikko") {
      al.ib = 1;
      al.ei = al.alTypes.peikko.selected[al.peikkoei];
    } else if (sel.key == "pfeifer") {
      al.ib = 1;
      al.ei = al.alTypes.pfeifer.selected[al.pfeiferei];
    } else if (sel.key == "tecnostrutture") {
      //filterTraegerPos(itm);
      al.ib = 1;
      al.ei = al.alTypes.tecnostrutture.selected[al.tecnostruttureei];
    } else {
      al.ib = Math.round((al.b * 100 * Math.pow(al.h * 100, 3)) / 12);
      al.ei = Math.round(
        (al.im != 0 ? al.im : al.ib) *
          Math.pow(10, -8) *
          al.alTypes.beton.selected.wert *
          (sel.key == "stb_traeger" ? 0.5 : 1.0)
      );
    }
  });
}

function filterTraegerPos(auflager) {
  console.log("filterTraegerPos", auflager);
  let al = auflager.gwerte;
  let sel = auflager.alselected;
  let selValues = al.alTypes[sel.key].selected;
  console.log("filterTraegerPos", selValues, al);
  if (al.traegerpos == selValues.position) {
    return;
  }
  let auswahl = al.alTypes[sel.key].auswahl;
  let newSelection = auswahl.find(
    (el) => el.position == al.traegerpos && selValues.h == el.h
  );
  if (!newSelection) {
    return;
  }
  al.alTypes[sel.key].selected = newSelection;
  //gwerte.alTypes.tecnostrutture.auswahl;
}

function postAssign(path, before) {
  let settedvalue = _.get(geometrieWerte, path + ".key");
  if (before.key == settedvalue) {
    return;
  }
  if (path == "auflager.AlAselected" || path == "auflager.AlBselected") {
    if (settedvalue == "stahltraeger") {
      let alselect = path == "auflager.AlAselected" ? "auflagerA" : "auflagerB";
      geometrieWerte.biegeweich[alselect].im = 0.0;
    }
  }
}

function getDataForSaving() {
  var g2 = _.cloneDeep(geometrieWerte);
  g2.auflager = _.omit(g2.auflager, ["ALAauswahl", "ALBauswahl"]);
  _.forIn(g2.biegeweich.auflagerA.alTypes, (v, k) => {
    g2.biegeweich.auflagerA.alTypes[k] = _.omit(v, ["auswahl"]);
  });
  _.forIn(g2.biegeweich.auflagerB.alTypes, (v, k) => {
    g2.biegeweich.auflagerB.alTypes[k] = _.omit(v, ["auswahl"]);
  });

  let savedata = { geometriewerte: _.cloneDeep(g2) };
  return savedata;
}

function applySavedData(sdata) {
  _.merge(geometrieWerte, sdata.geometriewerte);
}

const GeometrieDatenStore = assign({}, BaseStore, {
  // public methods used by Controller-View to operate on data

  getAll() {
    return { geometriewerte: geometrieWerte };
  },
  getSavedata() {
    return getDataForSaving();
  },
  setSavedData(sd) {
    applySavedData(sd);
    GeometrieDatenStore.emitChange();
  },

  // register store with dispatcher, allowing actions to flow through
  dispatcherIndex: Dispatcher.register(function handleAction(payload) {
    const action = payload.action;

    switch (action.type) {
      case Constants.ActionTypes.DATA_LOADED:
        initbiegeweichData(action.biegeweich.json);
        GeometrieDatenStore.emitChange();
        break;
      case Constants.ActionTypes.GEOMETRIE_EINGABE:
        var before = undefined;
        if (action.path) {
          before = _.get(geometrieWerte, action.path);
        }
        assign(geometrieWerte, action.geometriewerte);
        if (before) {
          postAssign(action.path, before);
        }
        calcStuetzweite();
        calcBiegeweich();
        GeometrieDatenStore.emitChange();
        break;
      case Constants.ActionTypes.DECKENTYP_SELECTED:
        console.log("_geostore:", action.deckentyp);

        filterAuflager(action.deckentyp);
        break;
      case Constants.ActionTypes.FILE_LOADED:
        applySavedData(action.data.GeometrieDatenStore);
        GeometrieDatenStore.emitChange();
      default:
        Dispatcher.waitFor([GrundDatenStore.dispatcherIndex]);
        console.log("_geostore wait:", action.deckentyp);
        if (dataLoaded) {
          let gd2 = GrundDatenStore.getAll();
          filterFuellTiefe(gd2.deckenWerte.deckendicke.selected);
        }
        break;

      // add more cases for other actionTypes...

      // no default
    }
  })
});

export default GeometrieDatenStore;
