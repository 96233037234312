import React from "react";
import ActionCreator from "../../actions/StatikActionCreator";
import ScheibenStore from "../../stores/Scheibenstore";
import DropDown from "../common/DropDown";
import InputAndLabel from "../common/InputAndLabel";
import GrundDatenView from "./GrundDaten";
import _ from "lodash";
import LNImage from "../common/LNImage";
import CalcStore from "../../stores/CalcStore";
class Scheiben extends React.Component {
  constructor() {
    super();
    this._onChange = this._onChange.bind(this);
    this.state = ScheibenStore.getAll();
  }
  _onChange() {
    let np = ScheibenStore.getAll();
    this.setState(np);
  }
  _handleChange(itm, pfad) {
    console.log("scheibe changed", itm, pfad);
    ActionCreator.setScheibenData(itm, pfad);
  }
  componentDidMount() {
    ScheibenStore.addChangeListener(this._onChange.bind(this));
  }

  componentWillUnmount() {
    ScheibenStore.removeChangeListener(this._onChange.bind(this));
  }
  _save() {
    ActionCreator.createPDF({ includeScheibe: true });
  }
  _print() {
    ActionCreator.createPDFAndPrint({ includeScheibe: true });
  }
  render() {
    let bew = CalcStore.getSelectedBewehrung()[0];
    let isprintable =
      bew != undefined && (bew.Error === undefined || bew.Error == "");
    return (
      <div>
        <GrundDatenView />
        <div className="row mt-3 mb-3">
          <h3>Scheibennachweis</h3>
          <hr />
        </div>
        <div className="row ">
          <div className="col-md-5">
            <div className="row mb-3 ">
              <div className="col-md-12">
                <img
                  src={
                    "assets/images/scheiben/shell" +
                    this.state.system.selected.value +
                    "" +
                    this.state.ausrichtung.selected.value +
                    ".png"
                  }
                  class="img-fluid"
                  alt="scheiben gfx"
                />
              </div>
            </div>
            <hr></hr>
            <div className="row">
              <div className="col-md-12">
                <div className="input-group mb-3">
                  <div className="input-group-text ">System</div>

                  <DropDown
                    onSelect={(itm) =>
                      this._handleChange(itm, "system.selected")
                    }
                    labelField="label"
                    selectedItem={this.state.system.selected}
                    dataProvider={this.state.system.auswahl}
                  />
                </div>
                <div className="input-group mb-5">
                  <div className="input-group-text ">Plattenausrichtung</div>

                  <DropDown
                    onSelect={(itm) =>
                      this._handleChange(itm, "ausrichtung.selected")
                    }
                    labelField="label"
                    selectedItem={this.state.ausrichtung.selected}
                    dataProvider={this.state.ausrichtung.auswahl}
                  />
                </div>
                <div className="row">
                  <InputAndLabel
                    label="Scheibenlänge L ="
                    defaultValue={0.0}
                    value={this.state.sL}
                    onChange={(itm) => this._handleChange(itm, "sL")}
                    einheit="m"
                    id="sl"
                  />

                  <InputAndLabel
                    label="Scheibenhöhe H ="
                    defaultValue={0.0}
                    value={this.state.sH}
                    einheit="m"
                    id="sh"
                    onChange={(itm) => this._handleChange(itm, "sH")}
                  />
                  <InputAndLabel
                    label="Abstand Zugpfosten az ="
                    defaultValue={5.0}
                    value={this.state.az}
                    onChange={(itm) => this._handleChange(itm, "az")}
                    einheit="m"
                    id="lfw"
                  />

                  <InputAndLabel
                    label="Scheibenbelastung qd ="
                    defaultValue={5.05}
                    value={this.state.qd}
                    onChange={(itm) => this._handleChange(itm, "qd")}
                    einheit="kN/m"
                    id="sw"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12" id="sout">
                <table>
                  <colgroup>
                    <col width="15%" />
                    <col width="20%" />
                    <col width="20%" />
                    <col width="20%" />
                    <col width="15%" />
                    <col width="10%" />
                  </colgroup>
                  <tr class="section">
                    <td colspan="6">
                      Schnittgr&ouml;&szlig;en und Auflagerkraft
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="2">
                      Maximales Scheibenmoment
                    </td>
                    <td class="rb">
                      M<sub>d</sub> ={" "}
                    </td>
                    <td class="rs">{_.round(this.state.Md, 2).toFixed(2)}</td>
                    <td class="lr"> kNm </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="2">
                      Auflagerkraft
                    </td>
                    <td class="rb">
                      V<sub>d</sub> ={" "}
                    </td>
                    <td class="rs">{_.round(this.state.Vd, 2).toFixed(2)}</td>
                    <td class="lr"> kN </td>
                  </tr>
                  <tr class="section">
                    <td colspan="6">1. Bemessung Zuggurt und Druckstrebe</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="2">
                      Hebelarm Scheibe
                    </td>
                    <td class="rb">z = </td>
                    <td class="rs">{_.round(this.state.z, 2).toFixed(2)}</td>
                    <td class="lr"> m </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="2">
                      Druckgurtkraft = Zuggurtkraft
                    </td>
                    <td class="rb">
                      F<sub>Ed</sub> = M<sub>d</sub>/z ={" "}
                    </td>
                    <td class="rs">{_.round(this.state.FEd1, 2).toFixed(2)}</td>
                    <td class="lr"> kN </td>
                  </tr>
                  <tr>
                    <td colspan="3">&nbsp;</td>
                    <td class="rb">
                      F<sub>Ed,min</sub> ={" "}
                    </td>
                    <td class="rs">
                      {" "}
                      {_.round(this.state.FEdmin1, 2).toFixed(2)}
                    </td>
                    <td class="lr"> kN </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="2">
                      Ringankerbewehrung
                    </td>
                    <td class="rb">
                      erf A<sub>sd,1</sub> ={" "}
                    </td>
                    <td class="rs">{_.round(this.state.Asd1, 2).toFixed(2)}</td>
                    <td class="lr"> cm &sup2; </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="2">
                      Druckstrebe
                    </td>
                    <td class="rb">
                      cot&theta; = a<sub>z</sub>/z ={" "}
                    </td>
                    <td class="rs">{_.round(this.state.cot, 2).toFixed(2)}</td>
                    <td class="lr"> - </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="2">
                      Nachweis Druckstrebe
                    </td>
                    <td class="rb">
                      V<sub>Rd,max</sub> ={" "}
                    </td>
                    <td class="rs">
                      {_.round(this.state.VRdmax, 2).toFixed(2)}
                    </td>
                    <td class="lr"> kN </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="2">
                      Fugenbeton (mind. C20/25)
                    </td>
                    <td class="rb">
                      V<sub>d</sub>/V<sub>Rd</sub> ={" "}
                    </td>
                    <td class="rs">{_.round(this.state.VV, 2).toFixed(2)}</td>
                    <td class="lr"> - </td>
                  </tr>
                  <tr class="section">
                    <td colspan="6">2. Bemessung Zugpfosten</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="2">
                      Max. Pfostenkraft
                    </td>
                    <td class="rb">
                      F<sub>Ed</sub> ={" "}
                    </td>
                    <td class="rs">{_.round(this.state.FEd2, 2).toFixed(2)}</td>
                    <td class="lr"> kN </td>
                  </tr>
                  <tr>
                    <td colspan="3">&nbsp;</td>
                    <td class="rb">
                      F<sub>Ed,min</sub> ={" "}
                    </td>
                    <td class="rs">
                      {" "}
                      {_.round(this.state.FEdmin2, 2).toFixed(2)}
                    </td>
                    <td class="lr"> kN </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="2">
                      Zugpfostenbewehrung
                    </td>
                    <td class="rb">
                      erf A<sub>sd,2</sub> ={" "}
                    </td>
                    <td class="rs">{_.round(this.state.Asd2, 2).toFixed(2)}</td>
                    <td class="lr"> cm &sup2; </td>
                  </tr>
                  <tr class="section">
                    <td colspan="6">3. Bemessung Fugen</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="2">
                      Fugenkraft
                    </td>
                    <td class="rb">
                      F<sub>Ed</sub> ={" "}
                    </td>
                    <td class="rs">{_.round(this.state.FEd3, 2).toFixed(2)}</td>
                    <td class="lr"> kN </td>
                  </tr>
                  <tr>
                    <td colspan="3">&nbsp;</td>
                    <td class="rb">
                      F<sub>Ed,min</sub> ={" "}
                    </td>
                    <td class="rs">
                      {" "}
                      {_.round(this.state.FEdmin3, 2).toFixed(2)}
                    </td>
                    <td class="lr"> kN </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="2">
                      Fugenbewehrung
                    </td>
                    <td class="rb">
                      erf A<sub>sd,3</sub> ={" "}
                    </td>
                    <td class="rs">{_.round(this.state.Asd3, 2).toFixed(2)}</td>
                    <td class="lr"> cm &sup2; </td>
                  </tr>
                  <tr class="section">
                    <td colspan="6">4. Anschluss an aussteifendes Bauteil</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="2">
                      Anschlusskraft
                    </td>
                    <td class="rb">
                      F<sub>Ed</sub> ={" "}
                    </td>
                    <td class="rs">{_.round(this.state.FEd4, 2).toFixed(2)}</td>
                    <td class="lr"> kN </td>
                  </tr>
                  <tr>
                    <td colspan="3">&nbsp;</td>
                    <td class="rb">
                      F<sub>Ed,min</sub> ={" "}
                    </td>
                    <td class="rs">
                      {" "}
                      {_.round(this.state.FEdmin4, 2).toFixed(2)}
                    </td>
                    <td class="lr"> kN </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="2">
                      Ringankerbewehrung
                    </td>
                    <td class="rb">
                      erf A<sub>sd,4</sub> ={" "}
                    </td>
                    <td class="rs">{_.round(this.state.Asd4, 2).toFixed(2)}</td>
                    <td class="lr"> cm &sup2; </td>
                  </tr>
                  <tr class="section">
                    <td colspan="6">5. Querkraft&uuml;bertragung</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="2">
                      Maximale Querkraft
                    </td>
                    <td class="rb">
                      v<sub>Ed</sub> ={" "}
                    </td>
                    <td class="rs">{_.round(this.state.vEd, 2).toFixed(2)}</td>
                    <td class="lr"> kN/m </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="2">
                      Max. Querkraft ohne Verd&uuml;belung
                    </td>
                    <td class="rb">
                      v<sub>Rd,ct</sub> ={" "}
                    </td>
                    <td class="rs">
                      {_.round(this.state.vRdct, 2).toFixed(2)}
                    </td>
                    <td class="lr"> kN/m </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="2">
                      D&uuml;belbewehrung
                    </td>
                    <td class="rb">
                      erf A<sub>sd,5</sub> ={" "}
                    </td>
                    <td class="rs">{_.round(this.state.Asd5, 2).toFixed(2)}</td>
                    <td class="lr"> cm &sup2; </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td class="l" colspan="5"></td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <hr />
                <h6 hidden={isprintable}>
                  Bitte wähen sie unter Geometrie eine Bewehrung, um drucken zu
                  können
                </h6>
                <br />
                <button
                  type="button"
                  className="btn btn-default btn-sm pull-right mx-2"
                  disabled={!isprintable}
                  onClick={this._save}
                >
                  Statik und Scheibe als PDF speichern
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-sm pull-right"
                  disabled={!isprintable}
                  onClick={this._print}
                >
                  Statik und Scheibe anzeigen und drucken
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Scheiben;
