import React from 'react';
import {Link} from 'react-router-dom';

import AussparungStore from '../../stores/AussparungStore';
import DataGrid from '../../components/common/DataGrid';
import Visualizer from '../../components/common/Visualizer';
import ActionCreator from '../../actions/StatikActionCreator';


class Aussparungen extends React.Component {

    constructor() {
        super();
        this._onChange = this._onChange.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._onSelectAus = this._onSelectAus.bind(this);
        this._deleteAus = this._deleteAus.bind(this);
        this.state = AussparungStore.getAll();
        this.selectedItem = null;
    }

    _handleChange() {
    }

    _onChange() {
        console.log("ausspa change");
        //if (this.isMounted) {

        let tk = AussparungStore.getAll();
        this.setState(tk);
       // }
    }

    _editAus() {

    }
    _deleteAus() {
        ActionCreator.removeAussparung(this.selectedItem);
    }
    _onSelectAus(item) {
        if (this.selectedItem) {
            this.selectedItem.selected = false;
        }
        this.selectedItem = item;
        item.selected = true;
        let tk = AussparungStore.getAll();
        this.setState(tk);
    }

    componentDidMount() {
        AussparungStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        AussparungStore.removeChangeListener(this._onChange);
    }

    render() {
        return (
            <div>
                <div className="row mg-bottom-15">
                    <h3>Aussparungen</h3>
                    <hr/>
                    <DataGrid headers={this.state.aussparungen.headers} onSelect={this._onSelectAus} dataProvider={this.state.aussparungen.data} />
                </div>
                <div>
                    <button type="button" className="btn btn-default btn-sm" ><Link to="/pop/aussparung">Zufügen</Link></button>
                    <button type="button" className="btn btn-default btn-sm mg-left-15" onClick={this._editAus}>Bearbeiten</button>
                    <button type="button" className="btn btn-default btn-sm pull-right" disabled={this.selectedItem == null} onClick={this._deleteAus}>Entfernen</button>
                    <div className="mg-bottom-15"></div>
                    <div className="visualizer"><Visualizer /></div>

                </div>
            </div>
        );
    }
}

export default Aussparungen;

