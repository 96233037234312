import Dispatcher from '../dispatcher/Dispatcher';
import Constants from '../constants/constants';
import BaseStore from '../stores/BaseStore';
import BelastungsStore from '../stores/BelastungStore';
import GeometrieDatenStore from '../stores/GeometrieDatenStore';
import GrundDatenStore from '../stores/GrundDatenStore';
import FileSaver from 'file-saver';
import ActionCreator from '../actions/StatikActionCreator';
import _ from 'lodash'
//import {hashHistory} from 'react-router';

import assign from 'object-assign';

function saveData(){
  let bd = BelastungsStore.getSavedata();
  let gd = GeometrieDatenStore.getSavedata();
  let grundd = GrundDatenStore.getSavedata();
  let sdata = {BelastungsStore:_.cloneDeep(bd),GeometrieDatenStore:_.cloneDeep(gd),GrundDatenStore:_.cloneDeep(grundd)};
  let datastring = JSON.stringify(sdata);
  var encodedString = btoa(datastring);
  var blob = new Blob([encodedString], {type: "text/plain;charset=utf-8"});
  FileSaver.saveAs(blob,grundd.eingaben.bauvorhaben+'.dwstatik');
  console.log("saving ",bd,gd,grundd);
}

function loadData(d){
  var decString = atob(d);
  let jsonData = JSON.parse(decString);
  //GrundDatenStore.setSavedData(jsonData.GrundDatenStore);
  //GeometrieDatenStore.setSavedData(jsonData.GeometrieDatenStore);
  //BelastungsStore.setSavedData(jsonData.BelastungsStore);
  setTimeout(()=>{
    ActionCreator.applyloadedData(jsonData);
    this.props.history.push('/calc/geometrie',{});
    },10);

}

const LNFileSaver = assign({}, BaseStore, {


    getAll() {
        return null;
    },



    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function handleAction(payload) {
        const action = payload.action;

        switch (action.type) {
            case Constants.ActionTypes.SAVE_DATA_TO_FILE:
                saveData();
                break;
          case Constants.ActionTypes.LOAD_DATA_FROM_FILE:
            loadData(action.data);
            break;

        }
    })
});

export default LNFileSaver;
