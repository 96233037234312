/*eslint no-shadow: [1]*/
/*eslint no-unused-vars: [1]*/
/*eslint camelcase: [0]*/

import Dispatcher from "../dispatcher/Dispatcher";
import Constants from "../constants/constants";
import BaseStore from "./BaseStore";
import assign from "object-assign";
import GeometrieStore from "./GeometrieDatenStore";
import GrundDatenStore from "./GrundDatenStore";
import BelastungStore from "./BelastungStore";
import _ from "lodash";
import firstBy from "thenby";

var bewehrungen = {
  headers: [
    { label: "BRESPA", key: "Bewehrung" },
    { label: "MRd", key: "mrd" },
    { label: "VRd A", key: "vrda" },
    { label: "VRd B", key: "vrdb" }
  ],
  data: []
};

var calcResults = {
  headers: [
    { label: "Bespannung", key: "Bewehrung" },
    //MRd, MEd, VRd,ct1, VEd, ct1

    { label: "M<sub>Rd</sub>", key: "MRd" },
    { label: "M<sub>Ed</sub>", key: "MEd" },
    { label: "V<sub>Rdct 1</sub>", key: "VRdct1" },
    { label: "V<sub>Edct 1</sub>", key: "VEdct1" },
    { label: "Action", key: "__bt:enabled" }
    // {label:'Durchb./Durchh.',key:'durchBothformat'}
  ],
  data: []
};
var lastitem = {
  G: 0.0,
  Q: 0.0,
  Anfang: 0.0,
  Lange: 0.0
};
var lasten = [];
var kategorieitem = {
  ID: "",
  Name: "",
  Phi0: 0.0,
  Phi1: 0.0,
  Phi2: 0.0,
  VL: 0.0,
  AUSST: 1.2,
  ExpositionsKlasse: "XC3",
  BrandschutzKlasse: "F90",
  Durchbiegung: 500.0,
  Durchhang: 250.0
};
var tdeckeitem = {
  Name: "",
  Bewehrung: "",
  DeckenArt: "TDeckenArt",
  h: 0.0,
  SelfWeight: 0.0,
  Lptd: 0.0,
  Sigma_cp: 0.0,
  Tau_cpd: 0.0,
  fctd: 0.0,
  FIbwS: 0.0,
  MaxLange: 0.0,
  MaxVL: 0.0,
  MRd: 0.0,
  MRXC1: 0.0,
  MRXC3: 0.0,
  MRXC3OPH: 0.0,
  MRdF90: 0.0,
  VRdF90: 0.0,
  EI: 0.0,
  Phi: 0.0,
  W: 0.0,
  Mpt2: 0.0,
  VRdct62a: 0.0
};
var decken = [];
var tresultitem = {
  VRdct1: 0.0,
  VEdct1: 0.0,
  VRdct1A2: 0.0,
  VEdct1A2: 0.0,
  VRdct2: 0.0,
  VEdct2: 0.0,
  MEd: 0.0,
  MRd: 0.0,
  MEdF90: 0.0,
  MRdF90: 0.0,
  VEdF90: 0.0,
  VRdF90: 0.0,
  MRdSLS: 0.0,
  MEdSLS: 0.0,
  Durchbiegung_E: 0.0,
  Durchhang_E: 0.0,
  Durchbiegung_R: 0.0,
  Durchhang_R: 0.0,
  Result: false,
  Error: "",
  durchBformat: "",
  durchHformat: "",
  durchBothformat: "",
  warn3Percent: "",
  requiredEI_a: 0.0,
  requiredEI_b: 0.0
};
var results = [];
//AUSST
var AUSST, B, a, a2, L, VL;
L = 0.0;
B = 1.0;
var SystemBreite = B;

const resTolerance = 1.03;

var mInput = {
  stutzWeite: 0.0,
  auflagerTiefe: 0.0,
  auflagerTiefe2: 0.0,
  verkehrslast: 0.0,
  ausstattung: 1.2,
  expoKlasse: "XC3",
  brandschutz: "F90",
  kategorie: _.cloneDeep(kategorieitem),
  durchbiegung: 500.0,
  durchhang: 250.0,
  lfg: 1.35,
  lfq: 1.5
};

function QuerkraftXFuerLast(i, X, fG, fQ) {
  var F = 0.0;
  var R = 0.0;
  let last = lasten[i];
  if (last.toosmall) {
    return 0.0;
  }
  F = fG * last.G + fQ * last.Q;
  //Werner ist das die richtige Stelle?
  if (last.lastverteilung) {
    F =
      X == 0
        ? F / last.lastverteilung.bma.value
        : F / last.lastverteilung.bmb.value;
  }

  R = F * (1 - (last.Anfang + 0.5 * last.Lange) / L);

  var Result = R;

  if (X > last.Anfang) {
    if (X < last.Anfang + last.Lange) {
      Result = Result - (F / last.Lange) * (X - last.Anfang);
    } else {
      Result = Result - F;
    }
  }
  return Result;
}
function loopQuerKraftFuerLast(X, fG, fQ) {
  var Result = 0.0;
  for (var t = 0; t < lasten.length; t++) {
    Result = Result + QuerkraftXFuerLast(t, X, fG, fQ);
  }
  return Result;
}
function MaxQuerkraft(fG, fQ) {
  var Result = Math.max(
    Math.abs(loopQuerKraftFuerLast(0, fG, fQ)),
    Math.abs(loopQuerKraftFuerLast(L, fG, fQ))
  );
  return Result;
}

function momentXFuerLast(i, X, fG, fQ) {
  var F, R, Result;
  let last = lasten[i];
  if (last.toosmall) {
    return 0.0;
  }
  F = fG * last.G + fQ * last.Q;
  if (last.lastverteilung) {
    F = F / last.lastverteilung.bmm.value;
  }
  R = F * (1 - (last.Anfang + 0.5 * last.Lange) / L);

  Result = R * X;

  if (X > last.Anfang) {
    if (X < last.Anfang + last.Lange) {
      Result = Result - ((0.5 * F) / last.Lange) * Math.pow(X - last.Anfang, 2);
    } else {
      Result = Result - F * (X - last.Anfang - 0.5 * last.Lange);
    }
  }
  if (X >= L) {
    Result = 0.0;
  }
  return Result;
}

function loopMomentX(X, fG, fQ) {
  /*
    Moment im Schnittstelle X

  */
  var Result = 0.0;
  for (var t = 0; t < lasten.length; t++) {
    Result = Result + momentXFuerLast(t, X, fG, fQ);
  }
  return Result;
}

function maxMoment(fG, fQ) {
  var dL, X, R, Result;

  function isMax(tX) {
    var m1, m2, m3;
    //var found;

    tX = Math.min(Math.max(tX, 0.01), L - 0.01);

    m1 = loopMomentX(tX - 0.01, fG, fQ);
    m2 = loopMomentX(tX, fG, fQ);
    m3 = loopMomentX(tX + 0.01, fG, fQ);

    if (m1 - m2 < 0.0 && m2 - m3 < 0.0) {
      Result = 1;
    } else if (m1 - m2 > 0.0 && m2 - m3 > 0.0) {
      Result = -1;
    } else {
      Result = 0;
    }
    return Result;
  }

  dL = 0.5 * mInput.stutzWeite;
  X = dL;
  R = isMax(X);
  while (R != 0) {
    R = isMax(X);
    if (R != 0) {
      dL = 0.5 * dL;
      X = X + R * dL;
    }
  }
  Result = loopMomentX(X, fG, fQ);
  return Result;
}

function MRiss_fctd(x, index) {
  var Alpha, Abstand, Result;
  /*
    Rissmoment
    an
    stelle
    x
  ...
  */
  Result = 0.0;
  let tdecke = decken[index];
  Abstand = Math.min(1000 * x + 0.5 * a, 1000 * (L - x) + 0.5 * a);
  Alpha = Math.min(1.0, Abstand / tdecke.Lptd);
  Result =
    tdecke.W * (Alpha * tdecke.Sigma_cp + tdecke.fctd) - Alpha * tdecke.Mpt2;
  return Result;
}

function VEdErstRiss(fG, fQ, index) {
  var // X,
    s1,
    s2;
  /*
    Wo
    gehts
    Biegemoment über
    das
    Rissmoment
  */
  s1 = -1;
  s2 = -1;
  //Von links bis Mitte
  var x = 0.0;
  while (x < 0.5 * L && s1 < 0.0) {
    if (loopMomentX(x, fG, fQ) > MRiss_fctd(x, index)) {
      s1 = x;
    }
    x = x + 0.05;
  }
  // Von Rechts bis Mitte
  x = L;
  while (x > 0.5 * L && s2 < 0.0) {
    if (loopMomentX(x, fG, fQ) > MRiss_fctd(x, index)) {
      s2 = x;
    }
    x = x - 0.05;
  }

  var Result = { all: 0.0, a: 0.0, b: 0.0 };
  if (s1 >= 0.0 || s2 >= 0.0) {
    let ra1 = Math.abs(loopQuerKraftFuerLast(s1, fG, fQ));
    let ra2 = Math.abs(loopQuerKraftFuerLast(s2, fG, fQ));
    Result.all = Math.max(ra1, ra2);
    Result.a = ra1;
    Result.b = ra2;
  }
  return Result;
}

function DurchbiegungXFuerLast(i, X, fG, fQ) {
  var F, a, b, c, d, Ra;

  var Result = 0.0;
  let last = lasten[i];
  if (last.toosmall) {
    return 0.0;
  }

  //{Durchbiegung im Mitte Formel aus Polytechnische zakboekje tabel 6.2}
  F = -(fG * last.G + fQ * last.Q);
  a = last.Anfang;
  c = last.Lange;
  b = a + c;
  d = L - a - c / 2;
  Ra = (F * d) / L;

  //{a bis C...}
  if (X > 0 && X <= a) {
    Result =
      (8 * Ra * (Math.pow(X, 3) - Math.pow(L, 2) * X) +
        F *
          X *
          ((8 * Math.pow(d, 3)) / L -
            (2 * b * Math.pow(c, 2)) / L +
            Math.pow(c, 3) / L +
            2 * Math.pow(c, 2))) /
      48;
  }

  //{C bis D...}
  if (X > a && X <= b) {
    Result =
      (8 * Ra * (Math.pow(X, 3) - Math.pow(L, 2) * X) +
        F *
          X *
          ((8 * Math.pow(d, 3)) / L -
            (2 * b * Math.pow(c, 2)) / L +
            Math.pow(c, 3) / L +
            2 * Math.pow(c, 2)) -
        (2 * F * Math.pow(X - a, 4)) / c) /
      48;
  }

  //{D bis B...}
  if (X > b && X <= L) {
    Result =
      (8 * Ra * (Math.pow(X, 3) - Math.pow(L, 2) * X) +
        F *
          X *
          ((8 * Math.pow(d, 3)) / L -
            (2 * b * Math.pow(c, 2)) / L +
            Math.pow(c, 3) / L) -
        8 * F * Math.pow(X - a / 2 - b / 2, 3) +
        F * (2 * b * Math.pow(c, 2) - Math.pow(c, 3))) /
      48;
  }
  return Result;
}

function loopDurchbiegungX(X, fG, fQ) {
  //Durchbiegung im Schnittstelle X...
  var Result = 0.0;
  for (var t = 0; t < lasten.length; t++) {
    Result = Result + DurchbiegungXFuerLast(t, X, fG, fQ);
  }
  return Result;
}

function loopDurchbiegungXFilter(X, fG, fQ, fArr) {
  //{Durchbiegung im Schnittstelle X...}
  var Result = 0.0;
  for (var t = 0; t < 2; t++) {
    //if (fArr.indexOf(t)) {
    Result = Result + DurchbiegungXFuerLast(t, X, fG, fQ);
    // }
  }
  return Result;
}

function isInToleranceRange(maxWert, istWert, tolerance) {
  return istWert > maxWert && istWert < maxWert * tolerance;
}

function mainCalculation() {
  let LFG = mInput.lfg;
  let LFQ = mInput.lfq;

  var lx,
    lx_b,
    alpha_1,
    alpha_1_b,
    alpha_p,
    alpha_p_b,
    u_el,
    u_on,
    u_mp,
    u_kr,
    Lange; // double
  var SubResult = false;
  // var PL = _.cloneDeep(lastitem);
  //{Getdata...}
  L = mInput.stutzWeite;

  //{Auflagerl‰nge min 50 und max 200 mm...}
  a = Math.max(Math.min(1000 * mInput.auflagerTiefe, 200), 50);
  a2 = Math.max(Math.min(1000 * mInput.auflagerTiefe2, 200), 50);

  var deckenDicke = GrundDatenStore.getDicke();

  //debugger;
  VL = mInput.verkehrslast;
  AUSST = mInput.ausstattung;
  // {Alle Lasten...}
  //Eigenlast
  lasten.push(_.cloneDeep(lastitem));
  // {Ausstattung...}
  var aussL = _.merge(_.cloneDeep(lastitem), {
    G: mInput.ausstattung * L * B,
    Q: 0.0,
    Anfang: 0.0,
    Lange: L,
    label: "Ausstattung"
  });
  lasten.push(aussL);
  //Verkehrslast
  var verkehrL = _.merge(_.cloneDeep(lastitem), {
    G: 0.0,
    Q: mInput.verkehrslast * L * B,
    Anfang: 0.0,
    Lange: L,
    label: "Verkehrslast"
  });
  lasten.push(verkehrL);
  // Zusatzlasten
  let zl = BelastungStore.getZusatz().data;
  _.each(zl, function (tzl) {
    if (tzl.enabled) {
      let mo = {
        G: tzl.groesse.fgk.value,
        Q: tzl.groesse.fqk.value,
        Anfang: tzl.abstand.xe.value,
        Lange: tzl.lb.tx.value,
        label: tzl.id,
        toosmall: false
      };
      if (mo.Anfang + mo.Lange < deckenDicke) {
        mo.toosmall = true;
      }

      mo.rawG = mo.G;
      mo.rawQ = mo.Q;
      if (mo.Lange > 0) {
        mo.G = mo.G * mo.Lange;
        mo.Q = mo.Q * mo.Lange;
      }

      CalcStore.calcLastVerteilung(
        BelastungStore.getAll().presets,
        tzl,
        GeometrieStore.getAll().geometriewerte.stuetzweite
      );
      if (mo.G != 0 || mo.Q != 0) {
        lasten.push(
          _.merge(_.cloneDeep(lastitem), mo, {
            lastverteilung: tzl.lastverteilung
          })
        );
      }
    }
  });

  results = [];
  for (var t = 0; t < decken.length; t++) {
    let index = t;
    let decke = decken[index];
    let tresult = _.cloneDeep(tresultitem);
    _.assign(tresult, decke);
    results.push(tresult);
    _.assign(lasten[0], {
      G: decke.SelfWeight * L * B,
      Q: 0.0,
      Anfang: 0.0,
      Lange: L,
      label: "Eigenlast"
    });

    tresult.eigenlast = _.merge(_.cloneDeep(lastitem), {
      G: decke.SelfWeight,
      Q: 0.0,
      Anfang: 0.0,
      Lange: L,
      label: "Eigenlast"
    });
    tresult.ausstattunglast = _.merge(_.cloneDeep(lastitem), {
      G: mInput.ausstattung,
      Q: 0.0,
      Anfang: 0.0,
      Lange: L,
      label: "Ausstattung"
    });
    tresult.verkehrslast = _.merge(_.cloneDeep(lastitem), {
      G: mInput.verkehrslast,
      Q: 0.0,
      Anfang: 0.0,
      Lange: L,
      label: "Verkehrslast"
    });
    Lange = L + a / 1000 + a2 / 1000;
    SubResult = Lange <= decke.MaxLange;
    tresult.PLange = Lange;
    tresult.Lichteweite =
      mInput.feldweite + mInput.auflagerTiefe + mInput.auflagerTiefe2;
    tresult.stutzweite = L;
    if (!SubResult) {
      tresult.Error += "Plänge";
    }
    // console.log("Plänge result: ", tresult.Error, tresult);
    SubResult = VL <= decke.MaxVL * resTolerance;
    if (!SubResult) {
      tresult.Error += " - MAX.VL";
    }
    if (isInToleranceRange(decke.MaxVL, VL, resTolerance)) {
      tresult.warn3Percent += " - MAX.VL";
    }
    // console.log("MAX.VL result: ", tresult.Error, tresult);

    //VRdct1...
    tresult.VEdct1All = MaxQuerkraft(LFG, LFQ);
    tresult.VEdct1 = Math.abs(loopQuerKraftFuerLast(0, LFG, LFQ));
    lx = a + 0.5 * decke.h;
    alpha_1 = Math.min(1.0, lx / decke.Lptd);
    alpha_p = 2 - (decke.h + 2 * a) / decke.Lptd;
    tresult.VRdct1 =
      decke.FIbw_S *
      (Math.sqrt(
        Math.pow(decke.fctd, 2) + alpha_1 * decke.Sigma_cp * decke.fctd
      ) -
        alpha_p * decke.Tau_cpd) *
      (mInput.isweichesauflager("a") ? 1.0 : 1.0);
    SubResult = tresult.VEdct1 <= tresult.VRdct1 * resTolerance;
    // tresult.VEdct1_m = tresult.VEdct1/1.2;
    // tresult.VRdct1_m = tresult.VRdct1/1.2;
    if (!SubResult) {
      tresult.Error += " - VRdct1";
    }
    if (isInToleranceRange(tresult.VRdct1, tresult.VEdct1, resTolerance)) {
      tresult.warn3Percent += " - VRdct1";
    }
    // console.log("result: ", tresult.Error, tresult);

    //VRdct1 Auflager 2...
    tresult.VEdct1A2All = MaxQuerkraft(LFG, LFQ);
    tresult.VEdct1A2 = Math.abs(loopQuerKraftFuerLast(L, LFG, LFQ));
    // lx      = a2 + 0.5 * decke.h;
    lx_b = a2 + 0.5 * decke.h;
    alpha_1_b = Math.min(1.0, lx_b / decke.Lptd);
    alpha_p_b = 2 - (decke.h + 2 * a2) / decke.Lptd;
    //alpha_1 = Math.min( 1.0 , lx / decke.Lptd );
    //alpha_p = 2 - (decke.h+2*a2) / decke.Lptd;
    tresult.VRdct1A2 =
      decke.FIbw_S *
      (Math.sqrt(
        Math.pow(decke.fctd, 2) + alpha_1_b * decke.Sigma_cp * decke.fctd
      ) -
        alpha_p_b * decke.Tau_cpd) *
      (mInput.isweichesauflager("b") ? 1.0 : 1.0);
    SubResult = tresult.VEdct1A2 <= tresult.VRdct1A2 * resTolerance;
    if (!SubResult) {
      tresult.Error += " - VRdct1A2";
    }
    if (isInToleranceRange(tresult.VRdct1A2, tresult.VEdct1A2, resTolerance)) {
      tresult.warn3Percent += " - VRdct1A2";
    }
    // console.log("result: ", tresult.Error, tresult);
    let TAG_BW = "biegeweich ";
    let TAG_BW_50 = "biegeweich 50%";
    tresult.isbiegeweichA = mInput.isweichesauflager("a");
    tresult.isbiegeweichB = mInput.isweichesauflager("b");
    let auflager = [
      {
        wselector: "a",
        ved: "VEdct1",
        vrd: "VRdct1",
        vrdbw: "VRdct1_bwA",
        reqEI: "requiredEI_a",
        bwdata: mInput.biegeweich.auflagerA,
        lx: lx,
        alpha_1: alpha_1,
        alpha_p: alpha_p
      },
      {
        wselector: "b",
        ved: "VEdct1A2",
        vrd: "VRdct1A2",
        vrdbw: "VRdct1_bwB",
        reqEI: "requiredEI_b",
        bwdata: mInput.biegeweich.auflagerB,
        lx: lx_b,
        alpha_1: alpha_1_b,
        alpha_p: alpha_p_b
      }
    ];
    auflager.forEach(function (aitm) {
      if (mInput.isweichesauflager(aitm.wselector)) {
        if (!mInput.is50prozent(aitm.wselector)) {
          calcBiegeweichNormal(aitm, decke, LFG, LFQ, AUSST, VL, lx, tresult);
          //if(tresult[aitm.ved] <= tresult[aitm.vrdbw] * resTolerance ){
          var iterresult = _.cloneDeep(tresult);
          var iterdecke = _.cloneDeep(decke);
          var iteraitm = _.cloneDeep(aitm);
          if (_.includes(tresult.Error, "VRdct1_bw") || tresult.Error === "") {
            calcBwIterativ(
              iteraitm,
              iterdecke,
              LFG,
              LFQ,
              AUSST,
              VL,
              lx,
              iterresult
            );
            tresult[aitm.reqEI] = iterresult[aitm.reqEI];
            console.log(
              "Iterative EI: ",
              decke.Bewehrung,
              tresult.requiredEI_a,
              tresult.requiredEI_b
            );
          }

          //}
          /*
          decke.bw = decke.b_y;
          decke.FIbwS = decke.FIbw_S;
          //console.log(TAG_BW,'50%ok');
          if ((decke.islq == 0.0) && (decke.kxzc == 0.0) && (decke.kzyc == 0.0)) {
            console.log(TAG_BW,aitm.wselector,' using 50% because all zero');
            tresult[aitm.vrdbw] = 0.50 * tresult[aitm.vrd];
          } else {

            let lf =  aitm.bwdata.fuelltiefe.selected.value;
            let b_sl = 1.0;
            let ELstreif = 0.001 * decke.h * 25 * aitm.bwdata.breite;
            let Bstreif = aitm.bwdata.breite;
            let Verbundsteigerung = aitm.bwdata.verbundsteigernd;
            var EIbalk = aitm.bwdata.ei;
            let Lbalk = Math.max(aitm.bwdata.abstand,0.001);


            //{Querkraftbeanspruchung nach Aktivierung einer Verbundwirkung...}
            // {in kN pro Systembreite...}
            let VEdcomp_SFD = MaxQuerkraft(LFG, LFQ);  //{= inkl. Eigenlast}
            if (!aitm.bwdata.tragerunterstuetzung) {
              VEdcomp_SFD = VEdcomp_SFD - 0.5 * LFG * decke.SelfWeight * L * SystemBreite;
            }

            //{Querkraft im Tr‰ger auf Momentennullpunkte...}
            let VEdcomp_Balk = 1.0 * VEdcomp_SFD / SystemBreite * Lbalk / 2;
            if (aitm.bwdata.traegerpos == 'mitte') {
              VEdcomp_Balk = 2 * VEdcomp_Balk;
            }

            // {Streife addieren...}
            VEdcomp_Balk = VEdcomp_Balk + Bstreif * (LFQ * AUSST + LFQ * VL) * Lbalk / 2;

            if (!aitm.bwdata.tragerunterstuetzung) {
              VEdcomp_Balk = VEdcomp_Balk + LFG * ELstreif * Lbalk / 2;
            }
            //{Abminderung infolge Hohlkammerverf¸llung...}
            let Beta_f = Math.max(1 - 0.2 * (lf - 50) / (decke.h - 50), 0.8);

            //{Anzahl der Stege...}
            let m = decke.Hohlraume + 1;

            //{Beiwert zur Ber¸cksichtigung der Verbundwirkung zwischen Tr‰ger und Platte...}
            let mu = 0.2 + 1.1E-03 * decke.h;
            if (Verbundsteigerung) {
              mu = 0.8 + 1.1E-03 * decke.h;
            }

            //{Querbiegesteifigkeit des Plattenquerschnitts...}
            let E_sl = 36000;
            let I_slq = decke.islq * lx;
            let EIslq = E_sl * I_slq * 1.0E-12;


            //{Beiwert zur Ber¸cksichtigung einer Querbiegebeanspruchung...}
              EIbalk = Math.max(EIbalk, 1);
            let kv = 1 + Beta_f * Math.pow(Lbalk, 3) / EIbalk * EIslq / Math.pow(b_sl, 3) * VEdcomp_Balk / (VEdcomp_SFD * Lbalk);

            //{Schubspannung infolge Querschub...}
            let Tau_zyc = 3 / 2 * Beta_f * decke.kzyc * m * mu * 1000 * VEdcomp_SFD / decke.bw / aitm.lx;

            //{Reduktion infolge Biegeweich..}
            let Alpha_bw2 = Math.pow((Math.sqrt(1 + aitm.alpha_1 * decke.Sigma_cp / decke.fctd) * kv * Tau_zyc),2);

            // {Verh‰ltnis zwischen Querkraftbeanspruchung nach Aktivierung einer Verbundwirkung
            //  und der Querkrafttragf‰higkeit bei biegeweicher Lagerung...}
            var Alpha_guess = 0.40;
            var Alpha_comp = VEdcomp_SFD / (0.40 * tresult[aitm.vrd]);

            //{Iteration...}
            var Count = 0;
            while (Count < 9 && Math.abs(Alpha_comp - Alpha_guess) > 0.01) {

              Alpha_guess = Alpha_comp;

              //{Reduktion infolge Biegeweich..}
              var Alpha_bw1 = 1 + Alpha_comp * Beta_f * decke.kxzc * m * mu;

              if ((Math.pow(decke.fctd, 2) + alpha_1 * decke.Sigma_cp * decke.fctd - Alpha_bw2) >= 0) {
                Alpha_comp = VEdcomp_SFD / (  decke.FIbwS / Alpha_bw1 * ( Math.sqrt(Math.pow(decke.fctd, 2) + aitm.alpha_1 * decke.Sigma_cp * decke.fctd - Alpha_bw2) - aitm.alpha_p * decke.Tau_cpd ) );
              } else {
                Alpha_comp = VEdcomp_SFD / (0.40 * tresult[aitm.vrd]);
                Count++;

              }
            }

            if (Count == 9) {
              Alpha_comp = VEdcomp_SFD / (0.40 * tresult[aitm.vrd]);
            }

            //{Reduktion infolge Biegeweich..}
            Alpha_bw1 = 1 + Alpha_comp * Beta_f * decke.kxzc * m * mu;

            if ((Math.pow(decke.fctd, 2) + aitm.alpha_1 * decke.Sigma_cp * decke.fctd - Alpha_bw2) >= 0) {
              tresult[aitm.vrdbw] = decke.FIbwS / Alpha_bw1 * ( Math.sqrt(Math.pow(decke.fctd, 2) + aitm.alpha_1 * decke.Sigma_cp * decke.fctd - Alpha_bw2) - aitm.alpha_p * decke.Tau_cpd );
            } else {
              tresult[aitm.vrdbw] = 1.0;
            }
            let tempvrdbw = tresult[aitm.vrdbw];
            tresult[aitm.vrdbw] = Math.max(Math.min(0.80 * tresult[aitm.vrd],tempvrdbw),0.50 * tresult[aitm.vrd]);
            console.log(TAG_BW,decke.Bewehrung,aitm.wselector,tresult[aitm.ved],tresult[aitm.vrdbw]);
          //  let UnityCheck = tresult.VEdct1 / tresult.VRdct1_bw;
          //  let UCcrit = 'Querkraft (VRdct1 Biegeweiches Aufl)';
            SubResult = tresult[aitm.ved] <= tresult[aitm.vrdbw] * resTolerance ;

            if (!SubResult) {
              tresult.Error += ' - ' + aitm.vrdbw;
            }
            if(isInToleranceRange(tresult[aitm.vrdbw],tresult[aitm.ved],resTolerance)){tresult.warn3Percent += ' - ' + aitm.vrdbw;}
            console.log(TAG_BW," result: ", tresult.Error, tresult);

          }*/
        } else {
          tresult[aitm.vrdbw] = tresult[aitm.vrd] * 0.5;
          SubResult = tresult[aitm.ved] <= tresult[aitm.vrdbw] * resTolerance;

          if (!SubResult) {
            tresult.Error += " - " + aitm.vrdbw;
          }
          if (
            isInToleranceRange(
              tresult[aitm.vrdbw],
              tresult[aitm.ved],
              resTolerance
            )
          ) {
            tresult.warn3Percent += " - " + aitm.vrdbw;
          }
          console.log(TAG_BW_50, " result: ", tresult.Error, tresult);
        }
      }
    });

    //VRdct2...
    //Querkraft bei Stelle der Erstriss...
    let VEdct2Result = VEdErstRiss(LFG, LFQ, index);
    tresult.VEdct2 = VEdct2Result.all;
    tresult.VEdct2A = VEdct2Result.a;
    tresult.VEdct2B = VEdct2Result.b;
    tresult.VRdct2 = decke.V_Rdc_62a;
    SubResult = tresult.VEdct2 <= tresult.VRdct2 * resTolerance;
    if (!SubResult) {
      tresult.Error += " - VRdct2";
    }
    if (isInToleranceRange(tresult.VRdct2, tresult.VEdct2, resTolerance)) {
      tresult.warn3Percent += " - VRdct2";
    }
    //  console.log("result: ", tresult.Error, tresult);

    //MRd
    tresult.MEd = maxMoment(LFG, LFQ);
    tresult.MRd = decke.MRD;
    SubResult = tresult.MEd <= tresult.MRd * resTolerance;
    if (!SubResult) {
      tresult.Error += " - MRdULS";
    }
    if (isInToleranceRange(tresult.MRd, tresult.MEd, resTolerance)) {
      tresult.warn3Percent += " - MRdULS";
    }
    //console.log("result: ", tresult.Error, tresult);

    //Feuerwiederstand
    tresult.MEdF90 = maxMoment(1.0, mInput.kategorie.Phi2);
    tresult.MRdF90 = decke.MRDF90;
    SubResult = tresult.MEdF90 <= tresult.MRdF90 * resTolerance;
    if (!SubResult) {
      tresult.Error += " - MRdF90";
    }
    if (isInToleranceRange(tresult.MRdF90, tresult.MEdF90, resTolerance)) {
      tresult.warn3Percent += " - MRdF90";
    }
    //console.log("result: ", tresult.Error, tresult);

    //Querkraft im Brandfall.
    tresult.VEdF90 = MaxQuerkraft(1.0, mInput.kategorie.Phi2);
    tresult.VEdfiA = Math.abs(
      loopQuerKraftFuerLast(0, 1.0, mInput.kategorie.Phi2)
    );
    tresult.VEdfiB = Math.abs(
      loopQuerKraftFuerLast(L, 1.0, mInput.kategorie.Phi2)
    );
    tresult.VRdF90 = decke.VRDF90;
    SubResult = tresult.VEdF90 <= tresult.VRdF90 * resTolerance;
    if (!SubResult) {
      tresult.Error += " - VRdF90";
    }
    if (isInToleranceRange(tresult.VRdF90, tresult.VEdF90, resTolerance)) {
      tresult.warn3Percent += " - VRdF90";
    }
    //console.log("result: ", tresult.Error, tresult);

    //SLS
    if (mInput.expoKlasse == "XC1") {
      tresult.MEdSLS = maxMoment(1.0, 1.0);
      tresult.MRdSLS = decke.MRXC1;
    } else if (mInput.expoKlasse == "XC3") {
      tresult.MEdSLS = maxMoment(1.0, mInput.kategorie.Phi2);
      tresult.MRdSLS = decke.MRXC3;
    } else {
      tresult.MEdSLS = maxMoment(1.0, 1.0);
      tresult.MRdSLS = decke.MRXC3OPH;
    }
    SubResult = tresult.MEdSLS <= tresult.MRdSLS * resTolerance;
    if (!SubResult) {
      tresult.Error += " - MRX_" + mInput.expoKlasse;
    }
    //console.log("result: ", tresult.Error, tresult);

    //Durchbiegung ins mitte Spannweite...
    u_el = loopDurchbiegungX(0.5 * L, 1.0, mInput.kategorie.Phi2);
    u_on = loopDurchbiegungXFilter(0.5 * L, 1.0, 1.0, [0, 1]); // {Allein Eigenlast und Ausstattung rechnen (0 und 1)}
    u_mp = (1 / 8) * decke.Mpt2 * Math.pow(L, 2);
    u_kr = loopDurchbiegungX(0.5 * L, 1.0, mInput.kategorie.Phi2);
    tresult.Durchbiegung_E =
      (u_el - u_on + (u_kr + u_mp) * decke.PHI) / decke.EI;
    tresult.Durchbiegung_R = L / mInput.durchbiegung;
    SubResult = tresult.Durchbiegung_E <= tresult.Durchbiegung_R * resTolerance;
    if (!SubResult) {
      tresult.Error += " - Durchbiegung";
    }
    if (
      isInToleranceRange(
        tresult.Durchbiegung_R,
        tresult.Durchbiegung_E,
        resTolerance
      )
    ) {
      tresult.warn3Percent += " - Durchbiegung";
    }
    //console.log("result: ", tresult.Error, tresult);

    //Durchhang
    tresult.Durchhang_E = (u_el + u_mp + (u_kr + u_mp) * decke.PHI) / decke.EI;
    tresult.Durchhang_R = L / mInput.durchhang;
    /*
    SubResult = tresult.Durchhang_E <= tresult.Durchhang_R;
    if(!SubResult){ tresult.Error += ' - Durchhang';}
     */
    //console.log("result: ", tresult.Error, tresult);

    tresult.durchbiegungEmm = tresult.Durchbiegung_E * 1000;
    tresult.durchbiegungRmm = tresult.Durchbiegung_R * 1000;
    tresult.durchhangEmm = tresult.Durchhang_E * 1000;
    tresult.durchhangRmm = tresult.Durchhang_R * 1000;

    tresult.durchBformat = "L/" + (L / tresult.Durchbiegung_E).toFixed(0);
    tresult.durchHformat = "L/" + (L / tresult.Durchhang_E).toFixed(0);
    tresult.durchBothformat =
      "DB: " + tresult.durchBformat + " / DH: " + tresult.durchHformat;
  }
  console.log("all result: ", results);
  let d2 = results.concat(); //_.sortBy(results.concat(),['MRD']);
  d2.sort(firstBy("azUnten").thenBy("azOben"));
  /* d2.sort(function (a, b) {
    if (a.MRD > b.MRD) {
      return 1;
    }
    if (a.MRD < b.MRD) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });
  */
  //console.log("all result sort: ", d2);
  calcResults.data = d2;

  //var diter = d2.concat().filter(function(itm){return (mInput.isweichesauflager('a') || mInput.isweichesauflager('b'))}).sort(firstBy("VRdct1_bwA").thenBy("VRdct1_bwB"));
}

function calcBwIterativ(aitm, decke, LFG, LFQ, AUSST, VL, lx, tresult) {
  var startei = 0;
  while (startei <= 2000) {
    aitm.bwdata.ei = startei;
    try {
      calcBiegeweichNormal(aitm, decke, LFG, LFQ, AUSST, VL, lx, tresult);
    } catch (err) {}

    let ved = tresult[aitm.ved];
    let vrd = tresult[aitm.vrdbw];
    if (ved <= vrd * resTolerance) {
      break;
    }
    startei += 10;
  }
  tresult[aitm.reqEI] = aitm.bwdata.ei;
}

function calcBiegeweichNormal(aitm, decke, LFG, LFQ, AUSST, VL, lx, tresult) {
  let TAG_BW = "biegeweich ";
  decke.bw = decke.b_y;
  decke.FIbwS = decke.FIbw_S;
  //console.log(TAG_BW,'50%ok');
  if (decke.islq == 0.0 && decke.kxzc == 0.0 && decke.kzyc == 0.0) {
    console.log(TAG_BW, aitm.wselector, " using 50% because all zero");
    tresult[aitm.vrdbw] = 0.5 * tresult[aitm.vrd];
  } else {
    let lf = aitm.bwdata.fuelltiefe.selected.value;
    let b_sl = 1.0;
    let ELstreif = 0.001 * decke.h * 25 * aitm.bwdata.breite;
    let Bstreif = aitm.bwdata.breite;
    let Verbundsteigerung = aitm.bwdata.verbundsteigernd;
    var EIbalk = aitm.bwdata.ei;
    let Lbalk = Math.max(aitm.bwdata.abstand, 0.001);

    //{Querkraftbeanspruchung nach Aktivierung einer Verbundwirkung...}
    // {in kN pro Systembreite...}
    let VEdcomp_SFD = MaxQuerkraft(LFG, LFQ); //{= inkl. Eigenlast}
    if (!aitm.bwdata.tragerunterstuetzung) {
      VEdcomp_SFD =
        VEdcomp_SFD - 0.5 * LFG * decke.SelfWeight * L * SystemBreite;
    }

    //{Querkraft im Tr‰ger auf Momentennullpunkte...}
    let VEdcomp_Balk = (((1.0 * VEdcomp_SFD) / SystemBreite) * Lbalk) / 2;
    if (aitm.bwdata.traegerpos == "mitte") {
      VEdcomp_Balk = 2 * VEdcomp_Balk;
    }

    // {Streife addieren...}
    VEdcomp_Balk =
      VEdcomp_Balk + (Bstreif * (LFQ * AUSST + LFQ * VL) * Lbalk) / 2;

    if (!aitm.bwdata.tragerunterstuetzung) {
      VEdcomp_Balk = VEdcomp_Balk + (LFG * ELstreif * Lbalk) / 2;
    }
    //{Abminderung infolge Hohlkammerverf¸llung...}
    let Beta_f = Math.max(1 - (0.2 * (lf - 50)) / (decke.h - 50), 0.8);

    //{Anzahl der Stege...}
    let m = decke.Hohlraume + 1;

    //{Beiwert zur Ber¸cksichtigung der Verbundwirkung zwischen Tr‰ger und Platte...}
    let mu = 0.2 + 1.1e-3 * decke.h;
    if (Verbundsteigerung) {
      mu = 0.8 + 1.1e-3 * decke.h;
    }

    //{Querbiegesteifigkeit des Plattenquerschnitts...}
    let E_sl = 36000;
    let I_slq = decke.islq * lx;
    let EIslq = E_sl * I_slq * 1.0e-12;

    //{Beiwert zur Ber¸cksichtigung einer Querbiegebeanspruchung...}
    EIbalk = Math.max(EIbalk, 1);
    let kv =
      1 +
      (((((Beta_f * Math.pow(Lbalk, 3)) / EIbalk) * EIslq) /
        Math.pow(b_sl, 3)) *
        VEdcomp_Balk) /
        (VEdcomp_SFD * Lbalk);

    //{Schubspannung infolge Querschub...}
    let Tau_zyc =
      ((3 / 2) * Beta_f * decke.kzyc * m * mu * 1000 * VEdcomp_SFD) /
      decke.bw /
      aitm.lx;

    //{Reduktion infolge Biegeweich..}
    let Alpha_bw2 = Math.pow(
      Math.sqrt(1 + (aitm.alpha_1 * decke.Sigma_cp) / decke.fctd) *
        kv *
        Tau_zyc,
      2
    );

    // {Verh‰ltnis zwischen Querkraftbeanspruchung nach Aktivierung einer Verbundwirkung
    //  und der Querkrafttragf‰higkeit bei biegeweicher Lagerung...}
    var Alpha_guess = 0.4;
    var Alpha_comp = VEdcomp_SFD / (0.4 * tresult[aitm.vrd]);
    //if(Alpha_comp == Infinity){debugger;}

    //{Iteration...}
    var Count = 0;
    while (Count < 9 && Math.abs(Alpha_comp - Alpha_guess) > 0.01) {
      // if(Alpha_comp == Infinity){debugger;}
      Alpha_guess = Alpha_comp;

      //{Reduktion infolge Biegeweich..}
      var Alpha_bw1 = 1 + Alpha_comp * Beta_f * decke.kxzc * m * mu;
      // if(Alpha_comp == Infinity){debugger;}
      if (
        Math.pow(decke.fctd, 2) +
          aitm.alpha_1 * decke.Sigma_cp * decke.fctd -
          Alpha_bw2 >=
        0
      ) {
        Alpha_comp =
          VEdcomp_SFD /
          ((decke.FIbwS / Alpha_bw1) *
            (Math.sqrt(
              Math.pow(decke.fctd, 2) +
                aitm.alpha_1 * decke.Sigma_cp * decke.fctd -
                Alpha_bw2
            ) -
              aitm.alpha_p * decke.Tau_cpd));
        // if(Alpha_comp == Infinity){debugger;}
      } else {
        Alpha_comp = VEdcomp_SFD / (0.4 * tresult[aitm.vrd]);
        Count++;
        // if(Alpha_comp == Infinity){debugger;}
      }
      if (Alpha_comp == Infinity) {
        Count = 9;
      }
    }

    if (Count == 9) {
      Alpha_comp = VEdcomp_SFD / (0.4 * tresult[aitm.vrd]);
    }

    //{Reduktion infolge Biegeweich..}
    Alpha_bw1 = 1 + Alpha_comp * Beta_f * decke.kxzc * m * mu;

    if (
      Math.pow(decke.fctd, 2) +
        aitm.alpha_1 * decke.Sigma_cp * decke.fctd -
        Alpha_bw2 >=
      0
    ) {
      tresult[aitm.vrdbw] =
        (decke.FIbwS / Alpha_bw1) *
        (Math.sqrt(
          Math.pow(decke.fctd, 2) +
            aitm.alpha_1 * decke.Sigma_cp * decke.fctd -
            Alpha_bw2
        ) -
          aitm.alpha_p * decke.Tau_cpd);
    } else {
      tresult[aitm.vrdbw] = 1.0;
    }
    let tempvrdbw = tresult[aitm.vrdbw];
    tresult[aitm.vrdbw] = Math.max(
      Math.min(0.75 * tresult[aitm.vrd], tempvrdbw),
      0.5 * tresult[aitm.vrd]
    );
    // console.log(TAG_BW,decke.Bewehrung,aitm.wselector,tresult[aitm.ved],tresult[aitm.vrdbw]);
    //  let UnityCheck = tresult.VEdct1 / tresult.VRdct1_bw;
    //  let UCcrit = 'Querkraft (VRdct1 Biegeweiches Aufl)';
    var SubResult = tresult[aitm.ved] <= tresult[aitm.vrdbw] * resTolerance;

    if (!SubResult) {
      tresult.Error += " - " + aitm.vrdbw;
    }
    if (
      isInToleranceRange(tresult[aitm.vrdbw], tresult[aitm.ved], resTolerance)
    ) {
      tresult.warn3Percent += " - " + aitm.vrdbw;
    }
    console.log(TAG_BW, " result: ", tresult.Error, tresult);
  }
}

function startCalc() {
  let td = _.filter(bewehrungen.data, function (item) {
    return item.hasOwnProperty("Sigma_cp");
  });
  _.each(td, function (itm) {
    _.each(itm, function (val, key) {
      if (!isNaN(val)) {
        itm[key] = parseFloat(val);
      }
    });
  });

  decken = td.concat();
  lasten = [];
  let feld = BelastungStore.getFeld();
  let geo = GeometrieStore.getAll().geometriewerte;
  let grund = GrundDatenStore.getAll();
  _.assign(mInput.kategorie, { Phi1: feld.psi1.val, Phi2: feld.psi2.val });
  _.assign(mInput, {
    stutzWeite: geo.stuetzweite,
    auflagerTiefe: geo.auflager.auflagerAKorr,
    auflagerTiefe2: geo.auflager.auflagerBKorr,
    verkehrslast: feld.verkehrslast.selected.val + feld.lwzuschlag.selected.val,
    ausstattung: feld.auflast.selected.val,
    biegeweich: geo.biegeweich,
    feldweite: geo.feldweite
  });
  mInput.lfq = feld.gammaQ.selected.val;
  mInput.lfg = feld.gammaG.selected.val;
  mInput.durchhang = feld.durchhang.selected.val;
  mInput.durchbiegung = feld.durchbiegung.selected.val;
  mInput.expoKlasse = grund.deckenWerte.expoklasse.selected.key;
  let weichf = function (seite) {
    let path = seite == "a" ? "AlAselected" : "AlBselected";
    return geo.auflager[path].key != "mauer";
  };
  mInput.isweichesauflager = weichf;
  mInput.is50prozent = (seite) => {
    let path = seite == "a" ? "AlAselected" : "AlBselected";
    return geo.auflager[path].key == "_50prozent";
  };

  let selblist = CalcStore.getSelectedBewehrung();
  let selb = undefined;
  if (selblist.length > 0) {
    selb = selblist[0];
  }

  mainCalculation();
  if (selb != undefined) {
    let newbew = _.filter(calcResults.data, { Bewehrung: selb.Bewehrung });
    if (newbew.length) {
      newbew[0].selected = true;
    }
  }
}

function calcBewehrung() {
  let aw = GrundDatenStore.getAll().deckenWerte.decken.auswahl;
  let sel = GrundDatenStore.getAll().deckenWerte.decken.selected;
  let bew = _.map(
    _.filter(aw, { Plattentype: sel.Plattentype }),
    function (itm) {
      console.log("display", itm.Display);
      return assign({ mrd: 0, vrda: 0, vrdb: 0 }, _.cloneDeep(itm));
    }
  );

  bewehrungen.data = bew;
}

const CalcStore = assign({}, BaseStore, {
  getAll() {
    return { bewehrungen: calcResults };
  },
  getBewehrungen() {
    return calcResults;
  },
  getLasten() {
    return lasten.concat();
  },

  getSelectedBewehrung() {
    console.log("data", calcResults.data);
    return _.filter(calcResults.data, { selected: true });
  },

  getEigenLasten() {
    /*let bew = this.getSelectedBewehrung();
      if(bew.length > 0){
        let tbw =  bew[0];
        return [tbw.eigenlast,tbw.ausstattunglast];
      }*/
    return [];
  },

  getLasten4Picture() {
    let feld = BelastungStore.getFeld();
    let geo = GeometrieStore.getAll().geometriewerte;
    let grund = GrundDatenStore.getAll();
    let ql = [
      {
        G: 0.0,
        rawG: 0.0,
        Q: feld.verkehrslast.selected.val,
        rawQ: feld.verkehrslast.selected.val,
        Anfang: 0.0,
        Lange: geo.stuetzweite,
        label: "Verkehrslast q1"
      },
      {
        G: 0.0,
        Q: feld.lwzuschlag.selected.val,
        rawG: 0.0,
        rawQ: feld.lwzuschlag.selected.val,
        Anfang: 0.0,
        Lange: geo.stuetzweite,
        label: "LW-Zuschlag q2"
      }
    ];

    let gl = [
      {
        G: bewehrungen.data[0].SelfWeight,
        Q: 0.0,
        rawG: bewehrungen.data[0].SelfWeight,
        rawQ: 0.0,
        Anfang: 0.0,
        Lange: geo.stuetzweite,
        label: "Eigengewicht g"
      },
      {
        G: feld.auflast.selected.val,
        Q: 0.0,
        rawG: feld.auflast.selected.val,
        rawQ: 0.0,
        Anfang: 0.0,
        Lange: geo.stuetzweite,
        label: "Zus. Auflast g1"
      }
    ];

    return { g: gl, q: ql };
  },

  getInput() {
    return _.cloneDeep(mInput);
  },

  calcLastVerteilung(presets, stateobj, sw) {
    var bma = 1.0;
    var bmm = 1.0;
    var bmb = 1.0;
    var deckenDicke = GrundDatenStore.getDicke() * 100;
    console.log("deckendicke: ", deckenDicke);
    if (presets.lastverteilung == "1m") {
      stateobj.lastverteilung = {
        bma: { label: "1.0", value: 1.0 },
        bmm: { label: "1.0", value: 1.0 },
        bmb: { label: "1.0", value: 1.0 }
      };
    } else {
      if (stateobj.lastart.lastoption == "einzellast") {
        bma = ((deckenDicke - 4) / 100) * 7; //0.15 + 0.5 * stateobj.abstand.xe.value ;
        bmb = ((deckenDicke - 4) / 100) * 7; //0.15 + 0.5 * (sw - stateobj.abstand.xe.value);
        bmm =
          0.1 +
          2.5 *
            stateobj.abstand.xe.value *
            (1 - stateobj.abstand.xe.value / sw);
      } else {
        bma = ((deckenDicke - 4) / 100) * 7; //stateobj.abstand.xe.value + 0.5*stateobj.lb.tx.value;
        bmb = ((deckenDicke - 4) / 100) * 7; //sw - (stateobj.abstand.xe.value + 0.5*stateobj.lb.tx.value);
        let abst = stateobj.abstand.xe.value + 0.5 * stateobj.lb.tx.value;
        bmm = 0.1 + 2.5 * abst * (1 - abst / sw);
      }
      bmm = Math.min(4, bmm);
      stateobj.lastverteilung = {
        bma: { label: bma.toFixed(2), value: bma },
        bmm: { label: bmm.toFixed(2), value: bmm },
        bmb: { label: bmb.toFixed(2), value: bmb }
      };
    }
    return stateobj;
  },

  // register store with dispatcher, allowing actions to flow through
  dispatcherIndex: Dispatcher.register(function handleAction(payload) {
    const action = payload.action;

    switch (action.type) {
      case Constants.ActionTypes.DATA_LOADED:
        break;
      case Constants.ActionTypes.CREATE_PDF:
        break;
      case Constants.ActionTypes.PRINT_PDF:
        break;
      case Constants.ActionTypes.BEWEHRUNG_SELECTED:
        CalcStore.emitChange();
        break;
      default:
        Dispatcher.waitFor([
          GeometrieStore.dispatcherIndex,
          GrundDatenStore.dispatcherIndex,
          BelastungStore.dispatcherIndex
        ]);
        calcBewehrung();
        startCalc();
        CalcStore.emitChange();
    }
  })
});

export default CalcStore;
