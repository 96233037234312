import React from 'react';
import CalcStore from '../../stores/CalcStore';
import BelastungStore from '../../stores/BelastungStore';
import ActionCreator from '../../actions/StatikActionCreator';
import DropDown from '../../components/common/DropDown';
import ComboBox from '../../components/common/ComboBox';
import _ from 'lodash'
//import numeral from 'numeral';
//import numde from 'numeral/languages/de';

class Belastungen extends React.Component {
    constructor() {
        super();
        this._onChange = this._onChange.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this.state = BelastungStore.getAll();

    }
    _handleChange() {

    }

    _onChange() {
        console.log("belastung change");
        let tk = BelastungStore.getAll();
        this.setState(tk);

    }

    _onSelectKat(item) {
        console.log("kat select");

        let valobj = _.set(BelastungStore.getAll(),'kategorien.selected',item);
        ActionCreator.setBelastungKategorie(valobj);
    }
    _onSelectFeld(item,path) {

      /*
      let checkedval = item.match(/[+-]?\d+(\,\d+)?/g);
      let lastchar = item.substr(-1);
      var newval = "0";//this.props.value;
      if (checkedval && checkedval.length > 0 ) {
        newval = checkedval[0];
        if(lastchar == "," && newval.indexOf(",") < 0 ){
          newval += ",0";
        }
      }

       // let parsedval = parseFloat(newval.replace(",","."));

        //console.log("last:",item, checkedval);
       // numeral.language('de',numde);
       */
      var pval = item;
        let valobj = _.set(BelastungStore.getAll(),'feld.'+path,{label:pval,val:pval});
        ActionCreator.setBelastung(valobj);
    }

    _onSelectGamma(item,path) {
      let valobj = _.set(BelastungStore.getAll(),'feld.'+path,item);
      ActionCreator.setBelastung(valobj);
    }
  _onSelectDurchbiegung(item,path) {
    let valobj = _.set(BelastungStore.getAll(),'feld.'+path,item);
    ActionCreator.setBelastung(valobj);
  }
  _onSelectDurchhang(item,path) {
    let valobj = _.set(BelastungStore.getAll(),'feld.'+path,item);
    ActionCreator.setBelastung(valobj);
  }

    componentDidMount() {
        BelastungStore.addChangeListener(this._onChange);
        CalcStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        BelastungStore.removeChangeListener(this._onChange);
        CalcStore.removeChangeListener(this._onChange);

    }

    render() {
      var self = this;
      let p1 = this.state.feld.psi1.val.toFixed(2).replace(".",",");
      let p2 = this.state.feld.psi2.val.toFixed(2).replace(".",",");
        return (
            <div>
                <div className="row mg-bottom-15">
                    <h3>Einwirkungen</h3>
                    <hr/>
                    <h4>Kategorie</h4>
                    <hr/>
                    <DropDown id="katselect" onSelect={this._onSelectKat} labelField="katlabel" selectedItem={this.state.kategorien.selected} dataProvider={this.state.kategorien.auswahl}/>
                </div>
                <div className="row mg-bottom-15">
                    <h4>Feld</h4>
                    <hr/>
                    <ComboBox label={'Verkehrslast q1'} dataProvider={this.state.feld.verkehrslast.auswahl} value={this.state.feld.verkehrslast.selected} einheit={'kN/m<sup>2</sup>'} labelField={'label'} valueField={'val'}
                              onChange={(itm) => (self._onSelectFeld(itm,'verkehrslast.selected'))} styles={["mg-bottom-15"]}/>
                    <ComboBox label={'LW-Zuschlag q2'} dataProvider={this.state.feld.lwzuschlag.auswahl} value={this.state.feld.lwzuschlag.selected} einheit={'kN/m<sup>2</sup>'} labelField={'label'} valueField={'val'}
                              onChange={(itm) => (self._onSelectFeld(itm,'lwzuschlag.selected'))} styles={["mg-bottom-15"]}/>
                    <ComboBox label={'Zus. Auflast g1'} dataProvider={this.state.feld.auflast.auswahl} value={this.state.feld.auflast.selected} einheit={'kN/m<sup>2</sup>'} labelField={'label'} valueField={'val'}
                            onChange={(itm) => (self._onSelectFeld(itm,'auflast.selected'))} styles={["mg-bottom-15"]}/>
                </div>
              <div className="row mg-bottom-15">
                <h4>Durchbiegung</h4>
                <hr/>
                <div className="col-md-6 pad-left-0 zup300">
                  <div className="input-group" >
                    <div className="input-group-text ">Durchbiegung</div>
                    
                      <DropDown onSelect={(itm) => (self._onSelectDurchbiegung(itm,'durchbiegung.selected'))} labelField="label" selectedItem={this.state.feld.durchbiegung.selected} dataProvider={this.state.feld.durchbiegung.auswahl}/>
                    
                  </div>
                </div>
                <div className="col-md-6 zup300">
                  {/*<div className="input-group" >
                    <div className="input-group-addon ">Durchhang</div>
                    <div className="input-group-btn">
                      <DropDown onSelect={(itm) => (self._onSelectDurchhang(itm,'durchhang.selected'))} labelField="label" selectedItem={this.state.feld.durchhang.selected} dataProvider={this.state.feld.durchhang.auswahl}/>
                    </div>
                  </div>*/}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pad-left-0">
                  <h4>Teilsicherheitsbeiwerte</h4>
                  <hr/>
                  <div className="input-group mg-bottom-15" >
                    <div className="input-group-text ">𝛾<sub>q</sub></div>
                    
                      <DropDown onSelect={(itm) => (self._onSelectGamma(itm,'gammaQ.selected'))} labelField="label" selectedItem={this.state.feld.gammaQ.selected} dataProvider={this.state.feld.gammaQ.auswahl}/>
                    
                  </div>
                  <div className="input-group" >
                    <div className="input-group-text ">𝛾<sub>g</sub></div>
                    
                      <DropDown onSelect={(itm) => (self._onSelectGamma(itm,'gammaG.selected'))} labelField="label" selectedItem={this.state.feld.gammaG.selected} dataProvider={this.state.feld.gammaG.auswahl}/>
                    
                  </div>
                </div>
                <div className="col-md-6">
                  <h4>Kombinationsbeiwerte</h4>
                  <hr/>
                  <div className={"input-group mg-bottom-15"} >
                    <div className="input-group-text min-w-70">Ψ<sub>1</sub></div>
                    <input type="text" className="form-control " disabled={true} value={p1} />
                  </div>
                  <div className={"input-group"} >
                    <div className="input-group-text min-w-70">Ψ<sub>2</sub></div>
                    <input type="text" className="form-control " disabled={true} value={p2} />
                  </div>
                </div>
              </div>
            </div>

        );
    }
}

export default Belastungen;
