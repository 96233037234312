import PropTypes from 'prop-types';
import React from 'react';
import numeral from 'numeral/numeral';
import 'numeral/locales';
var ReactPropTypes = PropTypes;

class InputAndLabel extends React.Component {
    constructor() {
        super();
        this._onChange = this._onChange.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._notifyCB = this._notifyCB.bind(this);
      this._keyDown = this._keyDown.bind(this);
      this._formatToString = this._formatToString.bind(this);
        this.state = {isfocused:false,val:''};
    }

    _handleChange() {
    }

    _onChange(e){
      this.setState({isfocused:true,val:e.target.value});
    }
    _onBlur(e){
      numeral.locale('de');
      var num = numeral(e.target.value);
      let tv = num.value();
      this.state = {isfocused:false,val:this._formatToString(tv)};
      this._notifyCB(tv);
    }

    _notifyCB(val){
      this.props.onChange(val);
    }
    _onFocus(){
      this.setState({isfocused:true,val:this._formatToString(this.props.value)});
    }
  _keyDown(ev){
    if(ev.keyCode == 13 && this.props.onChange){

      ev.target.blur();
    }
  }

    _formatToString(v){


      numeral.locale('de');
      let prec = (this.props.precision == undefined)? 2:this.props.precision;
      var fs = '0,0.00';
      if(prec == 1){
        fs = '0,0.0';
      }
      if(prec == 0){
        fs = '0,0';
      }
      //console.log("fromattimg: ",this.props.label,prec,fs);

     // if(this.props.isdecimal){
        return numeral(v).format(fs);

      //}
      //return  numeral(v).format('0,0');
    }

    componentDidMount() {

      this.setState({isfocused:false,val:this._formatToString(this.props.value)});
    }

   
    UNSAFE_componentWillReceiveProps(nextprops){
      //if(this.state.val != nextprops.value){
      this.setState({isfocused:false,val:this._formatToString(nextprops.value)});
      //}
    }

    render() {
      var addon;
      var self = this;
      if(this.props.toolTip) {

        addon = <span className="input-group-text inputaddon-minw" dangerouslySetInnerHTML={{__html: this.props.label}} data-toggle="tooltip" data-placement="bottom" title={this.props.toolTip}></span>;
      }else {
        addon =  <span className="input-group-text inputaddon-minw" dangerouslySetInnerHTML={{__html: this.props.label}}></span>;
      }



      return(
        <div className="form-group">

          <div className="input-group">
            {addon}
            {/*<div className="input-group-addon inputaddon-minw" dangerouslySetInnerHTML={{__html: this.props.label}}></div>*/}
            <input type="text"  className={"form-control numberinput"}
                   disabled={this.props.disabled} value={self.state.val} onKeyDown={this._keyDown} onFocus={(e)=>{self._onFocus(e);}} onChange={(e)=>{self._onChange(e);}} onBlur={(e)=>{self._onBlur(e);}} />
            {/* <NumericInput className="form-control numberinput" defaultValue={0.0} step={0.01} disabled={this.props.disabled} precision={2} value={this.props.value} onKeyDown={this._keyDown} onBlur={this._handleBlur}/>*/}

            {/* <input type="number" step="0.01" defaultValue={0.00} className="form-control numberinput" disabled={this.props.disabled} value={this.props.value.toFixed(2)} onBlur={this._handleBlur}  />*/}
            <span className="input-group-text" dangerouslySetInnerHTML={{__html: this.props.einheit}}></span>
          </div>
        </div>

      );
    }
}

InputAndLabel.propTypes = {
  id: ReactPropTypes.string,
  defaultValue:ReactPropTypes.number,
  label:ReactPropTypes.string,
  onChange:ReactPropTypes.func,
  einheit:ReactPropTypes.string,
  disabled:ReactPropTypes.bool,
  value:ReactPropTypes.number,
  toolTip:ReactPropTypes.string,
  precision:ReactPropTypes.number,

};

export default InputAndLabel;
