import PropTypes from 'prop-types';
import React from 'react';
import numeral from 'numeral/numeral';
import 'numeral/locales';
import _ from 'lodash'
var ReactPropTypes = PropTypes;

class ComboBox extends React.Component {
    constructor() {
        super();
        this._onChange = this._onChange.bind(this);
        this._handleChange = this._handleChange.bind(this);
      this._handleBlur = this._handleBlur.bind(this);
      this._notifyCB = this._notifyCB.bind(this);
      this._keyDown = this._keyDown.bind(this);
      this._didSelect = this._didSelect.bind(this);
      this._onFocus = this._onFocus.bind(this);
        this.state = {};
    }

    _handleChange() {

        //this.setState({invalue: newval});
       // this.props.onChange(parseFloat(parseFloat(event.target.value).toFixed(2)));
     // this.props.onChange(parseFloat(sc));
    }
    _didSelect(item) {
      debugger;
      let tv = item[this.props.valueField];
      let tvobj = _.merge({},this.props.value,{val:tv});
      this.setState({isfocused:false,val:this._formatToString(tv),valobj:tvobj});
      this._notifyCB(tv);



    // this.props.onChange(parseFloat(parseFloat(event.target.value).toFixed(2)));
  }
  _handleBlur(e){
    numeral.locale('de');
    var num = numeral(e.target.value);
    let tv = num.value();
    let tvobj = _.merge({},this.props.value,{val:tv});
    this.setState({isfocused:false,val:this._formatToString(tv),valobj:tvobj});
    this._notifyCB(tv);
  }

  _notifyCB(val){
    if(this.props.onChange) {
      this.props.onChange(val);
    }
  }
  _onFocus(){
    //this.setState({isfocused:true,val:this._formatToString(this.props.value.val),valobj:this.props.value});
  }
  _keyDown(ev){
    if(ev.keyCode == 13 ){

      ev.target.blur();
    }
  }

  _formatToString(v){
    numeral.locale('de');
    // if(this.props.isdecimal){
    return numeral(v).format('0,0.00');

    //}
    //return  numeral(v).format('0,0');
  }


    _onChange(e){
      this.setState({isfocused:true,val:e.target.value});
    }
    UNSAFE_componentWillReceiveProps(nextprops){
   // if(this.state.val != nextprops.value.val){
      this.setState({isfocused:false,val:this._formatToString(nextprops.value.val),valobj:nextprops.value});
    //}
  }

    componentDidMount(){

      this.setState({isfocused:false,val:this._formatToString(this.props.value.val),valobj:this.props.value});
    }

    

    render() {
        var self = this;
        let styles = this.props.styles || [];


        return (
            <div className={"input-group " + styles.join(' ')} >
                <div className="input-group-text inputaddon-minw">{this.props.label}</div>
              <input type="text"  className={"form-control numberinput"}
                     disabled={this.props.disabled} value={self.state.val} onKeyDown={this._keyDown} onFocus={(e)=>{self._onFocus(e);}} onChange={(e)=>{self._onChange(e);}} onBlur={(e)=>{self._handleBlur(e);}} />
                {/*<input type="number" step="0.01" className="form-control numberinput" disabled={this.props.disabled} value={this.props.value.val.toFixed(2)}  onChange={this._handleChange} />*/}
                <div className="input-group-text" dangerouslySetInnerHTML={{__html: this.props.einheit}}></div>
               
                    <button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu">

                        {this.props.dataProvider.map(function (item) {
                            return <li key={item.key} onClick={self._didSelect.bind(self,item)}><a >{item[self.props.labelField]}</a></li>;
                        })}

                    </ul>
               
                {/* <div className="input-group-addon">{this.props.einheit}</div>*/}
            </div>

    );
    }
}

export default ComboBox;

ComboBox.propTypes = {
    id: ReactPropTypes.string,
    defaultValue:ReactPropTypes.string,
    label:ReactPropTypes.string,
    onChange:ReactPropTypes.func,
    einheit:ReactPropTypes.string,
    disabled:ReactPropTypes.bool,
    value:ReactPropTypes.object,
    dataProvider:ReactPropTypes.arrayOf(PropTypes.object),
    labelField:ReactPropTypes.string,
    valueField:ReactPropTypes.string,
    styles:ReactPropTypes.array
};
