import Dispatcher from '../dispatcher/Dispatcher';
import Constants from '../constants/constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import _ from 'lodash';

var kategorien = {auswahl:[],selected:{}};
var feld = {
    verkehrslast:{
        auswahl:[{label:'0.00',val:0},{label:'0.75',val:0.75},{label:'1.50',val:1.5},{label:'2.00',val:2.0},{label:'3.50',val:3.5},{label:'5.00',val:5.0}],
        selected:{label:'2.00',val:2.0}
    },
    lwzuschlag:{
        auswahl:[{label:'0.00',val:0},{label:'0.80',val:0.8},{label:'1.20',val:1.2}],
        selected:{label:'0.00',val:0}
    },
    auflast:{
      auswahl:[{label:'0.00',val:0},{label:'0.50',val:0.5},{label:'0.75',val:0.75},{label:'1.00',val:1.0},{label:'1.25',val:1.25},{label:'1.50',val:1.5},{label:'2.00',val:2.0}],
      selected:{label:'0.00',val:0.0}
    },
    gammaQ:{
      auswahl:[{label:'1.30',val:1.3},{label:'1.50',val:1.5}],
      selected:{label:'1.50',val:1.5}
    },
    gammaG:{
      auswahl:[{label:'1.00',val:1.0},{label:'1.35',val:1.35}],
      selected:{label:'1.35',val:1.35}
    },
    durchbiegung:{
      auswahl:[{label:'L/250',val:250.0},{label:'L/300',val:300.0},{label:'L/350',val:350.0},{label:'L/400',val:400.0},{label:'L/500',val:500.0}],
      selected:{label:'L/500',val:500.0}
    },
    durchhang:{
      auswahl:[{label:'L/150',val:150.0},{label:'L/200',val:200.0},{label:'L/250',val:250.0},{label:'L/300',val:300.0}],
      selected:{label:'L/250',val:250.0}
    },
    psi1:{
      val:0.5
    },
    psi2:{
      val:0.5
    }
};
var zusatzlasten = {
  headers:[
    {label:'ID',key:'id'},
    {label:'Lastart',key:'lastart.lastoptionausgabe'},
    {label:'Abstand(m)',key:'abstand.xe.value'},
    {label:'Länge(m)',key:'lb.tx.value'},
    {label:'F<sub>Q</sub>',key:'groesse.fqk.value'},
    {label:'F<sub>G</sub>',key:'groesse.fgk.value'},
    {label:'An/Aus',key:'__cb:enabled'},
      {label:'Action',key:'__2bt'}
  ],
  data:[]
};
var presets = {
  lastverteilung:'1m'
};

function setFeldfromKat(){
  let tvl =  parseFloat(kategorien.selected.QK);
  _.assign(feld.verkehrslast.selected,{label:tvl,val:tvl});
  let tlw =  parseFloat(kategorien.selected.LW);
  _.assign(feld.lwzuschlag.selected,{label:tlw,val:tlw});
  let tal =  parseFloat(kategorien.selected.GK);
  _.assign(feld.auflast.selected,{label:tal,val:tal});
}

function calcpsi() {
  feld.psi1.val = parseFloat(kategorien.selected.PHI11);
  feld.psi2.val = parseFloat(kategorien.selected.PHI2i);
}

function katsLoaded(kdata) {

    kategorien.auswahl = kdata.dataroot.Kategoriebeiwerte.concat();
    var numkats = 0;
    kategorien.auswahl = kategorien.auswahl.map(function(ki) {
        numkats++;
        return assign(ki,{key:numkats + '',katlabel:ki.Kategorie + ' - ' + ki.Text});
    });
    kategorien.selected = kategorien.auswahl[0];
}

function getDataForSaving(){
    var k2 = _.cloneDeep(kategorien);
    var f2 = _.cloneDeep(feld);
    let savedata = {
      kategorien:_.omit(k2,'auswahl'),
      feld : _.mapValues(f2,(o)=>{return _.omit(o,'auswahl');}),
      zusatzlasten: {data:_.cloneDeep(zusatzlasten.data)},
      presets:_.cloneDeep(presets)
    };
    return savedata;
}

function applySavedData(sdata){
  _.merge(kategorien,sdata.kategorien);
  _.merge(feld,sdata.feld);
  _.merge(zusatzlasten,sdata.zusatzlasten);
  _.merge(presets,sdata.presets);
}



const BelastungStore = assign({}, BaseStore, {

    getKats() {
      return kategorien;
    },
    getFeld() {
        return feld;
    },
    getZusatz() {
      return zusatzlasten;
    },

    getAll() {
        return {kategorien:kategorien,feld:feld,zusatzlasten:zusatzlasten,presets:presets};
    },

    getSavedata(){
      return getDataForSaving();
    },
    setSavedData(sd){
      applySavedData(sd);
      BelastungStore.emitChange();
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function handleAction(payload) {
        const action = payload.action;

        switch (action.type) {
            case Constants.ActionTypes.DATA_LOADED:
                katsLoaded(action.katdata.json);
                setFeldfromKat();
                calcpsi();
                BelastungStore.emitChange();
                break;
            case Constants.ActionTypes.BELASTUNG_EINGABE:
                assign(kategorien,action.belastung.kategorien);
                assign(feld,action.belastung.feld);
                calcpsi();
                BelastungStore.emitChange();
                break;
            case Constants.ActionTypes.BELASTUNG_EINGABE_KATEGORIE:
              assign(kategorien,action.belastung.kategorien);
              setFeldfromKat();
              calcpsi();
              BelastungStore.emitChange();
              break;
            case Constants.ActionTypes.ADD_ZUSATZLAST:
                zusatzlasten.data.push(_.set(action.last,'id','Last '+ (zusatzlasten.data.length+1)));
                BelastungStore.emitChange();
                break;
            case Constants.ActionTypes.EDIT_ZUSATZLAST:
                _.assign(_.find(zusatzlasten.data,{id:action.last.id}),action.last);
               // zusatzlasten.data.push(_.set(action.last,'id','Last '+ (zusatzlasten.data.length+1)));
                BelastungStore.emitChange();
                break;
            case Constants.ActionTypes.SET_PRESET:
              _.assign(presets,action.preset.presets);
             BelastungStore.emitChange();
            case Constants.ActionTypes.TRIGGER_CHANGE:
              BelastungStore.emitChange();
              break;
            case Constants.ActionTypes.REMOVE_ZUSATZLAST:
                _.remove(zusatzlasten.data, function(d) {
                    return action.last.id === d.id;
                });

                BelastungStore.emitChange();
                break;

          case Constants.ActionTypes.FILE_LOADED:
            applySavedData(action.data.BelastungsStore);
            BelastungStore.emitChange();


        }
    })
});

export default BelastungStore;
