import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

class DropDown extends React.Component {
  _didSelect(item) {
    this.props.onSelect(item);
  }

  componentDidMount() {}

  render() {
    let self = this;
    let styles = this.props.styles || [];

    return (
      <div className={"btn-group " + styles.join(" ")}>
        <button
          className="btn btn-default btn-sm dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {self.props.selectedItem[self.props.labelField]}{" "}
          <span className="caret"></span>
        </button>
        <ul className="dropdown-menu">
          {this.props.dataProvider.map(function (item) {
            return (
              <li
                key={shortid.generate()}
                onClick={self._didSelect.bind(self, item)}
              >
                <a className="dropdown-item">{item[self.props.labelField]}</a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
DropDown.propTypes = {
  id: PropTypes.string,
  selectedItem: PropTypes.object,
  dataProvider: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  labelField: PropTypes.string,
  styles: PropTypes.array,
};
export default DropDown;
