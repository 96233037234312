import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App';


import {HashRouter,Route, Redirect} from 'react-router-dom';
import App from './components/App';


// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import './assets/styles/app.scss';


/*
const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
*/

const root = ReactDOM.createRoot(document.getElementById('root'));


const run = () => {
  root.render((
      <HashRouter>
        <Route path="/" component={App}>
          {/* <IndexRedirect to="/calc/geometrie" />*/}
          
          {/* make them children of `App`
          <Route path="/repos" component={Repos}/>
          <Route path="/about" component={About}/>
           */}
        </Route>
      </HashRouter>
  ));
};

if (window.addEventListener) {
  window.addEventListener('DOMContentLoaded', run);
} else {
  window.attachEvent('onload', run);
}

