import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <footer className="layout-footer">
        <div className="container">
          <div className="row">

            <div className="col-md-2"><a href="https://www.dw-systembau.de/content/kontakt">Kontakt</a></div>
            <div className="col-md-8"><a href="https://www.dw-systembau.de/content/impressum-disclaimer">Impressum</a></div>
            <div className="col-md-2 align-right">{new Date().getFullYear()} DW Systembau</div>
          </div>

        </div>
      </footer>
    );
  }
}

export default Footer;

