import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';


import ActionCreator from '../../actions/StatikActionCreator';
import BelastungStore from '../../stores/BelastungStore';
import GrundDatenStore from '../../stores/GrundDatenStore';
import GeometrieDatenStore from '../../stores/GeometrieDatenStore';
import CalcStore from '../../stores/CalcStore';
//import DropDown from 'components/common/DropDown';
import InputAndLabel from '../../components/common/InputAndLabel';
import Visualizer from '../../components/common/Visualizer';

const verfuegbareLasten = {
    einzellast:[
        { key:"EF", label:"Last auf Fuge" },
        { key:"F", label:"Last (bm beidseitig)" },
        { key:"FR", label:"Last am freien Rand (bm <= 1 m)" },
        { key:"R", label:"Last aus Wechsel (bm einseitig)" }
        ],
    linienlast:[
        { key:"F", label:"Last (bm beidseitig)" },
        { key:"FR", label:"Last am freien Rand (bm <= 1 m)" },
        { key:"L", label:"Last quer zur Spannrichtung" },
        { key:"R", label:"Last aus Wechsel (bm einseitig)" }
        ],
    blocklast:[
        { key:"F", label:"Last (bm beidseitig)" },
        { key:"L", label:"Last quer zur Spannrichtung" },
    ]

};

const lasteinheiten = {einzellast:"kN", linienlast:"kN/m"};

const ausgabelabels = {einzellast:"Punktlast", linienlast:"Linienlast"};

class ZusatzLast extends React.Component {
    constructor() {
        super();
        this._onChange = this._onChange.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._onSelectLastArt = this._onSelectLastArt.bind(this);
        this._handleOptionChange = this._handleOptionChange.bind(this);
        this._addLast = this._addLast.bind(this);
        this._onInfoChange = this._onInfoChange.bind(this);
        this._calcLastVerteilung = this._calcLastVerteilung.bind(this);
        this.state = {
            lastart:{
                auswahl:verfuegbareLasten.einzellast,
                selected:verfuegbareLasten.einzellast[0],
                lastoption:'einzellast',
                lastoptionausgabe:ausgabelabels.einzellast
            },
            groesse:{
                fqk:{label:"0,0",value:0.0,disabled:false},
                fgk:{label:"0,0",value:0.0,disabled:false}
            },
            lb:{
                tx:{label:"0,0",value:0.0,disabled:true},
                ty:{label:"0,0",value:0.0,disabled:false}
            },
            abstand:{
                xe:{label:"0,0",value:0.0,disabled:false},
                ye:{label:"0,0",value:0.0,disabled:false},
                qzf:{label:"0,0",value:0.0,disabled:false}
            },
            lastverteilung:{
              bma:{label:'1.0',value:1.0},
              bmm:{label:'1.0',value:1.0},
              bmb:{label:'1.0',value:1.0}
            },
            info:{text:""},
            id:'Last 1',
            selected:false,
            enabled:true
        };
    }

    _handleChange(item,path) {
        console.log("change",item,path);
       // let checkedval = parseFloat(item.match(/[+-]?\d+(\,\d+)?/g)[0].replace(",","."));
        let parsedval = item;//parseFloat(item.replace(",","."));
        let geo = GeometrieDatenStore.getAll().geometriewerte;
        let presets = BelastungStore.getAll().presets;
        let valobj = _.cloneDeep(this.state);
        if(path == 'lb.tx'){
            let maxval = Math.max(0,geo.stuetzweite-this.state.abstand.xe.value);
            parsedval = Math.min(maxval,parsedval);
        }
        if(path == 'abstand.xe'){
            let maxval = Math.max(0,geo.stuetzweite-this.state.lb.tx.value);
            parsedval = Math.min(maxval,parsedval);
        }


        console.log("pval",parsedval);

        _.set(valobj,path + '.value',parsedval);
        _.set(valobj,path + '.label',item);
        valobj = this._calcLastVerteilung(presets,valobj,geo.stuetzweite);
        this.setState(valobj);

    }

    _calcLastVerteilung(presets,stateobj,sw){
      return CalcStore.calcLastVerteilung(presets,stateobj,sw);
      /*
      var bma = 1.0;
      var bmm = 1.0;
      var bmb = 1.0;
      if(presets.lastverteilung == '1m'){
        stateobj.lastverteilung = {
          bma:{label:'1.0',value:1.0},
          bmm:{label:'1.0',value:1.0},
          bmb:{label:'1.0',value:1.0}
        };
      }else{
        if(stateobj.lastart.lastoption == 'einzellast'){
          bma = 0.15 + 0.5 * stateobj.abstand.xe.value;
          bmb = 0.15 + 0.5 * (sw - stateobj.abstand.xe.value);
          bmm = 0.1 + 2.5 * stateobj.abstand.xe.value * (1 - stateobj.abstand.xe.value/sw);
        }else{
          bma =  stateobj.abstand.xe.value + 0.5*stateobj.lb.tx.value;
          bmb =  sw - (stateobj.abstand.xe.value + 0.5*stateobj.lb.tx.value);
          let abst = stateobj.abstand.xe.value + 0.5 * stateobj.lb.tx.value;
          bmm = 0.1 + 2.5 * abst * (1 - abst/sw);
        }
        stateobj.lastverteilung = {
          bma:{label:bma.toFixed(2),value:bma},
          bmm:{label:bmm.toFixed(2),value:bmm},
          bmb:{label:bmb.toFixed(2),value:bmb}
        };
      }
      return stateobj;
      */
    }

    _onSelectLastArt(item,path) {
        let valobj = _.cloneDeep(this.state);
        _.set(valobj,path,item);
      let geo = GeometrieDatenStore.getAll().geometriewerte;
      let presets = BelastungStore.getAll().presets;

        this.setState(this._calcLastVerteilung(presets,valobj,geo.stuetzweite));
    }

    _handleOptionChange(changeEvent) {
        let valobj = _.cloneDeep(this.state);
        let val = changeEvent.target.value;
        _.set(valobj,'lastart.lastoption',val);
        _.set(valobj,'lastart.lastoptionausgabe',ausgabelabels[val]);
        _.set(valobj,'lastart.auswahl',verfuegbareLasten[val]);
        _.set(valobj,'lastart.selected',verfuegbareLasten[val][0]);
        if(val == 'einzellast'){
            valobj.lb.tx = {label:"0,0",value:0.0,disabled:true};
        }else{
            valobj.lb.tx.disabled = false;
        }
      let geo = GeometrieDatenStore.getAll().geometriewerte;
      let presets = BelastungStore.getAll().presets;

      this.setState(this._calcLastVerteilung(presets,valobj,geo.stuetzweite));
        //this.setState(valobj);
    }
    _onInfoChange(event) {
        let valobj = _.cloneDeep(this.state);
        _.set(valobj,'info.text',event.target.value);
        this.setState(valobj);
    }

    _onChange() {
    }
    _addLast() {
        let val = _.cloneDeep(this.state);
        if(this.props.location.state && this.props.location.state.editstate){
            ActionCreator.editZusatzLast(val);
        }else{
            ActionCreator.addZusatzLast(val);
        }
        this.props.history.goBack();
    }

    componentDidMount() {

        if(this.props.location.state && this.props.location.state.editstate){
            this.setState(this.props.location.state.editstate);
        }else{
          let geo = GeometrieDatenStore.getAll().geometriewerte;
          let presets = BelastungStore.getAll().presets;
          let valobj = _.cloneDeep(this.state);
          this.setState(this._calcLastVerteilung(presets,valobj,geo.stuetzweite));
        }
        BelastungStore.removeChangeListener(this._onChange);
        BelastungStore.addChangeListener(this._onChange);


    }

  



    render() {
        var self = this;
      var vsstate = _.cloneDeep(this.state);
      let mo = {G:vsstate.groesse.fgk.value, Q:vsstate.groesse.fqk.value, Anfang:vsstate.abstand.xe.value, Lange:vsstate.lb.tx.value};
      mo.rawG = mo.G;
      mo.rawQ = mo.Q;

      let showbmm = (mo.Anfang + mo.Lange < GrundDatenStore.getDicke())?false:true;

      var trs =  (this.props.location.state && this.props.location.state.editstate)?{lasten:[mo],input:CalcStore.getInput(),editLastID:this.state.id}:{lasten:[mo],input:CalcStore.getInput(),editLastID:"none"};
      let bmm = ()=> {
        if (showbmm) {
          return (<div className="row">
            <div className="col-md-4">
              <InputAndLabel label={"b<sub>mA</sub>"} defaultValue={0.0} disabled={true}
                             value={this.state.lastverteilung.bma.value} onChange={() => {
              }} einheit="m" id="tx"/>
            </div>
            <div className="col-md-4">
              <InputAndLabel label={"b<sub>mM</sub>"} defaultValue={0.0} disabled={true}
                             value={this.state.lastverteilung.bmm.value} onChange={() => {
              }} einheit="m" id="tx"/>
            </div>
            <div className="col-md-4">
              <InputAndLabel label={"b<sub>mB</sub>"} defaultValue={0.0} disabled={true}
                             value={this.state.lastverteilung.bmb.value} onChange={() => {
              }} einheit="m" id="tx"/>
            </div>
          </div>);
        }
        return '';
      };


      return (
            <div>
                <div className="row">
                    <h3>Zusatzlast erstellen</h3>
                    <hr/>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <h4>Lastart</h4>
                        <hr/>
                        <form className="mg-bottom-15">

                            <label className="radio-inline">
                                <input type="radio" name="lastOptions" id="einzellast" value="einzellast" onChange={this._handleOptionChange} checked={this.state.lastart.lastoption === 'einzellast'} /> Punktlast
                            </label>
                            <label className="radio-inline">
                                <input type="radio" name="lastOptions" id="linienlast" value="linienlast" onChange={this._handleOptionChange} checked={this.state.lastart.lastoption === 'linienlast'} /> Linienlast
                            </label>
                            {/*
                            <label className="radio-inline">
                                <input type="radio" name="lastOptions" id="blocklast" value="blocklast" onChange={this._handleOptionChange} checked={this.state.lastart.lastoption === 'blocklast'} /> Blocklast
                            </label>
                            */}
                        </form>
                        {/*<DropDown id="lastselect" onSelect={(itm)=> this._onSelectLastArt(itm,'lastart.selected')} labelField="label" selectedItem={this.state.lastart.selected} dataProvider={this.state.lastart.auswahl}/>*/}
                        <h4 className="mg-top-15">Größenanteil</h4>
                        <hr/>
                        <InputAndLabel label={"F<sub>Q,k</sub>"} defaultValue={5.0} disabled={this.state.groesse.fqk.disabled} value={this.state.groesse.fqk.value} onChange={(itm) => self._handleChange(itm,'groesse.fqk')} einheit={lasteinheiten[this.state.lastart.lastoption]} id="fqk"/>
                        <InputAndLabel label={"F<sub>G,k</sub>"} defaultValue={1.2} disabled={this.state.groesse.fgk.disabled} value={this.state.groesse.fgk.value} onChange={(itm) => self._handleChange(itm,'groesse.fgk')} einheit={lasteinheiten[this.state.lastart.lastoption]} id="fgk"/>
                       {/* <button type="button" className="btn btn-default btn-sm btn-block">Wechsellast ermitteln</button>*/}


                        <h4 className="mg-top-15">Länge / Breite</h4>
                        <hr/>
                        <InputAndLabel label="tx" defaultValue={0.0} disabled={this.state.lb.tx.disabled} value={this.state.lb.tx.value} onChange={(itm) => self._handleChange(itm,'lb.tx')} einheit="m" id="tx"/>
                        {/*<InputAndLabel label="ty" defaultValue={"0,0"} disabled={this.state.lb.ty.disabled} value={this.state.lb.ty.label} onChange={(itm) => self._handleChange(itm,'lb.ty')} einheit="m" id="ty"/>*/}

                        <h4 className="mg-top-15">Abstand</h4>
                        <hr/>
                        <InputAndLabel label="xe vom Auflager" defaultValue={0.0} disabled={this.state.abstand.xe.disabled} value={this.state.abstand.xe.value} onChange={(itm) => self._handleChange(itm,'abstand.xe')} einheit="m" id="axe"/>
                       {/* <InputAndLabel label="ye vom Längsrand" defaultValue={"0,0"} disabled={this.state.abstand.ye.disabled} value={this.state.abstand.ye.label} onChange={(itm) => self._handleChange(itm,'abstand.ye')} einheit="m" id="aye"/>
                        <InputAndLabel label="quer zur Fuge" defaultValue={"0,0"} disabled={this.state.abstand.qzf.disabled} value={this.state.abstand.qzf.label} onChange={(itm) => self._handleChange(itm,'abstand.qzf')} einheit="m" id="qzf"/>*/}

                    </div>
                    <div className="col-md-8 visualizer">
                        <Visualizer id="lastrender" renderState={trs} />
                        <h4 className="mg-top-15">Infotext</h4>
                        <hr/>
                      {bmm()}

                        <textarea className="form-control" value={this.state.info.text} onChange={this._onInfoChange} rows="3"></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <hr/>
                        <button type="button" className="btn btn-default btn-sm pull-left" onClick={()=> this.props.history.goBack()}>Abbrechen</button>
                        <button type="button" className="btn btn-default btn-sm pull-right" onClick={this._addLast}>Zusatzlast übernehmen</button>
                    </div>
                </div>
            </div>
        );
    }
}
ZusatzLast.propTypes = {
    router: PropTypes.shape({
        goBack: PropTypes.func
    }),
    location:PropTypes.object
};

export default withRouter(ZusatzLast);
