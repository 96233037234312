/**
 * Created by joerg on 12.06.17.
 */
import PropTypes from 'prop-types';

import React from 'react';

export default class LNContentEditable extends React.Component {

  constructor() {
    super();
    this.emitChange = this.emitChange.bind(this);
  }

  render() {
    const {inhtml,...rest} = this.props;
    return React.createElement(
      this.props.tagName || 'div',

      Object.assign({}, rest, {
        ref: (e) => this.htmlEl = e,
        //onInput: this.emitChange,
        onBlur: this.emitChange,
        contentEditable: !this.props.disabled,
        dangerouslySetInnerHTML: {__html: inhtml}
      }),
      this.props.children);
  }

  shouldComponentUpdate(nextProps) {
    return !this.htmlEl || nextProps.inhtml !== this.htmlEl.innerHTML ||
      this.props.disabled !== nextProps.disabled;
  }

  componentDidUpdate() {
    if ( this.htmlEl && this.props.inhtml !== this.htmlEl.innerHTML ) {
      this.htmlEl.innerHTML = this.props.inhtml;
    }
  }

  emitChange(evt) {
    if (!this.htmlEl) return;
    var html = this.htmlEl.innerHTML;
    if (this.props.onChange && html !== this.lastHtml) {
      evt.target = { value: html };
      this.props.onChange(evt);
    }
    this.lastHtml = html;
  }
}
LNContentEditable.propTypes = {
  inhtml: PropTypes.string,
  tagName: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func

};
