import React from 'react';
import ActionCreator from '../../actions/StatikActionCreator';
import LNFileSaver from '../../utils/LNFileSaver';


class IO extends React.Component
{
    constructor()
    {
        super();
        this._onChange = this._onChange.bind(this);
        this._handleSave = this._handleSave.bind(this);
        this._handleLoad = this._handleLoad.bind(this);
      this._handleFileSelect = this._handleFileSelect.bind(this);
        this.state = {fname:"",file:null};
    }
    _handleSave()
    {
      LNFileSaver.getAll();
      ActionCreator.saveData();
    }
    _handleLoad()
    {
      if (typeof window.FileReader !== 'function') {
        alert("Ihr Browser unterstützt das Laden von Daten nicht");
      }

      let file = this.state.file;
      let fr = new FileReader();
      fr.onload = (e)=>{
        ActionCreator.loadData(e.target.result);
      };
      fr.onerror = (e)=>{
        alert('Fehler beim Lesen der Datei\n'+e.toString());
      };
      fr.readAsText(file);

    }


   _handleFileSelect(evt) {
      var files = evt.target.files; // FileList object
      var f = files[0];
      this.setState({fname:f.name,file:f});

}

    _onChange()
    {
    }
  
    render()
    {
        return (
          <div>
            <div className="row mg-bottom-15">
              <h3>Speichern und Laden</h3>
              <hr/>
              <div className="row">
                <div className="col-md-12">
                  <h4>Speichern:</h4>
                  <button type="button" className="btn btn-default " onClick={()=> this._handleSave()}>In Datei speichern</button>
                </div>

              </div>
              <div className="row">
                <div className="col-md-12">
                  <hr/>
                  <h4>Laden:</h4>
                  <form>
                  <div className="form-group show-border">
                <span className="btn btn-default btn-file width-28">
                  Datei wählen<input className="" name="fileUpload" id="file-select" accept=".dwstatik" onChange={this._handleFileSelect} type="file"></input>
                </span><input className="form-control width-70-inline" value={this.state.fname} name="file-selected" id="file-selected" readOnly={true} placeholder="Keine Datei gewählt" type="text"></input>
                  </div>
                    <div className="form-group mg-top-15">
                      <button type="button" className="btn btn-default" onClick={()=> this._handleLoad()}>Öffnen</button>
                    </div>
                  </form>
            </div>
              </div>
            </div>
          </div>
        );
    }
}

export default IO;
