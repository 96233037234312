import PropTypes from 'prop-types';
import React from 'react';
import CalcStore from '../../stores/CalcStore';
import { withRouter } from 'react-router-dom';
import ActionCreator from '../../actions/StatikActionCreator';

class Ausgabe extends React.Component {
  /*
  *  "VRdct1": 67.20700078777556,
   "VEdct1": 12.721455,
   "VRdct1A2": 67.20700078777556,
   "VEdct1A2": 12.721455,
   "VRdct2": 51.72,
   "VEdct2": 0,
   "MEd": 16.0608369375,
   "MRd": 94.81,
   "MEdF90": 11.896916249999999,
   "MRdF90": 59.03,
   "VEdF90": 9.4233,
   "MRdSLS": 66.5,
   "MEdSLS": 11.896916249999999,
   "Durchbiegung_E": -0.0015037992747831727,
   "Durchhang_E": -0.002443673821522656,
   "Durchbiegung_R": 0.0101,
   "Durchhang_R": 0.0202,
   "Result": false,
   "Error": "",
   "durchBformat": "L/-3358.16",
   "durchHformat": "L/-2066.56",
   "durchBothformat": "DB: L/-3358.16 / DH: L/-2066.56",
   "mrd": 0,
   "vrda": 0,
   "vrdb": 0,
   "Deckenart": "Standarddecke",
   "Aktuell": 1,
   "Plattentype": "A20B",
   "Feuerwiderstand": "F90",
   "Feldmoment": 77.5,
   "Querkraft": 65.26,
   "Kragmoment": 0,
   "azUnten": 347,
   "azOben": 65,
   "Bemerkung": "Zulassung: Z-15.10-279 - Querschnitt: A20B",
   "P-Durchstanzen": 20,
   "ID": 105,
   "PROD_TYPE_CODE": 1142,
   "order": 2,
   "Spannung": 100,
   "Display": 1,
   "MindQuerkraft": 60.21,
   "Bewehrung": "A20B/S8-D4",
   "h": 200,
   "SelfWeight": 3.11,
   "Lptd": 893,
   "Sigma_cp": 2.55,
   "Tau_cpd": 0.1039,
   "fctd": 1.51,
   "FIbw_S": 43.7,
   "V_Rdc_62a": 51.72,
   "MRXC1": 66.5,
   "MRXC3": 34.41,
   "MRXC3OPH": 61.51,
   "MRD": 94.81,
   "MRDF90": 59.03,
   "VRDF90": 31.03,
   "W": 7.13,
   "Mpt2": -16.22,
   "EI": 21388,
   "PHI": 1.6,
   "MaxLange": 9,
   "MaxVL": 10,
   "key": 19,
   "dicke": 20
  * */
    constructor() {
        super();
        this._onChange = this._onChange.bind(this);
        this._handleChange = this._handleChange.bind(this);
      this._trClass = this._trClass.bind(this);
        this.state = {currbew:CalcStore.getSelectedBewehrung()[0]};
      this.headers  = [{label:"",func:function(r,itm){

        if(r.hasOwnProperty('labelbwA') && itm.isbiegeweichA ){
          return r.labelbwA;
        }
        if(r.hasOwnProperty('labelbwB') && itm.isbiegeweichB ){
          return r.labelbwB;
        }
        return r.label;
      }},
        {label:"Berechnet",func(r,itm){return (itm[r.ber]/r.faktor).toFixed(2) + ' '+ r.einheit;}},
        {label:"Zulässig",func(r,itm){

          let zval = itm[r.zul];
          if(r.hasOwnProperty('zulbwA') && itm.isbiegeweichA ){
            zval = itm[r.zulbwA];
          }
          if(r.hasOwnProperty('zulbwB') && itm.isbiegeweichB ){
            zval = itm[r.zulbwB];
          }
          return (zval/r.faktor).toFixed(2) + ' '+ r.einheit;
        }},
        {label:"Ausnutzung",func(r,itm){
          let zval = itm[r.zul];
          if(r.hasOwnProperty('zulbwA') && itm.isbiegeweichA ){
            zval = itm[r.zulbwA];
          }
          if(r.hasOwnProperty('zulbwB') && itm.isbiegeweichB ){
            zval = itm[r.zulbwB];
          }
          let tausnutzung = (((itm[r.ber]/r.faktor) / (zval/r.faktor))*100);
          return tausnutzung.toFixed(1)+"%" ;
        },colorfunc(r,itm){
          return '';
        }
        },
        ];
      //{label:"Durchhang", einheit:"mm", zul:"durchhangRmm", ber:"durchhangEmm",faktor:1.0},
      this.rows = [
        {label:"Durchbiegung", einheit:"mm", zul:"durchbiegungRmm", ber:"durchbiegungEmm",faktor:1.0},
        {label:"Max. Plattenlänge", einheit:"m", zul:"MaxLange", ber:"Lichteweite",faktor:1.0},
        {label:"M<sub>Rd</sub>", einheit:"kNm/m", zul:"MRd", ber:"MEd",faktor:1.0},
        {label:"M<sub>Rd,F90</sub>", einheit:"kNm/m", zul:"MRdF90", ber:"MEdF90",faktor:1.0},
        {label:"M<sub>Rd,SLS</sub>", einheit:"kNm/m", zul:"MRdSLS", ber:"MEdSLS",faktor:1.0},
        {label:"V<sub>Rd,ct1,A</sub>", labelbwA:"V<sub>Rd,ct1,bw,A</sub>",einheit:"kN/m", zul:"VRdct1",zulbwA:"VRdct1_bwA", ber:"VEdct1",faktor:1.0},
        {label:"V<sub>Rd,ct1,B</sub>", labelbwB:"V<sub>Rd,ct1,bw,B</sub>", einheit:"kN/m", zul:"VRdct1A2", zulbwB:"VRdct1_bwB", ber:"VEdct1A2",faktor:1.0},
        {label:"V<sub>Rd,ct2,A</sub>", einheit:"kN/m", zul:"VRdct2", ber:"VEdct2A",faktor:1.0},
          {label:"V<sub>Rd,ct2,B</sub>", einheit:"kN/m", zul:"VRdct2", ber:"VEdct2B",faktor:1.0},
        {label:"V<sub>Rd,F90,A</sub>", einheit:"kN/m", zul:"VRDF90", ber:"VEdfiA",faktor:1.0},
        {label:"V<sub>Rd,F90,B</sub>", einheit:"kN/m", zul:"VRDF90", ber:"VEdfiB",faktor:1.0}
      ];
    }

    _handleChange() {
    }

    _onChange() {
      let bew = CalcStore.getSelectedBewehrung();
      this.setState({currbew:bew[0]});
    }

    componentDidMount() {
      CalcStore.addChangeListener(this._onChange);
    }

  componentWillUnmount() {
      CalcStore.removeChangeListener(this._onChange);
    }
  _trClass(ritem,bew){
    let hitem = this.headers[this.headers.length-1];
    let ausval = parseFloat(hitem.func(ritem,bew)) ;
    return (ausval > 100.0)?((ausval < 103.0)?'warning':'danger'):'success' ;
  }
  _handleClick() {

   // this.props.onSelect(rowitem);
  }
  _save(){
    ActionCreator.createPDF({includeScheibe:false});
  }
  _print(){
    ActionCreator.createPDFAndPrint({includeScheibe:false});
  }


    render() {
      let bew = this.state.currbew;
      let rs = (bew == undefined)?[]:this.rows;
      let self = this;
      let isprintable = (bew != undefined && bew.Error == '');
      var mkey = 0;
      self.headers[0].label = (bew != undefined)?bew.Bewehrung:'';
        return (
          <div>
            
              <h3>Berechnungen</h3>

            
              <hr/>
            <div className="row">

                <table className="table table-bordered">
                  <thead>
                  <tr>
                    {self.headers.map(function (item) {
                      mkey ++;
                      return <th key={mkey} className={mkey==1?'info':''} dangerouslySetInnerHTML={{__html: item.label}}></th>;
                    })}

                  </tr>
                  </thead>
                  <tbody>
                  {
                    rs.map(function (ritem) {
                      mkey ++;
                      //(item.selected)?'info':''
                      return (
                        <tr className={self._trClass(ritem,bew)} key={mkey} >{
                          self.headers.map(function (hitem) {
                            mkey ++;
                            return <td key={mkey} className={hitem.colorfunc?hitem.colorfunc(ritem,bew):''} dangerouslySetInnerHTML={{__html: hitem.func(ritem,bew)}}></td>;
                          })

                        }

                        </tr>
                      );
                    })

                  }
                  </tbody>
                </table>

            </div>
            <div className="row">
              <div className="col-md-12">
                <hr/>
                <button type="button" className="btn btn-default btn-sm pull-left" onClick={()=> this.props.history.goBack()}>Schliessen</button>
                <button type="button" className="btn btn-default btn-sm pull-right" disabled={!isprintable} onClick={this._save}>Statik als PDF speichern</button>
                <button type="button" className="btn btn-default btn-sm pull-right" disabled={!isprintable} onClick={this._print}>Statik anzeigen und drucken</button>
              </div>

            </div>
          </div>
        );
    }
}
Ausgabe.propTypes = {
  router: PropTypes.shape({
    goBack: PropTypes.func
  }),
  trClass:PropTypes.string
};
export default withRouter(Ausgabe);
