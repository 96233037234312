/**
 * Created by joerg on 28.07.16.
 */
import Dispatcher from "../dispatcher/Dispatcher";
import Constants from "../constants/constants";
import BaseStore from "./BaseStore";
import assign from "object-assign";
import ActionCreator from "../actions/StatikActionCreator";
import canvg from "canvg-browser/index";
import _ from "lodash";
import $ from "jquery";
import shortid from "shortid";

var grundWerte = {};
var expo = {
  auswahl: [
    { key: "XC1", label: "XC1" },
    { key: "XC3", label: "XC3" },
    { key: "XC3 XF1", label: "XC3 XF1 Offenes Parkhaus" }
  ],
  selected: { key: "XC1", label: "XC1" }
};
var deckenDicke = { auswahl: [], selected: { key: "", label: "" } };
var deckenWerte = {
  decken: { auswahl: [], selected: { key: "null", label: "null" } },
  deckentyp: { auswahl: [], selected: { key: "null", label: "null" } },
  expoklasse: expo,
  deckendicke: deckenDicke
};
var eingaben = {
  bauvorhaben: "Vorbemessung",
  bauposition: "BRESPA®-Decke",
  baustatikseite: "0"
};
var skizzen = { auswahl: {}, selected: "", datauri: "" };
var texte = { subheader: "" };
var scheiben = {};

function createDickenListe() {
  //grundWerte.json.dataRoot.Grundwerte.map(function(item) {
  // item.Plattentyp.match(/\d+/);
  // });
  var numDecken = 0;

  deckenWerte.decken.auswahl = deckenWerte.decken.json.concat();
  deckenWerte.decken.auswahl = deckenWerte.decken.auswahl.map(function (item) {
    numDecken++;
    return assign(item, {
      key: shortid.generate(),
      dicke: item.Plattentype.match(/\d+/)[0]
    });
  });
  deckenWerte.decken.auswahl = _.sortBy(
    deckenWerte.decken.auswahl,
    "Sortierung"
  ); //'Plattentype'
  deckenWerte.decken.selected = deckenWerte.decken.auswahl[0];

  deckenWerte.deckentyp.auswahl =
    deckenWerte.deckentyp.json.dataroot.Deckenarten.concat();
  deckenWerte.deckentyp.auswahl = deckenWerte.deckentyp.auswahl.map(function (
    item
  ) {
    return assign(item, { key: item.Deckenart });
  });
  deckenWerte.deckentyp.selected = deckenWerte.deckentyp.auswahl[0];

  deckenWerte.deckendicke.auswahl = _.uniqBy(
    deckenWerte.decken.auswahl.map(function (item) {
      return { key: item.dicke.toString(), label: item.dicke.toString() };
    }),
    "key"
  );
  deckenWerte.deckendicke.auswahl = _.sortBy(
    _.cloneDeep(deckenWerte.deckendicke.auswahl),
    "key"
  );
  deckenWerte.deckendicke.selected = deckenWerte.deckendicke.auswahl[0];
  console.log("deckenwerte ", deckenWerte);
}

function setDefaultDecke() {
  let f = _.filter(deckenWerte.decken.auswahl, {
    Deckenart: deckenWerte.deckentyp.selected.Deckenart
  });
  //if ( _.find(f,{'Plattentype':deckenWerte.decken.selected.Plattentype}) == undefined) {
  //	}
  deckenWerte.deckendicke.auswahl = _.uniqBy(
    f.map(function (item) {
      return { key: item.dicke.toString(), label: item.dicke.toString() };
    }),
    "key"
  );
  deckenWerte.deckendicke.auswahl = _.sortBy(
    deckenWerte.deckendicke.auswahl,
    "key"
  );
  let df = _.find(f, { dicke: deckenWerte.deckendicke.selected.key });
  if (df == undefined) {
    deckenWerte.deckendicke.selected = deckenWerte.deckendicke.auswahl[0];
  }
  deckenWerte.decken.selected = _.find(f, {
    dicke: deckenWerte.deckendicke.selected.key
  });
}

function findSkizze() {
  skizzen.selected = skizzen.auswahl[deckenWerte.decken.selected.Plattentype];

  getDataUriFromSVG(
    "assets/images/skizzen/" + skizzen.selected,
    function (datauri) {
      skizzen.datauri = datauri;
    }
  );
}
/*
function getDataUri(url, callback) {
  var image = new Image();

  image.onload = function () {
    var canvas = document.createElement('canvas');
    canvas.width = this.naturalWidth; // or 'width' if you want a special/scaled size
    canvas.height = this.naturalHeight; // or 'height' if you want a special/scaled size

    canvas.getContext('2d').drawImage(this, 0, 0);

    // Get raw image data
    //callback(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''));

    // ... or get as Data URI
    callback(canvas.toDataURL('image/png'));
  };

  image.src = url;
}
*/

function getDataUriFromSVG(url, callback) {
  $.ajax({
    type: "GET",
    url: url, //"./bin/data/grundwerte.xml",
    dataType: "xml"
  }).done(function (xml) {
    var canvas = document.createElement("canvas");
    canvas.width = 500;
    canvas.height = 200;
    canvg(canvas, xml, { offsetX: 10, ignoreDimensions: true });
    callback(canvas.toDataURL("image/png"));
  });
  // get svg data
  //var xml = new XMLSerializer().serializeToString(svg);
  //canvg(canvas, xml,{offsetX:10,ignoreDimensions:true});
  //var ctx = canvas.getContext('2d');
  //ctx.drawSvg(url, 0, 0, 500, 200);

  //return;
}

function getDataForSaving() {
  var dw = _.cloneDeep(deckenWerte);
  dw.decken = _.omit(dw.decken, ["auswahl", "json", "xml"]);
  dw.deckentyp = _.omit(dw.deckentyp, ["auswahl", "json", "xml"]);
  dw.expoklasse = _.omit(dw.expoklasse, "auswahl");
  dw.deckendicke = _.omit(dw.deckendicke, "auswahl");

  let savedata = {
    deckenWerte: _.cloneDeep(dw),
    eingaben: _.cloneDeep(eingaben)
  };
  return savedata;
}

function applySavedData(sdata) {
  _.merge(deckenWerte, sdata.deckenWerte);
  _.merge(eingaben, sdata.eingaben);
}

const GrundDatenStore = assign({}, BaseStore, {
  // public methods used by Controller-View to operate on data

  getEingaben() {
    return eingaben;
  },
  getSkizzen() {
    return skizzen;
  },
  getSelectedDecke() {
    return deckenWerte.decken.selected;
  },
  getScheibenData() {
    return scheiben;
  },
  getAll() {
    return {
      deckenWerte: deckenWerte,
      eingaben: eingaben,
      skizzen: skizzen,
      texte: texte
    };
  },
  getDicke() {
    var _dicke = 0;
    try {
      _dicke = parseFloat(deckenWerte.deckendicke.selected.key);
    } catch (e) {
      console.error("deckendicke not defined", e);
      _dicke = 0;
    }
    return _dicke / 100.0;
  },
  getSavedata() {
    return getDataForSaving();
  },
  setSavedData(sd) {
    applySavedData(sd);
    findSkizze();
    GrundDatenStore.emitChange();
  },

  // register store with dispatcher, allowing actions to flow through
  dispatcherIndex: Dispatcher.register(function handleAction(payload) {
    const action = payload.action;

    switch (action.type) {
      case Constants.ActionTypes.DATA_LOADED:
        console.log(action);
        assign(deckenWerte.decken, action.wdata);
        assign(deckenWerte.deckentyp, action.artdata);
        assign(grundWerte, action.gwdata);
        assign(skizzen.auswahl, action.skizzen.json);
        assign(texte, action.texte.json);
        assign(scheiben, action.scheiben.json);
        createDickenListe();
        setDefaultDecke();
        findSkizze();
        GrundDatenStore.emitChange();
        let ausw = deckenWerte.decken.selected;
        setTimeout(function () {
          ActionCreator.selectDecke(ausw);
          let child = document.getElementById("loader");
          child.parentNode.removeChild(child);
        }, 100);
        break;
      case Constants.ActionTypes.DECKE_SELECTED:
        console.log(action.decke);
        deckenWerte.decken.selected = action.decke;
        findSkizze();
        GrundDatenStore.emitChange();
        break;
      case Constants.ActionTypes.DECKENTYP_SELECTED:
        console.log(action.deckentyp);
        deckenWerte.deckentyp.selected = action.deckentyp;
        setDefaultDecke();
        findSkizze();
        GrundDatenStore.emitChange();
        break;
      case Constants.ActionTypes.DECKENDICKE_SELECTED:
        console.log(action.dicke);
        deckenWerte.deckendicke.selected = action.dicke;
        setDefaultDecke();
        findSkizze();
        GrundDatenStore.emitChange();
        break;
      case Constants.ActionTypes.EXPO_SELECTED:
        console.log(action.expo);
        deckenWerte.expoklasse.selected = action.expo;
        GrundDatenStore.emitChange();
        break;
      case Constants.ActionTypes.GRUNDDATEN_EINGABE:
        console.log(action.eingaben);
        assign(eingaben, action.eingaben);
        GrundDatenStore.emitChange();
        break;
      case Constants.ActionTypes.FILE_LOADED:
        applySavedData(action.data.GrundDatenStore);
        findSkizze();
        GrundDatenStore.emitChange();

      // add more cases for other actionTypes...

      // no default
    }
  })
});

export default GrundDatenStore;
