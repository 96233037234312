import React from "react";
import InputAndLabel from "../../components/common/InputAndLabel";
import DropDown from "../../components/common/DropDown";
import LNCheckBox from "../../components/common/LNCheckBox";
import GeometrieStore from "../../stores/GeometrieDatenStore";
import CalcStore from "../../stores/CalcStore";
import GrundDatenStore from "../../stores/GrundDatenStore";
import _ from "lodash";
import ActionCreator from "../../actions/StatikActionCreator";
import $ from "jquery";
//import LNImage from 'components/common/LNImage';
class Geometrie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      geo: GeometrieStore.getAll().geometriewerte,
      bewehrung: CalcStore.getBewehrungen(),
      skizzen: GrundDatenStore.getSkizzen()
    };
  }

  _handleChange(item, path) {
    console.log("_geometrie changed : ", item, path);
    let valobj = _.cloneDeep(GeometrieStore.getAll().geometriewerte);
    _.set(valobj, path, item);
    ActionCreator.setGeometrie(valobj);
  }

  _onChange() {
    console.log("on changed: ");
    this.setState({
      geo: GeometrieStore.getAll().geometriewerte,
      bewehrung: CalcStore.getBewehrungen(),
      skizzen: GrundDatenStore.getSkizzen()
    });
  }

  _onSelectAuflager(item, path) {
    console.log("changed: ", item);
    let valobj = _.cloneDeep(GeometrieStore.getAll().geometriewerte);
    _.set(valobj, path, item);
    ActionCreator.setGeometrie(valobj, path);
  }

  componentDidMount() {
    CalcStore.addChangeListener(this._onChange.bind(this));
    if (
      navigator.appName == "Microsoft Internet Explorer" ||
      !!(
        navigator.userAgent.match(/Trident/) ||
        navigator.userAgent.match(/rv 11/)
      ) ||
      navigator.userAgent.match(/msie/)
    ) {
      alert(
        "Ihr Browser ist veraltet, und wird von dieser Anwendung nicht voll unterstützt.\nWir empfehlen eine aktuelle Version von Chrome oder Firefox zu verwenden."
      );
    }
    //  setTimeout(() => { $('[data-toggle="tooltip"]').tooltip();}, 0);
  }

  componentDidUpdate() {
    // setTimeout(() => { $('[data-toggle="tooltip"]').tooltip();}, 0);
  }

  componentWillUnmount() {
    CalcStore.removeChangeListener(this._onChange.bind(this));
  }

  findPeikkoSkizze(alSelector) {
    let alselect = alSelector === "auflagerA" ? "AlAselected" : "AlBselected";
    if (
      this.state.geo.auflager[alselect].key !== "peikko" &&
      this.state.geo.auflager[alselect].key !== "pfeifer" &&
      this.state.geo.auflager[alselect].key !== "tecnostrutture"
    ) {
      return "";
    }
    var pselected =
      this.state.geo.biegeweich[alSelector].alTypes.peikko.selected;
    var imgsrc = "assets/images/peikko/";
    if (this.state.geo.auflager[alselect].key === "pfeifer") {
      pselected =
        this.state.geo.biegeweich[alSelector].alTypes.pfeifer.selected;
      imgsrc = "assets/images/pfeifer/";
    }
    if (this.state.geo.auflager[alselect].key === "tecnostrutture") {
      pselected =
        this.state.geo.biegeweich[alSelector].alTypes.tecnostrutture.selected;
      imgsrc = "assets/images/tecnostrutture/";
    }
    var tpos = this.state.geo.biegeweich[alSelector].traegerpos;
    var dicke = GrundDatenStore.getDicke() * 1000;
    if (tpos === "rand") {
      if (this.state.geo.auflager[alselect].key === "pfeifer") {
        if (dicke >= 400 || dicke > pselected.h) {
          imgsrc += "bh4_r.svg";
        } else if (dicke === pselected.h) {
          imgsrc += "pgb_r.svg";
        } else if (dicke < pselected.h) {
          imgsrc += "pkb_r.svg";
        }
      } else if (this.state.geo.auflager[alselect].key === "tecnostrutture") {
        if (dicke < pselected.h) {
          imgsrc += "nps_rand_uz.svg";
        } else {
          imgsrc += "nps_rand_sf.svg";
        }
      } else {
        if (pselected.name.indexOf("DR") === 0) {
          if (dicke === pselected.h) {
            imgsrc += "case_5.svg";
          } else if (dicke < pselected.h) {
            imgsrc += "case_6.svg";
          }
        } else {
          if (dicke < pselected.h) {
            imgsrc += "case_1.svg";
          } else if (dicke === pselected.h) {
            imgsrc += "case_2.svg";
          }
        }
      }
    } else {
      if (this.state.geo.auflager[alselect].key === "pfeifer") {
        if (dicke >= 400 || dicke > pselected.h) {
          imgsrc += "bh4_m.svg";
        } else if (dicke === pselected.h) {
          imgsrc += "pgb_m.svg";
        } else if (dicke < pselected.h) {
          imgsrc += "pkb_m.svg";
        }
      } else if (this.state.geo.auflager[alselect].key === "tecnostrutture") {
        if (dicke < pselected.h) {
          imgsrc += "nps_mitte_uz.svg";
        } else {
          imgsrc += "nps_mitte_sf.svg";
        }
      } else {
        if (pselected.name.indexOf("D40") == 1) {
          imgsrc += "case_1.svg";
        } else if (dicke < pselected.h) {
          imgsrc += "case_4.svg";
        } else if (dicke === pselected.h) {
          imgsrc += "case_3.svg";
        }
      }
    }
    if (imgsrc.indexOf(".svg") > -1) {
      return <img src={imgsrc} alt="" className={"img-responsive"} />;
    } else {
      return "";
    }
  }

  render() {
    let self = this;
    let bews = self.state.bewehrung.data;
    var validbew = bews.filter(function (bew) {
      return bew.Error === "";
    });
    if (validbew.length > 0) {
      validbew = validbew[0];
    } else if (bews.length > 0) {
      validbew = bews[bews.length - 1];
    } else {
      validbew = { requiredEI_a: -1, requiredEI_b: -1 };
    }
    let reqeis = {
      auflagerA: validbew.requiredEI_a,
      auflagerB: validbew.requiredEI_b
    };

    let tragerbasics = function (alSelector, forced) {
      let alselect = alSelector == "auflagerA" ? "AlAselected" : "AlBselected";

      if (forced) {
        return (
          <div>
            <InputAndLabel
              label="E*I erforderlich"
              defaultValue={0}
              value={reqeis[alSelector]}
              einheit="MNm²"
              toolTip="Erforderliche Biegesteifheit des Trägers aufgerundet in 10 MNm²-Schritten."
              disabled={true}
              precision={1}
            />
            <InputAndLabel
              label="E * I"
              defaultValue={50}
              value={self.state.geo.biegeweich[alSelector].ei}
              onChange={(itm) =>
                self._handleChange(itm, "biegeweich." + alSelector + ".ei")
              }
              einheit="MNm²"
              toolTip="Biegesteifigkeit des Trägers"
              precision={1}
              disabled={true}
            />
            <InputAndLabel
              label="B<sub>uz</sub>"
              defaultValue={50}
              value={self.state.geo.biegeweich[alSelector].b}
              toolTip="Breite des Trägers zur Berechnung von Iy"
              onChange={(itm) =>
                self._handleChange(itm, "biegeweich." + alSelector + ".b")
              }
              einheit="m"
            />
            <InputAndLabel
              label="H<sub>uz</sub>"
              defaultValue={50}
              value={self.state.geo.biegeweich[alSelector].h}
              onChange={(itm) =>
                self._handleChange(itm, "biegeweich." + alSelector + ".h")
              }
              einheit="m"
              toolTip="Höhe des Trägers zur Berechnung von Iy"
            />
            <InputAndLabel
              label="I<sub>y,berechnet</sub>"
              defaultValue={50}
              value={self.state.geo.biegeweich[alSelector].ib}
              onChange={(itm) =>
                self._handleChange(itm, "biegeweich." + alSelector + ".ib")
              }
              einheit="cm<sup>4</sup>"
              toolTip="Iy des Trägers durch Programm berechnet"
              precision={0}
              disabled={true}
            />
            <InputAndLabel
              label="I<sub>y,manuell</sub>"
              defaultValue={50}
              value={self.state.geo.biegeweich[alSelector].im}
              onChange={(itm) =>
                self._handleChange(
                  itm == null ? 0.0 : itm,
                  "biegeweich." + alSelector + ".im"
                )
              }
              einheit="cm<sup>4</sup>"
              toolTip="Iy des Trägers manuelle Eingabe"
              precision={0}
            />
          </div>
        );
      }
      if (self.state.geo.auflager[alselect].key == "stahltraeger") {
        return (
          <div>
            <InputAndLabel
              label="E*I erforderlich"
              defaultValue={0}
              value={reqeis[alSelector]}
              einheit="MNm²"
              toolTip="Erforderliche Biegesteifheit des Trägers aufgerundet in 10 MNm²-Schritten."
              disabled={true}
              precision={1}
            />
            <InputAndLabel
              label="E * I"
              defaultValue={50}
              value={self.state.geo.biegeweich[alSelector].ei}
              onChange={(itm) =>
                self._handleChange(itm, "biegeweich." + alSelector + ".ei")
              }
              einheit="MNm²"
              toolTip="Biegesteifigkeit des Trägers"
              precision={1}
              disabled={true}
            />
            <InputAndLabel
              label="I<sub>y,berechnet</sub>"
              defaultValue={50}
              value={self.state.geo.biegeweich[alSelector].ib}
              onChange={(itm) =>
                self._handleChange(itm, "biegeweich." + alSelector + ".ib")
              }
              einheit="cm<sup>4</sup>"
              toolTip="Iy des Trägers durch Programm berechnet"
              precision={0}
              disabled={true}
            />
            <InputAndLabel
              label="I<sub>y,manuell</sub>"
              defaultValue={50}
              value={self.state.geo.biegeweich[alSelector].im}
              onChange={(itm) =>
                self._handleChange(
                  itm == null ? 0.0 : itm,
                  "biegeweich." + alSelector + ".im"
                )
              }
              einheit="cm<sup>4</sup>"
              toolTip="Iy des Trägers manuelle Eingabe"
              precision={0}
            />
          </div>
        );
      }

      if (
        self.state.geo.auflager[alselect].key == "peikko" ||
        self.state.geo.auflager[alselect].key == "pfeifer" ||
        self.state.geo.auflager[alselect].key == "tecnostrutture"
      ) {
        return (
          <div>
            <InputAndLabel
              label="E*I erforderlich"
              defaultValue={0}
              value={reqeis[alSelector]}
              einheit="MNm²"
              toolTip="Erforderliche Biegesteifheit des Trägers aufgerundet in 10 MNm²-Schritten."
              disabled={true}
              precision={1}
            />
            <InputAndLabel
              label="E * I"
              defaultValue={50}
              value={self.state.geo.biegeweich[alSelector].ei}
              onChange={(itm) =>
                self._handleChange(itm, "biegeweich." + alSelector + ".ei")
              }
              einheit="MNm²"
              toolTip="Biegesteifigkeit des Trägers"
              precision={1}
              disabled={true}
            />
          </div>
        );
      }

      return "";
    };

    let fuelltiefe = function (alSelector, forced) {
      let alselect = alSelector == "auflagerA" ? "AlAselected" : "AlBselected";

      if (
        forced ||
        self.state.geo.auflager[alselect].key == "stahltraeger" ||
        self.state.geo.auflager[alselect].key == "peikko" ||
        self.state.geo.auflager[alselect].key == "pfeifer" ||
        self.state.geo.auflager[alselect].key == "tecnostrutture"
      ) {
        return (
          <div>
            <div className="input-group">
              <div className="input-group-text ">Fülltiefe</div>

              <DropDown
                onSelect={(itm) =>
                  self._handleChange(
                    itm,
                    "biegeweich." + alSelector + ".fuelltiefe.selected"
                  )
                }
                labelField="label"
                selectedItem={
                  self.state.geo.biegeweich[alSelector].fuelltiefe.selected
                }
                dataProvider={
                  self.state.geo.biegeweich[alSelector].fuelltiefe.auswahl
                }
              />
            </div>

            {/*<InputAndLabel label="Fülltiefe" defaultValue={50}
                             value={self.state.geo.biegeweich[alSelector].fuelltiefe} toolTip="Fülltiefe der Hohlkammer"
                             onChange={(itm) => self._handleChange(itm, 'biegeweich.' + alSelector + '.fuelltiefe')}
                             einheit="mm" precision={0}/>*/}
            <h6>Abmessungen Unterzug/Träger</h6>
            {/*renderTragerposChange(alSelector)*/}
          </div>
        );
      }
      return "";
    };

    let betonAL = function (alSelector) {
      return (
        <div>
          {fuelltiefe(alSelector)}

          {tragerbasics(alSelector)}
          {/*<InputAndLabel label="B<sub>uz</sub>" defaultValue={50} value={self.state.geo.biegeweich[alSelector].b} toolTip="Breite des Trägers zur Berechnung von Iy"
                           onChange={(itm) => self._handleChange(itm, 'biegeweich.' + alSelector + '.b')} einheit="m"/>
            <InputAndLabel label="H<sub>uz</sub>" defaultValue={50} value={self.state.geo.biegeweich[alSelector].h}
                           onChange={(itm) => self._handleChange(itm, 'biegeweich.' + alSelector + '.h')} einheit="m" toolTip="Höhe des Trägers zur Berechnung von Iy"/>*/}
        </div>
      );
    };
    let betonAlA =
      self.state.geo.auflager.AlAselected.key != "stahltraeger" &&
      self.state.geo.auflager.AlAselected.key != "peikko" &&
      self.state.geo.auflager.AlAselected.key != "pfeifer" &&
      self.state.geo.auflager.AlAselected.key != "tecnostrutture" &&
      self.state.geo.auflager.AlAselected.key != "mauer" &&
      self.state.geo.auflager.AlAselected.key != "_50prozent"
        ? betonAL("auflagerA")
        : "";
    let betonAlB =
      self.state.geo.auflager.AlBselected.key != "stahltraeger" &&
      self.state.geo.auflager.AlBselected.key != "peikko" &&
      self.state.geo.auflager.AlAselected.key != "pfeifer" &&
      self.state.geo.auflager.AlBselected.key != "tecnostrutture" &&
      self.state.geo.auflager.AlBselected.key != "mauer" &&
      self.state.geo.auflager.AlBselected.key != "_50prozent"
        ? betonAL("auflagerB")
        : "";

    let stahlAL = function (alSelector) {
      let basicswitcher = function (alSelector2) {
        let alselect =
          alSelector2 == "auflagerA" ? "AlAselected" : "AlBselected";
        if (
          self.state.geo.auflager[alselect].key == "peikko" ||
          self.state.geo.auflager[alselect].key == "pfeifer" ||
          self.state.geo.auflager[alselect].key == "tecnostrutture" ||
          self.state.geo.auflager[alselect].key == "stahltraeger"
        ) {
          return (
            <div>
              {fuelltiefe(alSelector2)}
              <InputAndLabel
                label="Stützweite"
                defaultValue={50}
                value={self.state.geo.biegeweich[alSelector2].abstand}
                toolTip="Stützweite zwischen den Momentennullpunkten"
                onChange={(itm) =>
                  self._handleChange(
                    itm,
                    "biegeweich." + alSelector + ".abstand"
                  )
                }
                einheit="m"
              />
              {tragerbasics(alSelector2)}
              <InputAndLabel
                label="Breite Fuge"
                defaultValue={50}
                value={self.state.geo.biegeweich[alSelector2].breite}
                toolTip="Breite der Betonfuge auf dem Träger"
                onChange={(itm) =>
                  self._handleChange(
                    itm,
                    "biegeweich." + alSelector + ".breite"
                  )
                }
                einheit="m"
              />
            </div>
          );
        } else {
          return (
            <div>
              {fuelltiefe(alSelector2, true)}
              <InputAndLabel
                label="Stützweite"
                defaultValue={50}
                value={self.state.geo.biegeweich[alSelector2].abstand}
                toolTip="Stützweite zwischen den Momentennullpunkten"
                onChange={(itm) =>
                  self._handleChange(
                    itm,
                    "biegeweich." + alSelector + ".abstand"
                  )
                }
                einheit="m"
              />
              {tragerbasics(alSelector2, true)}
              <InputAndLabel
                label="Breite Fuge"
                defaultValue={50}
                value={self.state.geo.biegeweich[alSelector2].breite}
                toolTip="Breite der Betonfuge auf dem Träger"
                onChange={(itm) =>
                  self._handleChange(
                    itm,
                    "biegeweich." + alSelector + ".breite"
                  )
                }
                einheit="m"
              />
              {/*<InputAndLabel label="B<sub>uz</sub>" defaultValue={50} value={self.state.geo.biegeweich[alSelector].b} toolTip="Breite des Trägers zur Berechnung von Iy"
                               onChange={(itm) => self._handleChange(itm, 'biegeweich.' + alSelector + '.b')} einheit="m"/>
                <InputAndLabel label="H<sub>uz</sub>" defaultValue={50} value={self.state.geo.biegeweich[alSelector].h}
                               onChange={(itm) => self._handleChange(itm, 'biegeweich.' + alSelector + '.h')} einheit="m" toolTip="Höhe des Trägers zur Berechnung von Iy"/>*/}
            </div>
          );
        }
      };

      return (
        <div>
          {basicswitcher(alSelector)}
          {/*{fuelltiefe(alSelector)}
            {tragerbasics(alSelector)}
            <InputAndLabel label="Stützweite" defaultValue={50} value={self.state.geo.biegeweich[alSelector].abstand} toolTip="Stützweite zwischen den Momentennullpunkten"
                           onChange={(itm) => self._handleChange(itm, 'biegeweich.' + alSelector + '.abstand')} einheit="m"/>
            <InputAndLabel label="Breite Fuge" defaultValue={50} value={self.state.geo.biegeweich[alSelector].breite} toolTip="Breite der Betonfuge auf dem Träger"
                           onChange={(itm) => self._handleChange(itm, 'biegeweich.' + alSelector + '.breite')} einheit="m"/>*/}
          <div className="row geometriewell">
            <div className="col-md-8 pull-left pad-left-10">
              Unterstützung der Träger
            </div>
            <div className="col-md-4">
              <LNCheckBox
                checked={
                  self.state.geo.biegeweich[alSelector].tragerunterstuetzung
                }
                onChange={(boolval) =>
                  self._handleChange(
                    boolval,
                    "biegeweich." + alSelector + ".tragerunterstuetzung"
                  )
                }
                classNames={["geocheckbox"]}
              />
            </div>
          </div>
          <div className="row geometriewell">
            <div className="col-md-8 pull-left pad-left-10">
              Verbundsteigernde Massnahmen
            </div>
            <div className="col-md-4">
              <LNCheckBox
                checked={self.state.geo.biegeweich[alSelector].verbundsteigernd}
                onChange={(boolval) =>
                  self._handleChange(
                    boolval,
                    "biegeweich." + alSelector + ".verbundsteigernd"
                  )
                }
                classNames={["geocheckbox"]}
              />
            </div>
          </div>
        </div>
      );
    };
    let stahlAlA =
      self.state.geo.auflager.AlAselected.key != "mauer" &&
      self.state.geo.auflager.AlAselected.key != "_50prozent"
        ? stahlAL("auflagerA")
        : "";
    let stahlAlB =
      self.state.geo.auflager.AlBselected.key != "mauer" &&
      self.state.geo.auflager.AlBselected.key != "_50prozent"
        ? stahlAL("auflagerB")
        : "";

    let gueteSelect = function (alSelector, gtype) {
      //debugger;
      let alselect = alSelector == "auflagerA" ? "AlAselected" : "AlBselected";
      var gueteselected =
        self.state.geo.biegeweich[alSelector].alTypes[gtype].selected;
      var gueteauswahl =
        self.state.geo.biegeweich[alSelector].alTypes[gtype].auswahl;
      //debugger;
      if (
        self.state.geo.auflager[alselect].key == "peikko" ||
        self.state.geo.auflager[alselect].key == "pfeifer" ||
        self.state.geo.auflager[alselect].key == "tecnostrutture"
      ) {
        gueteauswahl = _.filter(gueteauswahl, (ga) => {
          /* console.log(
            "guete",
            ga,
            self.state.geo.biegeweich[alSelector].traegerpos
          ); */
          return (
            ga.position == self.state.geo.biegeweich[alSelector].traegerpos
            // || self.state.geo.biegeweich[alSelector].traegerpos == "rand"
          );
        });
        if (
          _.filter(gueteauswahl, (ga) => {
            return ga.name == gueteselected.name;
          }).length == 0
        ) {
          let gleichebreite = _.filter(gueteauswahl, (ga) => {
            return ga.h == gueteselected.h;
          });
          gueteselected =
            gleichebreite.length > 0 ? gleichebreite[0] : gueteauswahl[0];
          setTimeout(() => {
            self._onSelectAuflager(
              gueteselected,
              "biegeweich." + alSelector + ".alTypes." + gtype + ".selected"
            );
          }, 100);
        }
      }
      return (
        <div className="form-group">
          <DropDown
            id="gueteselectA"
            styles={["min-h-34"]}
            onSelect={(itm) => {
              self._onSelectAuflager(
                itm,
                "biegeweich." + alSelector + ".alTypes." + gtype + ".selected"
              );
            }}
            labelField="name"
            selectedItem={gueteselected}
            dataProvider={gueteauswahl}
          />
        </div>
      );
    };

    var gueteDDA = "";
    var gueteDDB = "";
    if (
      self.state.geo.auflager.AlAselected.key != "mauer" &&
      self.state.geo.auflager.AlAselected.key != "_50prozent"
    ) {
      if (self.state.geo.auflager.AlAselected.key == "stahltraeger") {
        gueteDDA = gueteSelect("auflagerA", "stahl");
      } else if (self.state.geo.auflager.AlAselected.key == "peikko") {
        gueteDDA = gueteSelect("auflagerA", "peikko");
      } else if (self.state.geo.auflager.AlAselected.key == "pfeifer") {
        gueteDDA = gueteSelect("auflagerA", "pfeifer");
      } else if (self.state.geo.auflager.AlAselected.key == "tecnostrutture") {
        gueteDDA = gueteSelect("auflagerA", "tecnostrutture");
      } else {
        gueteDDA = gueteSelect("auflagerA", "beton");
      }
    }
    if (
      self.state.geo.auflager.AlBselected.key != "mauer" &&
      self.state.geo.auflager.AlBselected.key != "_50prozent"
    ) {
      if (self.state.geo.auflager.AlBselected.key == "stahltraeger") {
        gueteDDB = gueteSelect("auflagerB", "stahl");
      } else if (self.state.geo.auflager.AlBselected.key == "peikko") {
        gueteDDB = gueteSelect("auflagerB", "peikko");
      } else if (self.state.geo.auflager.AlBselected.key == "pfeifer") {
        gueteDDB = gueteSelect("auflagerB", "pfeifer");
      } else if (self.state.geo.auflager.AlBselected.key == "tecnostrutture") {
        gueteDDB = gueteSelect("auflagerB", "tecnostrutture");
      } else {
        gueteDDB = gueteSelect("auflagerB", "beton");
      }
    }

    let peikkoPosSelect = function (alSelector) {
      let alselect = alSelector == "auflagerA" ? "AlAselected" : "AlBselected";
      if (self.state.geo.auflager[alselect].key == "peikko") {
        return (
          <div>
            <h6>Steifigkeit des Peikko Trägers</h6>
            <div className="row geometriewell">
              <div className="col-md-12 pad-right-0">
                <label className="radio-inline  pad-right-0 ms-5">
                  <input
                    type="radio"
                    name={"peikkoeiOptions_" + alSelector}
                    id="peikkoeiweich"
                    value="ei_weich"
                    onChange={(itm) =>
                      self._handleChange(
                        itm.target.value,
                        "biegeweich." + alSelector + ".peikkoei"
                      )
                    }
                    checked={
                      self.state.geo.biegeweich[alSelector].peikkoei ===
                      "ei_weich"
                    }
                  />{" "}
                  Weich
                </label>
                <label className="radio-inline  mx-2 pad-right-0">
                  <input
                    type="radio"
                    name={"peikkoeiOptions_" + alSelector}
                    id="peikkoeimittel"
                    value="ei_mittel"
                    onChange={(itm) =>
                      self._handleChange(
                        itm.target.value,
                        "biegeweich." + alSelector + ".peikkoei"
                      )
                    }
                    checked={
                      self.state.geo.biegeweich[alSelector].peikkoei ===
                      "ei_mittel"
                    }
                  />{" "}
                  Mittel
                </label>
                <label className="radio-inline   pad-right-0">
                  <input
                    type="radio"
                    name={"peikkoeiOptions_" + alSelector}
                    id="peikkoeisteif"
                    value="ei_steif"
                    onChange={(itm) =>
                      self._handleChange(
                        itm.target.value,
                        "biegeweich." + alSelector + ".peikkoei"
                      )
                    }
                    checked={
                      self.state.geo.biegeweich[alSelector].peikkoei ===
                      "ei_steif"
                    }
                  />{" "}
                  Steif
                </label>
              </div>
            </div>
          </div>
        );
      } else if (self.state.geo.auflager[alselect].key == "pfeifer") {
        return (
          <div>
            <h6>Steifigkeit des Pfeifer Trägers</h6>
            <div className="row geometriewell">
              <div className="col-md-12 pad-right-0">
                <label className="radio-inline  pad-right-0 ms-5">
                  <input
                    type="radio"
                    name={"pfeifereiOptions_" + alSelector}
                    id="pfeifereiweich"
                    value="ei_weich"
                    onChange={(itm) =>
                      self._handleChange(
                        itm.target.value,
                        "biegeweich." + alSelector + ".pfeiferei"
                      )
                    }
                    checked={
                      self.state.geo.biegeweich[alSelector].pfeiferei ===
                      "ei_weich"
                    }
                  />{" "}
                  Weich
                </label>
                <label className="radio-inline mx-2  pad-right-0">
                  <input
                    type="radio"
                    name={"pfeifereiOptions_" + alSelector}
                    id="pfeifereimittel"
                    value="ei_mittel"
                    onChange={(itm) =>
                      self._handleChange(
                        itm.target.value,
                        "biegeweich." + alSelector + ".pfeiferei"
                      )
                    }
                    checked={
                      self.state.geo.biegeweich[alSelector].pfeiferei ===
                      "ei_mittel"
                    }
                  />{" "}
                  Mittel
                </label>
                <label className="radio-inline   pad-right-0">
                  <input
                    type="radio"
                    name={"pfeifereiOptions_" + alSelector}
                    id="pfeifereisteif"
                    value="ei_steif"
                    onChange={(itm) =>
                      self._handleChange(
                        itm.target.value,
                        "biegeweich." + alSelector + ".pfeiferei"
                      )
                    }
                    checked={
                      self.state.geo.biegeweich[alSelector].pfeiferei ===
                      "ei_steif"
                    }
                  />{" "}
                  Steif
                </label>
              </div>
            </div>{" "}
          </div>
        );
      } else if (self.state.geo.auflager[alselect].key == "tecnostrutture") {
        return (
          <div>
            <h6>Steifigkeit des Tecnostrutture Trägers</h6>
            <div className="row geometriewell">
              <div className="col-md-12 pad-right-0">
                <label className="radio-inline  pad-right-0 ms-5">
                  <input
                    type="radio"
                    name={"tecnostruttureOptions_" + alSelector}
                    id="tecnostruttureeiweich"
                    value="ei_weich"
                    onChange={(itm) =>
                      self._handleChange(
                        itm.target.value,
                        "biegeweich." + alSelector + ".tecnostruttureei"
                      )
                    }
                    checked={
                      self.state.geo.biegeweich[alSelector].tecnostruttureei ===
                      "ei_weich"
                    }
                  />{" "}
                  Weich
                </label>
                <label className="radio-inline mx-2  pad-right-0">
                  <input
                    type="radio"
                    name={"tecnostruttureeiOptions_" + alSelector}
                    id="tecnostruttureeimittel"
                    value="ei_mittel"
                    onChange={(itm) =>
                      self._handleChange(
                        itm.target.value,
                        "biegeweich." + alSelector + ".tecnostruttureei"
                      )
                    }
                    checked={
                      self.state.geo.biegeweich[alSelector].tecnostruttureei ===
                      "ei_mittel"
                    }
                  />{" "}
                  Mittel
                </label>
                <label className="radio-inline   pad-right-0">
                  <input
                    type="radio"
                    name={"tecnostruttureeiOptions_" + alSelector}
                    id="tecnostruttureeisteif"
                    value="ei_steif"
                    onChange={(itm) =>
                      self._handleChange(
                        itm.target.value,
                        "biegeweich." + alSelector + ".tecnostruttureei"
                      )
                    }
                    checked={
                      self.state.geo.biegeweich[alSelector].tecnostruttureei ===
                      "ei_steif"
                    }
                  />{" "}
                  Steif
                </label>
              </div>
            </div>{" "}
          </div>
        );
      } else {
        return "";
      }
    };

    return (
      <div>
        <h3>Geometrie</h3>
        <hr />
        <div className="row">
          <h4>Feld</h4>
          <div className="row">
            <div className="col-md-6">
              <InputAndLabel
                label="Lichte Feldweite"
                defaultValue={5.0}
                value={this.state.geo.feldweite}
                onChange={(itm) => self._handleChange(itm, "feldweite")}
                einheit="m"
                id="lfw"
              />
              {/*<InputAndLabel label="Feldbreite" defaultValue={1.20} value={this.state.geo.feldbreite} disabled={true} einheit="m" id="lfb"/>*/}
            </div>
            <div className="col-md-6">
              <InputAndLabel
                label="Stützweite"
                defaultValue={5.05}
                value={this.state.geo.stuetzweite}
                disabled={true}
                einheit="m"
                id="sw"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <h4>Auflager A</h4>
            </div>
            <div className="col-md-6">
              <h4>Auflager B</h4>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <DropDown
                  id="auflagerselect"
                  styles={["min-h-34"]}
                  onSelect={(itm) => {
                    this._onSelectAuflager(itm, "auflager.AlAselected");
                  }}
                  labelField="label"
                  selectedItem={this.state.geo.auflager.AlAselected}
                  dataProvider={this.state.geo.auflager.ALAauswahl}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <DropDown
                  id="auflagerselectB"
                  styles={["min-h-34"]}
                  onSelect={(itm) => {
                    this._onSelectAuflager(itm, "auflager.AlBselected");
                  }}
                  labelField="label"
                  selectedItem={this.state.geo.auflager.AlBselected}
                  dataProvider={this.state.geo.auflager.ALBauswahl}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              {renderTragerposChange("auflagerA")}
              {gueteDDA}
              {self.findPeikkoSkizze("auflagerA")}
            </div>
            <div className="col-md-6">
              {renderTragerposChange("auflagerB")}
              {gueteDDB}
              {self.findPeikkoSkizze("auflagerB")}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">{peikkoPosSelect("auflagerA")}</div>
            <div className="col-md-6">{peikkoPosSelect("auflagerB")}</div>
          </div>
          <div className="row">
            <div className="col-md-6">
              {/*<InputAndLabel label="Mindest-Auflagertiefe" defaultValue={0.7} value={this.state.geo.auflager.mindestauflager} disabled={true} einheit="m" />*/}
              <InputAndLabel
                label="Auflagertiefe A"
                defaultValue={0.05}
                value={this.state.geo.auflager.auflagerA}
                onChange={(itm) =>
                  self._handleChange(itm, "auflager.auflagerA")
                }
                einheit="m"
              />
              {betonAlA}
              {stahlAlA}
            </div>
            <div className="col-md-6">
              <InputAndLabel
                label="Auflagertiefe B"
                defaultValue={0.05}
                value={this.state.geo.auflager.auflagerB}
                onChange={(itm) =>
                  self._handleChange(itm, "auflager.auflagerB")
                }
                einheit="m"
              />
              {stahlAlB}
              {betonAlB}
            </div>
          </div>
        </div>
      </div>
    );

    function renderTragerposChange(alSelector) {
      console.log("alSelector", alSelector);
      let alSelect = alSelector == "auflagerA" ? "AlAselected" : "AlBselected";
      if (
        self.state.geo.auflager[alSelect].key == "stahltraeger" ||
        self.state.geo.auflager[alSelect].key == "peikko" ||
        self.state.geo.auflager[alSelect].key == "pfeifer" ||
        self.state.geo.auflager[alSelect].key == "tecnostrutture"
      ) {
        return (
          <div className="row geometriewell">
            <div className="col-md-4 pull-left pad-left-10">Trägerposition</div>
            <div className="col-md-8 pad-right-0">
              <label className="radio-inline float-end pad-right-0 ms-2">
                <input
                  type="radio"
                  name={"traegerposOptions_" + alSelector}
                  id="traegerposmitte"
                  value="mitte"
                  onChange={(itm) =>
                    self._handleChange(
                      itm.target.value,
                      "biegeweich." + alSelector + ".traegerpos"
                    )
                  }
                  checked={
                    self.state.geo.biegeweich[alSelector].traegerpos === "mitte"
                  }
                />{" "}
                Mitte
              </label>
              <label className="radio-inline float-end  pad-right-0">
                <input
                  type="radio"
                  name={"traegerposOptions_" + alSelector}
                  id="traegerposrand"
                  value="rand"
                  onChange={(itm) =>
                    self._handleChange(
                      itm.target.value,
                      "biegeweich." + alSelector + ".traegerpos"
                    )
                  }
                  checked={
                    self.state.geo.biegeweich[alSelector].traegerpos === "rand"
                  }
                />{" "}
                Rand
              </label>
            </div>
          </div>
        );
      } else {
        return "";
      }
    }
  }
}

export default Geometrie;
