import PropTypes from 'prop-types';
import React from 'react';
import LNCheckBox from './LNCheckBox';
import _ from 'lodash';
import { Pencil, Trash } from 'react-bootstrap-icons';
var ReactPropTypes = PropTypes;

class DataGrid extends React.Component {
    constructor() {
        super();
        //this.state = {invalue: 0};
        this._handleClick = this._handleClick.bind(this);
    }





    _handleClick(rowitem) {

        this.props.onSelect(rowitem);
    }

    componentDidMount() {

    }

    _trClass(item){
      if(this.props.trClass){
       return this.props.trClass(item);
      }
      return '';
    }

    _tdHtml(hitem,item){
      var self = this;
      var ret = '';
      if(hitem.key.indexOf('__cb') == 0){
        let k = hitem.key.split(':')[1];
        ret = <LNCheckBox checked={_.get(item,k)} onChange={(boolval)=>{self.props.checkBoxChange(boolval,item,hitem);}}/>;
      }else if(hitem.key.indexOf('__bt') == 0){
        ret = <button type="button" className="btn btn-default btn-sm" onClick={()=>{self.props.buttonClick(item);}}>{(item.Error != '')?'Überschreitung anzeigen':'Auswählen'}</button>;
      }else if(hitem.key.indexOf('__2bt') == 0) {
        ret = <div><button type="button" className="btn btn-default btn-sm" onClick={()=> {self.props.buttonClick(item);}}><Pencil/></button>
          <button type="button" className="btn btn-default btn-sm" onClick={()=> {self.props.buttonClick2(item);}}><Trash></Trash></button></div>;
      }else{
        ret = (self.props.valueFormatter != null )?self.props.valueFormatter(_.get(item,hitem.key)):_.get(item,hitem.key);
      }
      return ret;
    }


    render() {
        let self = this;
        var mkey = 0;
        return (
            <div style={{"overflowX":"auto"}}>
                <table className="table table-bordered" style={{"maxWidth":window.outerWidth}}>
                    <thead>
                        <tr>
                            {this.props.headers.map(function (item) {
                                mkey ++;
                                return <th key={mkey} dangerouslySetInnerHTML={{__html: item.label}}></th>;
                            })}

                         </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.dataProvider.map(function (item) {
                            mkey ++;
                          //(item.selected)?'info':''
                            return (
                                <tr className={self._trClass(item)} key={mkey} onClick={(evt) => {evt.preventDefault(); self._handleClick(item);}}>{
                                    self.props.headers.map(function (hitem) {
                                        mkey ++;
                                        return <td key={mkey}>{self._tdHtml(hitem,item)}</td>;
                                    })

                                }

                                </tr>
                            );
                        })

                    }
                    </tbody>
                </table>
            </div>
        );
    }
}
DataGrid.propTypes = {
    id: ReactPropTypes.string,
    headers:ReactPropTypes.arrayOf(PropTypes.object),
    dataProvider:ReactPropTypes.arrayOf(PropTypes.object),
    onSelect:ReactPropTypes.func,
    valueFormatter:ReactPropTypes.func,
    trClass:ReactPropTypes.func,
    checkBoxChange:ReactPropTypes.func,
    buttonClick:ReactPropTypes.func,
    buttonClick2:ReactPropTypes.func
};

export default DataGrid;
