import Dispatcher from '../dispatcher/Dispatcher';
import Constants from '../constants/constants';
import BaseStore from './BaseStore';
import GrundDatenStore from './GrundDatenStore';
import assign from 'object-assign';
import _ from 'lodash';


var aussparungen = {
  headers:[
    {label:'ID',key:'id'},
    {label:'Breite',key:'breite'},
    {label:'Länge',key:'laenge'},
    {label:'Vom Auflager',key:'vomauflager'},
    {label:'Vom Längsrand',key:'vomlaengsrand'}

  ],
  data:[]
};
var rawdata = {lagen:{},groessen:{}};

var lagen = {auswahl:[]};
var groessen = {auswahl:[]};

function buildDataSets(aldata,agdata) {
    var numkey = 0;
    lagen.auswahl = aldata.json.dataroot.Aussparungslagen.concat();
    lagen.auswahl = lagen.auswahl.map(function(item) {
        numkey++;
        return assign(item,{key:numkey + ''});
    });
    groessen.auswahl = agdata.json.dataroot.Aussparungsgroessen.concat();
    groessen.auswahl = groessen.auswahl.map(function(item) {
        numkey++;
        return assign(item,{key:numkey + ''});
    });
}


const AussparungStore = assign({}, BaseStore, {

    getAussparungen() {
      return aussparungen;
    },

    getAll() {
        return {aussparungen:aussparungen,lagen:lagen,groessen:groessen};
    },
    getFiltered(RandOrFeld) {
        let lf = _.filter(lagen.auswahl,{'Plattentyp':GrundDatenStore.getAll().deckenWerte.decken.selected.Plattentype});
        let agf = _.filter(groessen.auswahl,{'Plattentyp':GrundDatenStore.getAll().deckenWerte.decken.selected.Plattentype,'Lage':RandOrFeld});
        return {lagen:lf,groessen:agf};
        //{'Plattentype':deckenWerte.decken.selected.Plattentype}
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function handleAction(payload) {
        const action = payload.action;

        switch (action.type) {
            case Constants.ActionTypes.DATA_LOADED:
                assign(rawdata.lagen,action.aldata);
                assign(rawdata.groessen,action.agdata);
                buildDataSets(action.aldata,action.agdata);
                AussparungStore.emitChange();
                break;

            case Constants.ActionTypes.ADD_AUSSPARUNG:
                //aussparungen.data.push(_.set(action.last,'id','Last '+ (zusatzlasten.data.length+1)));
                AussparungStore.emitChange();
                break;
            case Constants.ActionTypes.REMOVE_AUSSPARUNG:
                _.remove(aussparungen.data, function(d) {
                    return action.last.id === d.id;
                });

                AussparungStore.emitChange();
                break;



        }
    })
});

export default AussparungStore;
