import PropTypes from 'prop-types';
import React from 'react';
import SizeMe from 'react-sizeme';
import * as PIXI from 'pixi.js';
import CalcStore from '../../stores/CalcStore';
import PDFStore from '../../stores/PdfStore';
import assign from 'object-assign';
import _ from 'lodash';

class Visualizer extends React.Component {

    constructor() {
        super();
        this._onChange = this._onChange.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._drawElements = this._drawElements.bind(this);
      this._saveImg = this._saveImg.bind(this);
        var l = CalcStore.getLasten();
        var cl = (l.length >= 3)?l.slice(3):[];
      var l2 = CalcStore.getEigenLasten();
      this.state = {lasten:l2.concat(cl),input:CalcStore.getInput()};
       // this.state = {lasten:cl,input:CalcStore.getInput()};
        Math.radians = function(degrees) {
            return degrees * Math.PI / 180;
        };
        Math.degrees = function(radians) {
            return radians * 180 / Math.PI;
        };
        this.stage = null;
        this.renderer = null;
        this.aniframeid = -1;
        console.log("mpixie",PIXI);
    }

    _handleChange() {
    }

    _onChange() {
        var l = CalcStore.getLasten();
        var cl = (l.length >= 3)?l.slice(3):[];
      var l2 = [];//CalcStore.getEigenLasten();
        this.setState({lasten:l2.concat(cl),input:CalcStore.getInput()});
    }

    _drawElements() {
      let gq = CalcStore.getLasten4Picture();
      let rlasten =  _.map(this.state.lasten,function (li) {
        return _.cloneDeep(li);
      });
      if(this.props.renderState && this.props.renderState.lasten.length >0 && this.props.renderState.editLastID == "none" ){
        rlasten.push(this.props.renderState.lasten[0]);
      }
      if(this.props.renderState && this.props.renderState.editLastID != "none"){
        let editid = this.props.renderState.editLastID;
        let foundLast = _.filter(rlasten,function (tq) {
          return tq.label == editid;
        });
        assign(foundLast[0],this.props.renderState.lasten[0]);

      }
      gq.g = gq.g.concat(rlasten);
      gq.q = gq.q.concat(rlasten);

      var lc = _.filter(gq.g,function(ig){return ig != 0.0;}).length + _.filter(gq.q,function(iq){return iq != 0.0;}).length;

       // let breite = 1.2;
      let borderright = 130;
      let borderleft = 20;
      let border = borderright + borderleft;
        let laenge = this.state.input.stutzWeite;
      //  let faktor = laenge / breite;
        var self = this;
        if (this.renderer != null) {
            cancelAnimationFrame(this.aniframeid);
          try{

            this.renderer.destroy(true);
          }catch(e){}

        }
        let sw = this.props.size.width;
        let sh = 130 + 27 * lc;
        let drawlaenge = sw-border;
       // let drawbreite = drawlaenge / faktor;
        this.renderer = new PIXI.Application({ width: sw, height: sh, backgroundColor:0xFFFFFF });//new PIXI.CanvasRenderer(sw, sh);
        let renderer = this.renderer;
        //renderer.backgroundColor = 0xFFFFFF;
        document.getElementById('renderCanvas').appendChild(renderer.view);
        this.stage = new PIXI.Container();
        renderer.stage.addChild(this.stage);
        let stage = this.stage;

        var graphics = new PIXI.Graphics();
        graphics.beginFill(0x7fb2f2,0.0);
        graphics.lineStyle(1, 0x999999,0.5);
        graphics.drawRect(1, 1, sw-2, sh-2);
        graphics.endFill();
        //graphics.lineStyle(1, 0x000000,1);
        //graphics.drawRect((sw - drawlaenge)/2, (sh- drawbreite)/2, drawlaenge, drawbreite);
        stage.addChild(graphics);

      function drawStutzweite(atY){

        let uparrow1 = new PIXI.Sprite.from('assets/images/arrow.png');
        uparrow1.x = borderleft - uparrow1.width/2;
        uparrow1.y = atY-30;
        stage.addChild(uparrow1);
        let uparrow2 = new PIXI.Sprite.from('assets/images/arrow.png');
        uparrow2.x = sw-borderright - uparrow2.width/2;
        uparrow2.y = atY-30;
        stage.addChild(uparrow2);
        graphics.lineStyle(1, 0x000000,1);
        graphics.drawRect(borderleft, atY-30, drawlaenge, 1);
        let textstutzw = new PIXI.Text(laenge.toFixed(2),{fontFamily : 'Arial', fontSize: 10, fill : 0x00, align : 'center'});
        let textw = textstutzw.width;
        textstutzw.y = atY-15;
        textstutzw.x = (sw - border)/2 + borderleft - textw/2;
        stage.addChild(textstutzw);
      }

      function drawArrow(ax,ay,aheight,color,alpha){
        graphics.lineStyle(1, color,alpha|1);
        let tmaxy = ay+aheight;
        graphics.moveTo(ax,tmaxy);
        graphics.lineTo(ax+5,tmaxy-5);
        graphics.moveTo(ax,tmaxy);
        graphics.lineTo(ax-5,tmaxy-5);
        graphics.moveTo(ax,ay);
        graphics.lineTo(ax,tmaxy);
      }

      function fillWithArrows(fstartx,fstarty,fheight,fwidth){
        let gap = 20;
        var tx = fstartx + gap;
        graphics.lineStyle(1, 0x999999,1);

        while (tx < fstartx+fwidth) {
          drawArrow(tx,fstarty,fheight, 0x999999,1);
          tx += gap;

        }
      }
/*
      function drawbms(bms,atY){
        let textbma= new PIXI.Text('bmA = '+bms.bma.label+' m',{font : 'normal 12px Arial', fill : 0x00, align : 'left'});
        var textw = textbma.width;
        textbma.y = atY-15;
        textbma.x = borderleft;
        stage.addChild(textbma);
        let textbmm= new PIXI.Text('bmM = '+bms.bmm.label+' m',{font : 'normal 12px Arial', fill : 0x00, align : 'center'});
        textw = textbmm.width;
        textbmm.y = atY-15;
        textbmm.x = (sw - border)/2 + borderleft - textw/2;
        stage.addChild(textbmm);
        let textbmb= new PIXI.Text('bmB = '+bms.bmb.label+' m',{font : 'normal 12px Arial', fill : 0x00, align : 'right'});
        textw = textbmb.width;
        textbmb.y = atY-15;
        textbmb.x = sw-borderright - textw;
        stage.addChild(textbmb);
      }
*/
      function renderlasten(mlasten,mstarty,lasttype){
        var starty = mstarty;
        let ltype = lasttype || 'G';
        _.each(mlasten,function (last) {
          let lastvalue = last[ltype];
          if( lastvalue != 0.0){
            var l = last.Lange;
            let lf = laenge /l;
            let af = laenge / last.Anfang;
            let dl = Math.max(1,drawlaenge / lf);
            graphics.lineStyle(1, 0x999999,1);
            if(last.Lange != 0.0){
              graphics.drawRect(drawlaenge/af + borderleft,starty,dl,20);
            }else{
              drawArrow(drawlaenge/af + borderleft, starty,20,0x000000,1);
            }
            let textval = new PIXI.Text(lastvalue.toFixed(2) ,{fontFamily : 'Arial', fontSize: 10, fill : 0x00, align : 'right'});
            textval.x = sw - borderright + 60 - textval.width;
            textval.y = starty + 10 - textval.height/2;
            graphics.addChild(textval);
            fillWithArrows(drawlaenge/af + borderleft,starty,20,dl);
            starty -= 25;
          }
        });
        return starty;
      }

      drawStutzweite(sh);
      let lasty = renderlasten(gq.q,sh-55,'rawQ');
      let textval = new PIXI.Text('Veränderliche Lasten' ,{fontFamily : 'Arial', fontSize: 12, fontWeight:'bold', fill : 0x00, align : 'left'});
      textval.x =  borderleft;
      textval.y = lasty -  5;
      graphics.addChild(textval);
      graphics.lineStyle(1, 0x999999,0.5);
      graphics.drawRect(1, lasty-12, sw-2, 1);
      drawStutzweite(lasty-15);
      let lasty2 = renderlasten(gq.g,lasty- 45 - textval.height-10,'rawG');
      let textval2 = new PIXI.Text('Ständige Lasten' ,{fontFamily : 'Arial', fontSize: 12, fontWeight:'bold', fill : 0x00, align : 'left'});
      textval2.x =  borderleft;
      textval2.y = lasty2 - 5;
      graphics.addChild(textval2);


        this.aniframeid = requestAnimationFrame( animate );
        function animate() {
            if (self.renderer) {
                self.aniframeid = requestAnimationFrame( animate );
                renderer.render(stage);

            }

        }

    }



    _saveImg(){
      var d = undefined;
      if(this.state.lasten.length >0 ){
         d = this.renderer.view.toDataURL('image/png');
      }
      PDFStore.setlastenImage(d);
      d = undefined;
    }

    componentDidMount() {
      //  VisualizerStore.addChangeListener(this._onChange);
      //  this._drawElements();
        CalcStore.addChangeListener(this._onChange);

    }

    componentWillUnmount() {
      //  VisualizerStore.removeChangeListener(this._onChange);
      this._saveImg();
        CalcStore.removeChangeListener(this._onChange);
        cancelAnimationFrame(this.aniframeid);
      try{

        this.renderer.destroy(true);
      }catch(e){}
    }

    render() {
        setTimeout(this._drawElements,10);
        return (
            <div id="renderCanvas">

            </div>
        );
    }
}

Visualizer.propTypes = {
    size: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number,
    }),
    renderState:PropTypes.object,


};
export default SizeMe()(Visualizer);
