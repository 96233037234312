import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import AussparungStore from '../../stores/AussparungStore';
import InputAndLabel from '../../components/common/InputAndLabel';
import DropDown from '../../components/common/DropDown';
import Visualizer from '../../components/common/Visualizer';
import _ from 'lodash'

class Aussparung extends React.Component {
    constructor() {
        super();
        this._onChange = this._onChange.bind(this);
        this._handleOptionChange = this._handleOptionChange.bind(this);
        this._onSelect = this._onSelect.bind(this);
        this.state = {
            lage:'rand',
            abstand:{
                xa:{label:'0,0',value:0.0},
                ya:{
                    auswahl:
                        [{key:1,label:'0,0',value:0.0},{key:2,label:'1,20',value:1.2}],
                        selected:{key:1,label:'0,0',value:0.0}

                }
            },
            masse:{
                breite:{
                        auswahl:
                            [{key:1,label:'0,0',value:0.0},{key:2,label:'0,22',value:2.22}],
                        selected:{key:1,label:'0,0',value:0.0}
                },
                laenge:{label:'0,0',value:0.0}
            }
        };
    }



    _onChange() {
    }

    _handleOptionChange(changeEvent) {
        let valobj = _.cloneDeep(this.state);
        let val = changeEvent.target.value;
        _.set(valobj,'lage',val);
        this.setState(valobj);
    }
    _onSelect(item,path) {
       // let checkedval = parseFloat(item.match(/[+-]?\d+(\,\d+)?/g)[0].replace(",","."));
        let valobj = _.set(_.cloneDeep(this.state),path,item);
        this.setState(valobj);
        //ActionCreator.setBelastung(valobj);
    }
    _addAussparung() {

    }

    componentDidMount() {
        AussparungStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        AussparungStore.removeChangeListener(this._onChange);
    }

    render() {
        return (
            <div>
                <div className="row">
                    <h3>Aussparung erstellen</h3>
                    <hr/>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <h4>Lage</h4>
                        <hr/>
                        <form className="mg-bottom-15">
                            <label className="radio-inline">
                                <input type="radio" name="lageOptions" id="lagerand" value="rand" onChange={this._handleOptionChange} checked={this.state.lage === 'rand'} /> Rand
                            </label>
                            <label className="radio-inline">
                                <input type="radio" name="lageOptions" id="lagefeld" value="feld" onChange={this._handleOptionChange} checked={this.state.lage === 'feld'} /> Feld
                            </label>
                        </form>
                        <h4 className="mg-top-15">Abstand</h4>
                        <hr/>
                        <InputAndLabel label="xa vom Auflager" einheit="m"/>
                      <div className="input-group mg-bottom-15" >
                        <div className="input-group-addon inputaddon-minw">ya vom Längsrand</div>
                        <DropDown label={'ya vom Längsrand'} dataProvider={this.state.abstand.ya.auswahl} selectedItem={this.state.abstand.ya.selected} labelField={'label'}
                                  onSelect={(itm) => (this._onSelect(itm,'abstand.ya.selected'))} styles={['btngroup-100']}/>
                        <div className="input-group-addon ">m</div>
                      </div>
                        <h4 className="mg-top-30">Maße</h4>
                        <hr/>
                      <div className="input-group mg-bottom-15" >
                        <div className="input-group-addon inputaddon-minw">Breite</div>

                        <DropDown label={'Breite'} dataProvider={this.state.masse.breite.auswahl} selectedItem={this.state.masse.breite.selected} labelField={'label'}
                                  onSelect={(itm) => (this._onSelect(itm,'masse.breite.selected'))} styles={['btngroup-100']}/>

                        <div className="input-group-addon ">m</div>
                      </div>
                        <InputAndLabel label="Länge" einheit="m"/>

                    </div>
                    <div className="col-md-8 visualizer">
                        <Visualizer />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <hr/>
                        <button type="button" className="btn btn-default btn-sm pull-left" onClick={()=> this.props.router.goBack()}>Abbrechen</button>
                        <button type="button" className="btn btn-default btn-sm pull-right" onClick={this._addAussparung}>Aussparung übernehmen</button>
                    </div>
                </div>
            </div>
        );
    }
}
Aussparung.propTypes = {
    router: PropTypes.shape({
        goBack: PropTypes.func
    })
};

export default withRouter(Aussparung);
