import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import ActionCreator from "../actions/StatikActionCreator";
import PdfStore from "../stores/PdfStore";
import LNFileSaver from "../utils/LNFileSaver";

import logo from "../assets/images/logo.png";
import version from "../version";

class Header extends React.Component {
  _handleDrucken() {
    PdfStore.getAll();
    ActionCreator.createPDFAndPrint();
  }
  _handlePDF() {
    ActionCreator.createPDF();
  }
  _handleSave() {
    LNFileSaver.getAll();
    ActionCreator.saveData();
  }
  render() {
    let pname = ""; //this.context.location.pathname;
    // console.log('route: ',this.context.location);
    let isactive = (p) => {
      return pname == p ? "active" : "";
    };
    return (
      <header className="layout-header">
        <nav className="navbar fixed-top navbar-expand-lg bg-light">
          <div className="container">
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <a className="navbar-brand" href="https://www.dw-systembau.de">
              <img className="logotop" src={logo} />
            </a>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <span className="navbar-text">{"Version: " + version}</span>
              <ul className="navbar-nav ms-auto">
                <li className={"nav-item " + isactive("/calc/geometrie")}>
                  <Link className="nav-link" to="/calc/geometrie">
                    Geometrie
                  </Link>
                </li>
                <li className={"nav-item " + isactive("/calc/belastung")}>
                  <Link className="nav-link" to="/calc/belastung">
                    Einwirkungen
                  </Link>
                </li>
                <li className={"nav-item " + isactive("/calc/zusatzlasten")}>
                  <Link className="nav-link" to="/calc/zusatzlasten">
                    Zusatzlasten
                  </Link>
                </li>
                <li className={"nav-item " + isactive("/pop/io")}>
                  <Link className="nav-link" to="/pop/io">
                    Speichern/Laden
                  </Link>
                </li>
                {/* <li ><a href="#" onClick={this._handleSave}>Speichern</a></li>*/}
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="assets/hilfe/hilfe.pdf"
                    target="_blank"
                  >
                    Hilfe
                  </a>
                </li>
                <li className={"nav-item " + isactive("/pop/scheiben")}>
                  <Link className="nav-link" to="/pop/scheiben">
                    Scheiben
                  </Link>
                </li>
                {/* <li><a className='nav-link' href="https://apps.dw-systembau.de/bin/shell.php" target="_blank" rel="noreferrer">-- Scheiben <span className="glyphicon glyphicon-share"/></a></li>
                <li><Link to="/calc/aussparungen">Aussparungen</Link></li>
                <li ><a href="#" onClick={this._handleDrucken}>Drucken</a></li>
                <li ><a href="#" onClick={this._handlePDF}>PDF</a></li>*/}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
Header.contextTypes = {
  // router: React.PropTypes.object,
  //location: React.PropTypes.object
};
Header.propTypes = {
  // router: PropTypes.shape({
  //   goBack: PropTypes.func
  // }),
  // location:PropTypes.object
};
export default withRouter(Header);
