//import {KeyMirror} from 'keyMirror';
import Enum from '../utils/enum';

const KeyMirror = (list) => {
  let newList= {};
  Object.keys(list).map(element => {
    var key = String(element);
    newList[key] = element
  });
return newList
}

export default  {
  // event name triggered from store, listened to by views
  CHANGE_EVENT: 'change',



  // Each time you add an action, add it here... They should be past-tense
  ActionTypes: KeyMirror({
    TASK_ADDED: null,
    SCHEIBEN_EINGABE:null,
    DATA_LOADING: null,
    DATA_LOADED: null,
    CREATE_PDF:null,
    PRINT_PDF:null,
    DECKE_SELECTED:null,
    DECKENTYP_SELECTED:null,
    DECKENDICKE_SELECTED:null,
    BEWEHRUNG_SELECTED:null,
    EXPO_SELECTED:null,
    GRUNDDATEN_EINGABE:null,
    GEOMETRIE_EINGABE:null,
    BELASTUNG_EINGABE:null,
    BELASTUNG_EINGABE_KATEGORIE:null,
    ADD_ZUSATZLAST:null,
    EDIT_ZUSATZLAST:null,
    SET_PRESET:null,
    TRIGGER_CHANGE:null,
    REMOVE_ZUSATZLAST:null,
    AUSSPARUNG_EINGABE:null,
    REMOVE_AUSSPARUNG:null,
    ADD_AUSSPARUNG:null,
    SAVE_DATA_TO_FILE:null,
    LOAD_DATA_FROM_FILE:null,
    FILE_LOADED:null
  }),

  ActionSources: KeyMirror({
    SERVER_ACTION: null,
    VIEW_ACTION: null
  }),

  //wall params
  WALL_USAGE : Enum({
    t: 'trenn',
    i: 'innen',
    a: 'aussen'
  })
};
