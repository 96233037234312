import React from 'react';
import Header from './Header';
import Footer from './Footer';
import ActionCreator from '../actions/StatikActionCreator';
import StatikCalc from '../components/pages/StatikCalc';

import ZusatzLast from '../components/pages/ZusatzLast';
import Aussparung from '../components/pages/Aussparung';
import Scheiben from '../components/pages/Scheiben';
import IO from '../components/pages/IO';
import {Switch,Route, Redirect} from 'react-router-dom';

class App extends React.Component {
  

    componentDidMount() {
        //Wallstore.addChangeListener(this._onChange);
        setTimeout(function() {

        ActionCreator.loadBasicData();
        },1000);
    };

  render() {
    return (
      <div>
        <Header/>

          <div className="container">
          <Switch>
          <Route exact path="/" component={() => <Redirect to="/calc/geometrie" />}/> 
            <Route path="/calc" component={StatikCalc}/>
            <Route path="/pop/zusatzlast" component={ZusatzLast}/>
            <Route path="/pop/aussparung" component={Aussparung}/>
            <Route path="/pop/io" component={IO}/>
            <Route path="/pop/scheiben" component={Scheiben} />
          </Switch>
          </div>

        <Footer/>
      </div>
    );
  }
}

export default App;
