import React from 'react';
//import {hashHistory} from 'react-router';

import BelastungStore from '../../stores/BelastungStore';
import DataGrid from '../../components/common/DataGrid';
import Visualizer from '../../components/common/Visualizer';
import ActionCreator from '../../actions/StatikActionCreator';
import _ from 'lodash'


class ZusatzLasten extends React.Component {

    constructor() {
        super();
        this._onChange = this._onChange.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._onSelectLast = this._onSelectLast.bind(this);
        this._deleteLast = this._deleteLast.bind(this);
      this._checkBoxChange = this._checkBoxChange.bind(this);
      this._handlePresetChange = this._handlePresetChange.bind(this);
        this.state = BelastungStore.getAll();
        this.selectedItem = null;

    }

    _handleChange() {
    }

    _onChange() {
        console.log("zusatzlasten change");
        //if (this.isMounted) {

        let tk = BelastungStore.getAll();
        this.setState(tk);
       // }
    }
    _addLast() {
    //onClick={this._addLast()}
    }
    _editLast() {

    }
    _deleteLast(item) {
        ActionCreator.removeZusatzLast(item);
    }
    _onSelectLast() {

        /*if (this.selectedItem) {
            this.selectedItem.selected = false;
        }
        this.selectedItem = item;
        item.selected = true;
        let tk = BelastungStore.getAll();
        this.setState(tk);*/
    }

    componentDidMount() {
        BelastungStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        BelastungStore.removeChangeListener(this._onChange);
    }
    _trClass(item){
      var cln = '';
      if(item.selected){
        cln = 'info';
      }
      return cln;
    }
    _checkBoxChange(boolval,item){
      item.enabled = boolval;
        setTimeout(function(){
            ActionCreator.triggerChange(null);
        },10);


    }

    _handlePresetChange(changeEvent){
      let val = changeEvent.target.value;
      let valobj = _.set(_.cloneDeep(BelastungStore.getAll()),'presets.lastverteilung',val);
      ActionCreator.setPreset(valobj);
    }

    _buttonclicked(item){
      this.props.history.push('/pop/zusatzlast', {editstate:item})
      /*
      hashHistory.pushState({editstate:item},
        '/pop/zusatzlast'

      );*/
    }

    _valueformatter(val){
      if(val =="Punktlast"){
        return val + ' kN';
      }
      if(val =="Linienlast"){
        return val + ' kN/m';
      }
      if(!isNaN(val)){
        return val.toFixed(2);
      }
      return val;
    }


    render() {
        return (
            <div>
                <div className="row mg-bottom-15">
                    <h3>Zusatzlasten</h3>
                    <hr/>
                  <div className="row">
                    <div className="col-md-12">
                      <h5>Lastverteilung:</h5>

                      <form className="mg-bottom-15">

                        <label className="radio-inline">
                          <input type="radio" name="lastOptions" id="1m" value="1m" onChange={this._handlePresetChange} checked={this.state.presets.lastverteilung === '1m'} /> Freier Rand(1m)
                        </label>
                        <label className="radio-inline">
                          <input type="radio" name="lastOptions" id="heft240" value="heft240" onChange={this._handlePresetChange} checked={this.state.presets.lastverteilung === 'heft240'} /> DAfStb, Heft 631
                        </label>
                        {/*
                         <label className="radio-inline">
                         <input type="radio" name="lastOptions" id="blocklast" value="blocklast" onChange={this._handleOptionChange} checked={this.state.lastart.lastoption === 'blocklast'} /> Blocklast
                         </label>
                         */}
                      </form>
                    </div>
                  </div>
                    <DataGrid headers={this.state.zusatzlasten.headers} trClass={this._trClass} valueFormatter={this._valueformatter} buttonClick={(itm)=>this._buttonclicked(itm)} buttonClick2={this._deleteLast} onSelect={this._onSelectLast} dataProvider={this.state.zusatzlasten.data} checkBoxChange={this._checkBoxChange} />
                </div>
                <div>
                    <button type="button" className="btn btn-default btn-sm" disabled={this.state.zusatzlasten.data.length > 3} onClick={()=>{this.props.history.push('/pop/zusatzlast');}}>Zufügen</button>
                 {/* <button type="button" className="btn btn-default btn-sm mg-left-15" style={this.selectedItem == null ? {display: "none"} : null} ><Link to="/pop/zusatzlast"  state={{editstate:this.selectedItem}}>Bearbeiten</Link></button>

                  <button type="button" className="btn btn-default btn-sm mg-left-15" style={this.selectedItem == null ? {display: "none"} : null} onClick={()=>{hashHistory.pushState({editstate:this.selectedItem},
                    '/pop/zusatzlast'

                  );}}>Bearbeiten</button>*/}
                    <button type="button" className="btn btn-default btn-sm pull-right" disabled={this.selectedItem == null} onClick={this._deleteLast}>Entfernen</button>
                    <div className="mg-bottom-15"></div>
                  <div className="visualizer"><Visualizer /></div>
                </div>
            </div>
        );
    }
}

export default ZusatzLasten;
