import Dispatcher from "../dispatcher/Dispatcher";
import Constants from "../constants/constants";
import BaseStore from "./BaseStore";
import assign from "object-assign";
import moment from "moment";
import GeometrieStore from "./GeometrieDatenStore";
import GrundDatenStore from "./GrundDatenStore";
import BelastungStore from "./BelastungStore";
import Scheibenstore from "./Scheibenstore";
import CalcStore from "./CalcStore";
import Utils from "../utils/UtilsStore";
import _ from "lodash";
import * as PIXI from "pixi.js";
import version from "../version";
//import {hashHistory}  from 'react-router';

// data storage
let _data = [];
let lastenImage = { data: null, datauri: null };
let arrowloadtex = PIXI.BaseTexture.from("assets/images/arrow.png"); // .fromImage('assets/images/arrow.png');
//let arrowload1 = new PIXI.Sprite.fromImage('assets/images/arrow.png');
//let arrowload2 = new PIXI.Sprite.fromImage('assets/images/arrow.png');
/*
function checkStatusofCalc(val,normval,smallerthan){
  //var valback = (isNaN(val) || val == 0)?" -- ":val;
  var valok = (smallerthan)?function(a,b){return a<=b}:function(a,b){return a>=b};
  var classval = (isNaN(val) || val <= 0 )?"---":(valok(val,normval))?"erfüllt":"nicht erfüllt!";
  return classval;
}
function round(value) {
  let decimals = 2;
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

*/

function printWithPdfJS(pdfdata) {
  var viewercontent = document.getElementById("pdfview").contentWindow;
  var container = document
    .getElementById("pdfview")
    .contentDocument.getElementById("viewerContainer");
  viewercontent.PDFViewerApplication.open(pdfdata).then(function () {});
  container.addEventListener("pagesloaded", function () {
    viewercontent.PDFViewerApplication.eventBus.dispatch("print");
  });
}

// add private functions to modify data
function createPdf(print = false, opts = {}) {
 console.log("pdf opts", opts)
  let includeScheibe = opts.includeScheibe === true;
  pdfMake.fonts = {
    joey: {
      normal: "FS-Joey-Regular.ttf",
      medium: "FS-Joey-Medium.ttf",
      bold: "FS-Joey-Bold.ttf",
    },
    roboto: {
      normal: "Roboto-Regular.ttf",
    },
  };
  let geo = GeometrieStore.getAll();
  let gd = GrundDatenStore.getAll();
  let eg = GrundDatenStore.getEingaben();
  let last = BelastungStore.getAll();
  let bewl = CalcStore.getSelectedBewehrung();
  let scb = Scheibenstore.getAll();
  //  let minput = CalcStore.getInput();
  if (last.kategorien.selected.Kategorie == "0") {
    // alert ("Bitte wählen Sie eine Belastung aus.");
    // hashHistory.push('/calc/belastung');
    //  return;
  }

  if (bewl.length == 0) {
    alert("Bitte wählen Sie eine Bewehrung aus.");
    // hashHistory.push('/calc/geometrie');
    return;
  }

  let bew = bewl[0];
  console.log("bew", bew);

  // playground requires you to assign document definition to a variable called dd
  var genhline2 = function () {
    return [
      { type: "line", x1: 0, y1: 0, x2: 595 - 60, y2: 0, lineWidth: 0.5 },
    ];
  };

  var genLasten = function () {
    let l = BelastungStore.getZusatz().data;

    let tbl = {
      headerRows: 1,
      header: [
        { text: "Lastart" },
        { text: "Abstand" },
        { text: "Länge" },
        { text: ["F", { text: "Q", style: "subtext", fontSize: 7 }] },
        { text: ["F", { text: "G", style: "subtext", fontSize: 7 }] },
      ],
      body: (function () {
        let mp = [];
        _.each(l, function (lst) {
          if (lst.enabled) {
            mp.push([
              { text: _.get(lst, "lastart.lastoptionausgabe") },
              { text: _.get(lst, "abstand.xe.value").toFixed(2) },
              { text: _.get(lst, "lb.tx.value").toFixed(2) },
              { text: _.get(lst, "groesse.fqk.value").toFixed(2) },
              { text: _.get(lst, "groesse.fgk.value").toFixed(2) },
            ]);
            let lt = lst.lastverteilung;
            let bmtxt = [
              "b",
              { text: "mA", style: "subtext", fontSize: 7 },
              "/b",
              { text: "mM", style: "subtext", fontSize: 7 },
              "/b",
              { text: "mB", style: "subtext", fontSize: 7 },
              ": ",
              lt.bma.value.toFixed(2) +
                "/" +
                lt.bmm.value.toFixed(2) +
                "/" +
                lt.bmb.value.toFixed(2) +
                " m",
            ];
            console.log("bmtxt ", bmtxt);
            mp.push([
              "",
              { text: bmtxt, style: "lasttextsm", colSpan: 4 },
              "",
              "",
              "",
            ]);
            if (_.get(lst, "info.text") != "") {
              mp.push([
                "",
                {
                  text: _.get(lst, "info.text"),
                  colSpan: 4,
                  style: "lasttext",
                },
                "",
                "",
                "",
              ]);
            }
          }
        });
        mp.unshift([
          { text: "Lastart" },
          { text: "Abstand" },
          { text: "Länge" },
          { text: ["F", { text: "Q", style: "subtext", fontSize: 7 }] },
          { text: ["F", { text: "G", style: "subtext", fontSize: 7 }] },
        ]);
        return mp;
      })(),
      layout: "headerLineOnly",
      margin: [0, 0, 0, 5],
      style: "tableStyle",
    };

    return {
      stack: [
        { text: "Zusatzlasten", style: "subheader" },
        {
          columns: [
            { table: tbl, layout: "headerLineOnly", style: "tableStyle" },
            { image: "lasten", width: 300, margin: [0, 0] },
          ],
        },
      ],
      margin: [0, 10, 0, 0],
    };
  };

  let genAuflagerTyp = function (alselector) {
    let alselected =
      alselector == "a"
        ? geo.geometriewerte.auflager.AlAselected
        : geo.geometriewerte.auflager.AlBselected;
    let alvalues =
      alselector == "a"
        ? geo.geometriewerte.biegeweich.auflagerA
        : geo.geometriewerte.biegeweich.auflagerB;
    if (alselected.key == "mauer") {
      return "Biegesteif";
    }
    if (alselected.key == "_50prozent") {
      return "Biegeweich 50%";
    }
    if (
      alselected.key != "stahltraeger" &&
      alselected.key != "peikko" &&
      alselected.key != "pfeifer"
    ) {
      if (alvalues.im == 0) {
        return (
          alselected.label +
          " " +
          (alvalues.b * 100).toFixed(0) +
          "/" +
          (alvalues.h * 100).toFixed(0) +
          "\n" +
          alvalues.alTypes.beton.selected.name
        );
      } else {
        return alselected.label;
      }
    } else {
      if (alvalues.im == 0) {
        if (alselected.key == "stahltraeger") {
          return alselected.label + "\n" + alvalues.alTypes.stahl.selected.name;
        } else if (alselected.key == "peikko") {
          return (
            alselected.label + "\n" + alvalues.alTypes.peikko.selected.name
          );
        } else {
          return (
            alselected.label + "\n" + alvalues.alTypes.pfeifer.selected.name
          );
        }
      } else {
        return alselected.label;
      }
    }
  };

  let genAuflagerRows = () => {
    let isAbw = geo.geometriewerte.auflager.AlAselected.key != "mauer";
    let isBbw = geo.geometriewerte.auflager.AlBselected.key != "mauer";
    if (!isAbw && !isBbw) {
      return [
        [
          { text: "Feld", bold: true },
          "Feldbreite",
          {
            text: geo.geometriewerte.feldbreite.toFixed(2),
            alignment: "right",
          },
          "m",
          { text: "Auflager", bold: true },
          "",
          { text: "A", italic: true },
          "",
          { text: "B", italic: true },
          "",
        ],
        [
          "",
          "Lichte Weite",
          { text: geo.geometriewerte.feldweite.toFixed(2), alignment: "right" },
          "m",
          "Auflagertiefe",
          "",
          {
            text: geo.geometriewerte.auflager.auflagerA.toFixed(2),
            alignment: "left",
          },
          "m",
          {
            text: geo.geometriewerte.auflager.auflagerB.toFixed(2),
            alignment: "left",
          },
          "m",
        ],
        [
          "",
          "Stützweite",
          {
            text: geo.geometriewerte.stuetzweite.toFixed(2),
            alignment: "right",
          },
          "m",
          "Typ",
          "",
          { text: genAuflagerTyp("a"), colSpan: 2, fontSize: 9 },
          "",
          { text: genAuflagerTyp("b"), colSpan: 2, fontSize: 9 },
          "",
        ],
      ];
    } else {
      return [
        [
          { text: "Feld", bold: true },
          "Feldbreite",
          {
            text: geo.geometriewerte.feldbreite.toFixed(2),
            alignment: "right",
          },
          "m",
          { text: "Auflager", bold: true },
          "",
          { text: "A", italic: true },
          "",
          { text: "B", italic: true },
          "",
        ],
        [
          "",
          "Lichte Weite",
          { text: geo.geometriewerte.feldweite.toFixed(2), alignment: "right" },
          "m",
          "Auflagertiefe",
          "",
          {
            text: geo.geometriewerte.auflager.auflagerA.toFixed(2),
            alignment: "left",
          },
          "m",
          {
            text: geo.geometriewerte.auflager.auflagerB.toFixed(2),
            alignment: "left",
          },
          "m",
        ],
        [
          "",
          "Stützweite",
          {
            text: geo.geometriewerte.stuetzweite.toFixed(2),
            alignment: "right",
          },
          "m",
          "Typ",
          "",
          { text: genAuflagerTyp("a"), colSpan: 2, fontSize: 9 },
          "",
          { text: genAuflagerTyp("b"), colSpan: 2, fontSize: 9 },
          "",
        ],
        [
          "",
          "",
          "",
          "",
          "E*I",
          "",
          {
            text: isAbw
              ? geo.geometriewerte.biegeweich.auflagerA.ei.toFixed(0)
              : "-",
          },
          "MNm²",
          {
            text: isBbw
              ? geo.geometriewerte.biegeweich.auflagerB.ei.toFixed(0)
              : "-",
          },
          "MNm²",
        ],
        [
          "",
          "",
          "",
          "",
          "Breite Fuge",
          "",
          {
            text: isAbw
              ? geo.geometriewerte.biegeweich.auflagerA.breite.toFixed(2)
              : "-",
          },
          "m",
          {
            text: isBbw
              ? geo.geometriewerte.biegeweich.auflagerB.breite.toFixed(2)
              : "-",
          },
          "m",
        ],
        [
          "",
          "",
          "",
          "",
          "Stützweite Momentennullpunkte",
          "",
          {
            text: isAbw
              ? geo.geometriewerte.biegeweich.auflagerA.abstand.toFixed(2)
              : "-",
          },
          "m",
          {
            text: isBbw
              ? geo.geometriewerte.biegeweich.auflagerB.abstand.toFixed(2)
              : "-",
          },
          "m",
        ],
      ];
    }
  };

  let genAuflagerVergleich = () => {
    let isAbw = geo.geometriewerte.auflager.AlAselected.key != "mauer";
    let isBbw = geo.geometriewerte.auflager.AlBselected.key != "mauer";
    let afootnotes = isAbw
      ? geo.geometriewerte.auflager.AlAselected.key == "_50prozent"
        ? "**"
        : "*"
      : "***";
    let bfootnotes = isBbw
      ? geo.geometriewerte.auflager.AlBselected.key == "_50prozent"
        ? "**"
        : "*"
      : "***";
    let atraegerText = {
      text: isAbw
        ? "Unterstützung der Träger: " +
          (geo.geometriewerte.biegeweich.auflagerA.tragerunterstuetzung
            ? "Ja"
            : "Nein")
        : "",
    };
    let btraegerText = {
      text: isBbw
        ? "Unterstützung der Träger: " +
          (geo.geometriewerte.biegeweich.auflagerB.tragerunterstuetzung
            ? "Ja"
            : "Nein")
        : "",
    };
    let averbText = {
      text: isAbw
        ? "Verbundsteigernde Massnahmen: " +
          (geo.geometriewerte.biegeweich.auflagerA.verbundsteigernd
            ? "Ja"
            : "Nein")
        : "",
    };
    let bverbText = {
      text: isBbw
        ? "Verbundsteigernde Massnahmen: " +
          (geo.geometriewerte.biegeweich.auflagerB.verbundsteigernd
            ? "Ja"
            : "Nein")
        : "",
    };
    let atraegerPosText = {
      text: isAbw
        ? "Trägerposition: " +
          (geo.geometriewerte.biegeweich.auflagerA.traegerpos == "mitte"
            ? "Mitte"
            : "Rand")
        : "",
    };
    let btraegerPosText = {
      text: isBbw
        ? "Trägerposition: " +
          (geo.geometriewerte.biegeweich.auflagerB.traegerpos == "mitte"
            ? "Mitte"
            : "Rand")
        : "",
    };
    return [
      [
        "Auflager A",
        { text: ["V", { text: "Ed,ct1", style: "subtext" }] },
        {
          text: Utils.round(bew.VEdct1).toString(),
          alignment: "right",
          paddingLeft: 0,
        },
        { text: "kN/m", alignment: "left", paddingLeft: 0 },
        "<=",
        {
          text: [
            "V",
            { text: isAbw ? "Rd,ct1,bw" : "Rd,ct1", style: "subtext" },
          ],
        },
        Utils.round(isAbw ? bew.VRdct1_bwA : bew.VRdct1).toString(),
        "kN/m",
        "=",
        "Ausnutzung",
        Utils.round(
          (bew.VEdct1 / (isAbw ? bew.VRdct1_bwA : bew.VRdct1)) * 100
        ).toFixed(1) +
          "% " +
          afootnotes,
      ],
      [
        "",
        { text: ["V", { text: "Ed,fi", style: "subtext" }] },
        { text: bew.VEdfiA.toFixed(2), alignment: "right", paddingLeft: 0 },
        { text: "kN/m", alignment: "left", paddingLeft: 0 },
        "<=",
        { text: ["V", { text: "Rd,fi", style: "subtext" }] },
        bew.VRdF90.toFixed(2),
        "kN/m",
        "=",
        "Ausnutzung",
        Utils.round((bew.VEdfiA / bew.VRdF90) * 100).toFixed(1) + "%",
      ],
      [
        "",
        { text: ["V", { text: "Ed,ct2", style: "subtext" }] },
        { text: bew.VEdct2A.toFixed(2), alignment: "right", paddingLeft: 0 },
        { text: "kN/m", alignment: "left", paddingLeft: 0 },
        "<=",
        { text: ["V", { text: "Rd,ct2", style: "subtext" }] },
        bew.VRdct2.toFixed(2),
        "kN/m",
        "=",
        "Ausnutzung",
        Utils.round((bew.VEdct2A / bew.VRdct2) * 100).toFixed(1) + "%",
      ],
      [
        "",
        { text: atraegerText.text, colSpan: 5, fontSize: 9 },
        "",
        "",
        "",
        "",
        "",
        { text: averbText.text, colSpan: 4, fontSize: 9, alignment: "left" },
        "",
        "",
        "",
      ],
      [
        "",
        { text: atraegerPosText.text, colSpan: 5, fontSize: 9 },
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],

      [
        "Auflager B",
        { text: ["V", { text: "Ed,ct1", style: "subtext" }] },
        {
          text: Utils.round(bew.VEdct1A2).toString(),
          alignment: "right",
          paddingLeft: 0,
        },
        { text: "kN/m", alignment: "left", paddingLeft: 0 },
        "<=",
        {
          text: [
            "V",
            { text: isBbw ? "Rd,ct1,bw" : "Rd,ct1", style: "subtext" },
          ],
        },
        Utils.round(isBbw ? bew.VRdct1_bwB : bew.VRdct1A2).toString(),
        "kN/m",
        "=",
        "Ausnutzung",
        Utils.round(
          (bew.VEdct1A2 / (isBbw ? bew.VRdct1_bwB : bew.VRdct1A2)) * 100
        ).toFixed(1) +
          "% " +
          bfootnotes,
      ],
      [
        "",
        { text: ["V", { text: "Ed,fi", style: "subtext" }] },
        { text: bew.VEdfiB.toFixed(2), alignment: "right", paddingLeft: 0 },
        { text: "kN/m", alignment: "left", paddingLeft: 0 },
        "<=",
        { text: ["V", { text: "Rd,fi", style: "subtext" }] },
        bew.VRdF90.toFixed(2),
        "kN/m",
        "=",
        "Ausnutzung",
        Utils.round((bew.VEdfiB / bew.VRdF90) * 100).toFixed(1) + "%",
      ],
      [
        "",
        { text: ["V", { text: "Ed,ct2", style: "subtext" }] },
        { text: bew.VEdct2B.toFixed(2), alignment: "right", paddingLeft: 0 },
        { text: "kN/m", alignment: "left", paddingLeft: 0 },
        "<=",
        { text: ["V", { text: "Rd,ct2", style: "subtext" }] },
        bew.VRdct2.toFixed(2),
        "kN/m",
        "=",
        "Ausnutzung",
        Utils.round((bew.VEdct2B / bew.VRdct2) * 100).toFixed(1) + "%",
      ],
      [
        "",
        { text: btraegerText.text, colSpan: 5, fontSize: 9 },
        "",
        "",
        "",
        "",
        "",
        { text: bverbText.text, colSpan: 4, fontSize: 9, alignment: "left" },
        "",
        "",
        "",
      ],
      [
        "",
        { text: btraegerPosText.text, colSpan: 5, fontSize: 9 },
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
    ];
  };

  let genFootnotes = function () {
    let isAbw = geo.geometriewerte.auflager.AlAselected.key != "mauer";
    let isBbw = geo.geometriewerte.auflager.AlBselected.key != "mauer";
    let afootnotes = isAbw
      ? geo.geometriewerte.auflager.AlAselected.key == "_50prozent"
        ? "**"
        : "*"
      : "***";
    let bfootnotes = isBbw
      ? geo.geometriewerte.auflager.AlBselected.key == "_50prozent"
        ? "**"
        : "*"
      : "***";
    let out = [];
    let fns = {
      "*": {
        style: "footnotes",
        text: "*Die Nachweisführung zur erhöhten Querkraftausnutzung des biegeweichen Auflagers wurde nach der Industrierichtlinie\n (Modell von Dr. Roggendorf, Büro Hegger und Partner) erstellt",
        margin: [0, 0],
      },
      "**": {
        style: "footnotes",
        text: "**Der Nachweis der Querkraft des biegeweichen Auflagers wurde vereinfacht nach der 50%-Regel geführt.",
        margin: [0, 0],
      },
      "***": {
        style: "footnotes",
        text: "***Es wurde ein biegesteifes Auflager angenommen.",
        margin: [0, 0],
      },
    };
    if (afootnotes == bfootnotes) {
      out.push(fns[afootnotes]);
    } else {
      out.push(fns[afootnotes], fns[bfootnotes]);
    }
    return out;
  };

  let genScheiben = function () {
    if(!includeScheibe){return []}
    return [
      {
        pageBreak: "before",

        stack: [
          {
            stack: [
              { image: "dwlogo.png", width: 150 },
              {
                stack: [
                  { text: "DW SYSTEMBAU GMBH" },
                  { text: "Stockholmer Straße 1" },
                  { text: "29640 Schneverdingen\n\n" },
                  { text: "Fon +49(0) 5193 85 0\n\n" },
                  { text: "www.dw-systembau.de" },
                  { text: "info@dw-systembau.de" },
                ],
                margin: [50, 10, 0, 0],
                alignment: "left",
                style: "adresse",
              },
            ],
            alignment: "right",
            margin: [400, 0, 0, 0],
          },
          {
            text: [
              "BV: " + eg.bauvorhaben + " - Position: " + eg.bauposition + "\n",
              { text: "", style: "subheader" },
            ],
            style: "header",
            margin: [0, 0, 0, 3],
          },
          { canvas: genhline2(), margin: [0, 3] },
        ],
      },
      {
        text: "Scheibennachweis\n\n",
        style: "subheader",
        margin: [0, 0, 0, 0],
      },
      {
        columns: [
          {
            table: {
              widths: [110, 30, 70],
              body: [
                [
                  { text: "System ", bold: true },
                  "",
                  scb.system.selected.label,
                ],
                [
                  { text: "Plattenausrichtung ", bold: true },
                  "",
                  scb.ausrichtung.selected.label,
                ],
                [
                  { text: "Scheibenlänge", bold: true },
                  { text: "L =", bold: true },
                  _.round(scb.sL, 2),
                ],
                [
                  { text: "Scheibenhöhe", bold: true },
                  { text: "H =", bold: true },
                  _.round(scb.sH, 2).toFixed(2),
                ],
                [
                  { text: "Abstand Zugpfosten", bold: true },
                  { text: "az =", bold: true },
                  _.round(scb.az, 2).toFixed(2),
                ],
                [
                  { text: "Scheibenbelastung", bold: true },
                  { text: "qd =", bold: true },
                  _.round(scb.qd, 2).toFixed(2),
                ],
                [
                  { text: "Max. Scheibenmoment", bold: true },
                  { text: "Md =", bold: true },
                  _.round(scb.Md, 2).toFixed(2),
                ],
                [
                  { text: "Auflagerkraft", bold: true },
                  { text: "Vd =", bold: true },
                  _.round(scb.Vd, 2).toFixed(2),
                ],
              ],
            },
            layout: "noBorders",
            margin: [0, 5],
            style: "tableStyle",
          },
          {
            stack: [
              {
                image: "scheibe",
                width: 200,
                margin: [0, 5],
              },
            ],
          },
        ],
      },

      { text: " ", margin: [0, 5] },
      {
        table: {
          widths: [20, 110, 70, 30, 30, 60, 30, 20],
          body: [
            [
              {
                text: "1. Bemessung Zuggurt und Druckstrebe",
                bold: true,
                colSpan: 5,
              },
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ],
            [
              "",
              { text: "Hebelarm Scheibe" },
              { text: "z =", bold: true, alignment: "right" },
              { text: _.round(scb.z, 2).toFixed(2), alignment: "left" },
              { text: "m", alignment: "left" },
              { text: "", bold: true, alignment: "right" },
              { text: "", alignment: "left" },
              { text: "", alignment: "left" },
            ],
            [
              "",
              { text: "Druck-/Zuggurtkraft" },
              { text: "FEd = Md/z =	", bold: true, alignment: "right" },
              { text: _.round(scb.FEd1, 2).toFixed(2), alignment: "left" },
              { text: "kN", alignment: "left" },
              { text: "> FEd,min =	", bold: true, alignment: "right" },
              { text: _.round(scb.FEdmin1, 2).toFixed(2), alignment: "left" },
              { text: "Kn", alignment: "left" },
            ],
            [
              "",
              { text: "Ringankerbewehrung" },
              { text: "erf Asd,1 = ", bold: true, alignment: "right" },
              { text: _.round(scb.Asd1, 2).toFixed(2), alignment: "left" },
              { text: "cm ²", alignment: "left" },
              { text: "", bold: true, alignment: "right" },
              { text: "", alignment: "left" },
              { text: "", alignment: "left" },
            ],
            [
              "",
              { text: "Druckstrebe" },
              { text: "cotθ = az/z = ", bold: true, alignment: "right" },
              { text: _.round(scb.cot, 2).toFixed(2), alignment: "left" },
              { text: "-", alignment: "left" },
              { text: "", bold: true, alignment: "right" },
              { text: "", alignment: "left" },
              { text: "", alignment: "left" },
            ],
            [
              "",
              { text: "Nachweis Druckstrebe" },
              { text: "VRd,max = ", bold: true, alignment: "right" },
              { text: _.round(scb.VRdmax, 2).toFixed(2), alignment: "left" },
              { text: "-", alignment: "left" },
              { text: "", bold: true, alignment: "right" },
              { text: "", alignment: "left" },
              { text: "", alignment: "left" },
            ],
            [
              "",
              { text: "Fugenbeton (mind. C20/25)" },
              { text: "Vd/VRd = ", bold: true, alignment: "right" },
              { text: _.round(scb.VV, 2).toFixed(2), alignment: "left" },
              { text: "-", alignment: "left" },
              { text: "", bold: true, alignment: "right" },
              { text: "", alignment: "left" },
              { text: "", alignment: "left" },
            ],
            [
              {
                text: "2. Bemessung Zugpfosten",
                bold: true,
                colSpan: 5,
              },
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ],
            [
              "",
              { text: "Max. Pfostenkraft" },
              { text: "FEd = ", bold: true, alignment: "right" },
              { text: _.round(scb.FEd2, 2).toFixed(2), alignment: "left" },
              { text: "kN", alignment: "left" },
              { text: "> FEd,min = ", bold: true, alignment: "right" },
              { text: _.round(scb.FEdmin2, 2).toFixed(2), alignment: "left" },
              { text: "kN", alignment: "left" },
            ],
            [
              "",
              { text: "Zugpfostenbewehrung" },
              { text: "erf Asd,2 = ", bold: true, alignment: "right" },
              { text: _.round(scb.Asd2, 2).toFixed(2), alignment: "left" },
              { text: "cm ²", alignment: "left" },
              { text: "", bold: true, alignment: "right" },
              { text: "", alignment: "left" },
              { text: "", alignment: "left" },
            ],
            [
              {
                text: "3. Bemessung Fugen",
                bold: true,
                colSpan: 5,
              },
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ],
            [
              "",
              { text: "Fugenkraft" },
              { text: "FEd = ", bold: true, alignment: "right" },
              { text: _.round(scb.FEd3, 2).toFixed(2), alignment: "left" },
              { text: "kN", alignment: "left" },
              { text: "≤ FEd,min = ", bold: true, alignment: "right" },
              { text: _.round(scb.FEdmin3, 2).toFixed(2), alignment: "left" },
              { text: "kN", alignment: "left" },
            ],
            [
              "",
              { text: "Fugenbewehrung" },
              { text: "erf Asd,3 = ", bold: true, alignment: "right" },
              { text: _.round(scb.Asd3, 2).toFixed(2), alignment: "left" },
              { text: "cm ²", alignment: "left" },
              { text: "", bold: true, alignment: "right" },
              { text: "", alignment: "left" },
              { text: "", alignment: "left" },
            ],
            [
              {
                text: "4. Anschluss an aussteifendes Bauteil",
                bold: true,
                colSpan: 5,
              },
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ],
            [
              "",
              { text: "Anschlusskraft" },
              { text: "FEd = ", bold: true, alignment: "right" },
              { text: _.round(scb.FEd4, 2).toFixed(2), alignment: "left" },
              { text: "kN", alignment: "left" },
              { text: "> FEd,min = ", bold: true, alignment: "right" },
              { text: _.round(scb.FEdmin4, 2).toFixed(2), alignment: "left" },
              { text: "kN", alignment: "left" },
            ],
            [
              "",
              { text: "Ringankerbewehrung" },
              { text: "erf Asd,4 = ", bold: true, alignment: "right" },
              { text: _.round(scb.Asd4, 2).toFixed(2), alignment: "left" },
              { text: "cm ²", alignment: "left" },
              { text: "", bold: true, alignment: "right" },
              { text: "", alignment: "left" },
              { text: "", alignment: "left" },
            ],
            [
              {
                text: "5. Querkraftübertragung",
                bold: true,
                colSpan: 5,
              },
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ],
            [
              "",
              { text: "Maximale Querkraft" },
              { text: "vEd = ", bold: true, alignment: "right" },
              { text: _.round(scb.vEd, 2).toFixed(2), alignment: "left" },
              { text: "kN/m", alignment: "left" },
              { text: "", bold: true, alignment: "right" },
              { text: "", alignment: "left" },
              { text: "", alignment: "left" },
            ],
            [
              "",
              { text: "Max. Querkraft ohne Verdübelung" },
              { text: "vRd,ct =	", bold: true, alignment: "right" },
              { text: _.round(scb.vRdct, 2).toFixed(2), alignment: "left" },
              { text: "kN/m", alignment: "left" },
              { text: "", bold: true, alignment: "right" },
              { text: "", alignment: "left" },
              { text: "", alignment: "left" },
            ],
            [
              "",
              { text: "Dübelbewehrung" },
              { text: "erf Asd,5 = ", bold: true, alignment: "right" },
              { text: _.round(scb.Asd5, 2).toFixed(2), alignment: "left" },
              { text: "cm ²", alignment: "left" },
              { text: "", bold: true, alignment: "right" },
              { text: "", alignment: "left" },
              { text: "", alignment: "left" },
            ],
          ],
        },
        layout: "noBorders",
        style: "tableStyle",
      },
      { canvas: genhline2(), margin: [0, 10] },
      {
        style: "footnotes",
        text: "[1] DIN EN 1168 : 2011-12 und DIN EN 1992-1-1 : 2011-01 System BRESPA®\n[2] DIN EN 1992-1-1 : 2011 und DIN EN 1992-1-1/NA : 2013\n[3] DAfStb-Heft 600\n[4] DAfStb-Heft 599\n[5] DAfStb-Heft 224\n[6] DAfStb-Heft 288",
      },
    ];
  };

  let calculationen = [
    { text: "Systemmaße\n\n", style: "subheader", margin: [0, 0, 0, 0] },
    {
      table: {
        widths: [50, 70, 50, 50, 60, 20, 40, 30, 40, 20],
        body: genAuflagerRows(),
      },
      layout: "noBorders",
      style: "tableStyle",
    },
    { canvas: genhline2(), margin: [0, 5] },

    { text: "Einwirkungen\n\n", style: "subheader", margin: [0, 0] },

    {
      table: {
        widths: [50, 90, 50, 50, 50, 50],
        body: [
          [
            { text: "Kategorie", bold: true },
            {
              text:
                last.kategorien.selected.Kategorie +
                " - " +
                last.kategorien.selected.Text,
              colSpan: 2,
            },
            "",
            { text: "" },
            "",
            "",
          ],
          [
            { text: "Feld", bold: true },
            { text: ["Verkehrslast q", { text: "1", style: "subtext" }, ":"] },
            {
              text: last.feld.verkehrslast.selected.val.toFixed(2),
              alignment: "right",
            },
            "kN/m²",
            "",
            "",
          ],
          [
            "",
            { text: ["LW-Zuschlag q", { text: "2", style: "subtext" }, ":"] },
            {
              text: last.feld.lwzuschlag.selected.val.toFixed(2),
              alignment: "right",
            },
            "kN/m²",
            "",
            "",
          ],
          [
            "",
            { text: ["Zus. Auflast g", { text: "1", style: "subtext" }, ":"] },
            "",
            "",
            {
              text: last.feld.auflast.selected.val.toFixed(2),
              alignment: "right",
            },
            "kN/m²",
          ],
          [
            "",
            "Eigenlast Decke g",
            "",
            "",
            { text: bew.SelfWeight.toFixed(2), alignment: "right" },
            "kN/m²",
          ],
          [
            "",
            "Belastung q/g",
            {
              text: (
                last.feld.verkehrslast.selected.val +
                last.feld.lwzuschlag.selected.val
              ).toFixed(2),
              alignment: "right",
            },
            "kN/m²",
            {
              text: (last.feld.auflast.selected.val + bew.SelfWeight).toFixed(
                2
              ),
              alignment: "right",
            },
            "kN/m²",
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          if (i === node.table.body.length) {
            return 2;
          }
          if (i === node.table.body.length - 1) {
            return 1;
          }
        },
        vLineWidth: function () {
          return 0;
        },
      },
      style: "tableStyle",
    },
    genLasten(),

    {
      pageBreak: "before",

      stack: [
        {
          stack: [
            { image: "dwlogo.png", width: 150 },
            {
              stack: [
                { text: "DW SYSTEMBAU GMBH" },
                { text: "Stockholmer Straße 1" },
                { text: "29640 Schneverdingen\n\n" },
                { text: "Fon +49(0) 5193 85 0\n\n" },
                { text: "www.dw-systembau.de" },
                { text: "info@dw-systembau.de" },
              ],
              margin: [50, 10, 0, 0],
              alignment: "left",
              style: "adresse",
            },
          ],
          alignment: "right",
          margin: [400, 0, 0, 0],
        },
        {
          text: [
            "BV: " + eg.bauvorhaben + " - Position: " + eg.bauposition + "\n",
            { text: "", style: "subheader" },
          ],
          style: "header",
          margin: [0, 0, 0, 10],
        },
        { canvas: genhline2(), margin: [0, 10] },
      ],
    },
    {
      table: {
        widths: [130, 90, 50, 90, 50],
        body: [
          [
            { text: "Teilsicherheitsbeiwerte", bold: true },
            "gamma q:",
            { text: last.feld.gammaQ.selected.label, alignment: "left" },
            { text: "gamma g:" },
            { text: last.feld.gammaG.selected.label, alignment: "left" },
          ],

          [
            { text: "Kombinationsbeiwerte", bold: true },
            { text: ["phi", { text: "1", style: "subtext" }] },
            { text: last.feld.psi1.val.toFixed(2), alignment: "left" },
            { text: ["phi", { text: "2", style: "subtext" }] },
            { text: last.feld.psi2.val.toFixed(2), alignment: "left" },
          ],
          [
            { text: "Expositionsklasse", bold: true },
            gd.deckenWerte.expoklasse.selected.label,
            "",
            "",
            "",
          ],
          [{ text: "Feuerwiderstandsklasse", bold: true }, "F90", "", "", ""],
        ],
      },
      layout: "noBorders",
      margin: [0, 20],
      style: "tableStyle",
    },
    { canvas: genhline2(), margin: [0, 10] },
    { text: "Bemessung\n", style: "subheader", margin: [0, 10] },
    {
      columns: [
        {
          stack: [
            { text: "Bewehrung " + bewl[0].Bewehrung, style: "bewehrung" },
            {
              image: "deckenskizze",
              width: 300,
              margin: [0, 5],
            },
          ],
        },
        {
          table: {
            widths: [110, 150],
            body: [
              ["Deckenart:", gd.deckenWerte.deckentyp.selected.Deckenart],
              ["Deckentyp:", gd.deckenWerte.decken.selected.Plattentype],
              ["Deckendicke:", gd.deckenWerte.decken.selected.dicke + "cm"],
              ["az,oben(mm²/m):", bew.azOben.toFixed(2)],
              ["az,unten(mm²/m):", bew.azUnten.toFixed(2)],
            ],
          },
          layout: "noBorders",
          margin: [0, 20],
          style: "tableStyle",
        },
      ],
    },
    { canvas: genhline2(), margin: [0, 10] },

    {
      text: "Nachweis der Biegetragfähigkeit",
      style: "subheader",
      margin: [0, 0, 0, 0],
    },

    {
      table: {
        body: [
          [
            "Feldmoment",
            { text: ["M", { text: "Ed", style: "subtext" }] },
            {
              text: Utils.round(bew.MEd) + "",
              alignment: "right",
              paddingLeft: 0,
            },
            { text: "kNm/m", alignment: "left", paddingLeft: 0 },
            "<=",
            { text: ["M", { text: "Rd", style: "subtext" }] },
            { text: Utils.round(bew.MRd) + "", alignment: "right" },
            { text: "kNm/m", alignment: "left" },
            "=",
            "Ausnutzung",
            Utils.round((bew.MEd / bew.MRd) * 100).toFixed(1) + "%",
          ],
        ],
      },
      layout: {
        hLineWidth: function () {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        },

        paddingLeft: function (i) {
          return i === 3 || i === 7 ? 1 : 4;
        },
        paddingRight: function (i) {
          return i === 6 || i === 2 ? 1 : 4;
        },
      },
      margin: [0, 0, 0, 10],
      style: "tableStyle",
    },
    {
      text: "Nachweis der Querkraftfähigkeit",
      style: "subheader",
      margin: [0, 10],
    },

    {
      table: {
        body: genAuflagerVergleich(),
      },
      layout: {
        hLineWidth: function () {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        },

        paddingLeft: function (i) {
          return i === 3 || i === 7 ? 1 : 4;
        },
        paddingRight: function (i) {
          return i === 6 || i === 2 ? 1 : 4;
        },
      },
      margin: [0, 0, 0, 10],
      style: "tableStyle",
    },
    { text: "Durchbiegung", style: "subheader", margin: [0, 0] },

    {
      table: {
        body: [
          [
            "vorhandene Durchbiegung",
            { text: "(" + bew.durchBformat + ")", alignment: "left" },
            "=",
            { text: bew.durchbiegungEmm.toFixed(2) + " mm", alignment: "left" },
            "zul. Durchbiegung",
            { text: last.feld.durchbiegung.selected.label, alignment: "left" },
            "=",
            { text: bew.durchbiegungRmm.toFixed(2) + " mm", alignment: "left" },
          ],
          /*,
        [ 'vorhandener Durchhang',{text:'('+ bew.durchHformat+')',alignment:'left'},'=',{text:bew.durchhangEmm.toFixed(2) +' mm',alignment:'left'},
            'zul. Durchhang',{text:last.feld.durchhang.selected.label,alignment:'left'},'=',{text:bew.durchhangRmm.toFixed(2) +' mm',alignment:'left'}
          ]
*/
        ],
      },
      layout: {
        hLineWidth: function () {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        },

        paddingLeft: function (i) {
          return i === 2 || i === 5 ? 1 : 4;
        },
        paddingRight: function (i) {
          return i === 1 || i === 4 ? 1 : 4;
        },
      },
      margin: [0, 0, 0, 10],
      style: "tableStyle",
    },
  ].concat(genFootnotes());

  let pageTop = [
    {
      stack: [
        { text: "Grundlagen", style: "header" },
        {
          text: "\nStatik erstellt nach DIN EN 1168, DIN EN 1992-1-1, DIN EN 1992-1-1/NA für Deutschland, DIN EN 13369, DIN EN 206-1 zusätzlich gelten folgende Merkblätter: Industrierichtlinie und Merkblatt „biegeweiche Auflagerung“ vom Bundesverband Spannbeton-Fertigdecken\n",
        },
        { text: "\nVorbemerkungen:", style: "header" },
        {
          text: "\nSchnittkräfte im Bereich von Einzellasten, Linienlasten und Aussparungen:",
        },
        {
          text: "\nDie mitwirkende Lastverteilungsbreite bei Spannbeton-Fertigdecken darf wie bei monolithischen Betonplatten gemäß DAfStb Heft 631 berechnet werden.",
        },
        {
          text: [
            {
              text: "\nTorsion wird am freien Rand eines Deckenfeldes (vertikal und horizontal nicht unterstützt) berücksichtigt, indem b",
            },
            { text: "mg", style: "subtext" },
            { text: " auf max. 1,00 m reduziert wird." },
          ],
        },
        {
          text: "\nDiagramme zur Ermittlung der Fugenquerkräfte bzw. der anteiligen Lasten benachbarter Platten in einem Deckenfeld für bestimmte Einzel- und Linienlaststellungen sind in DIN EN 1168, Anhang C (informativ) angegeben.",
        },
        {
          text: "\nDie Breite von Aussparungen wird von der mitwirkenden Lastverteilungsbreite abgezogen.",
        },
        {
          text: "\nDa es sich um eine Vorbemessung handelt, kann eine Überschreitung von bis zu 3 % der Ausnutzung toleriert werden.",
        },
        { text: "\nHinweis zur Querkraftbemessung:", style: "header" },
        {
          text: "\nNach DIN EN 1168 muss bei biegeweicher Lagerung der abmindernde Einfluss auf die Querkrafttragfähigkeit berücksichtigt werden. Die genaue Berechnung ist im Merkblatt „Biegeweiche Auflagerung“ des Bundesverbandes Spannbeton-Fertigdecken zu entnehmen. Dieser Nachweis ist zu führen, wenn die Ausnutzung der Querkraft nach DIN EN 1168 Abs. 4.3.3.2.2.2 größer als 50% ist.",
        },
      ],
      margin: [50, 0, 50, 0],
    },
  ];

  let header2 = [
    {
      pageBreak: "before",

      stack: [
        {
          stack: [
            { image: "dwlogo.png", width: 150 },
            {
              stack: [
                { text: "DW SYSTEMBAU GMBH" },
                { text: "Stockholmer Straße 1" },
                { text: "29640 Schneverdingen\n\n" },
                { text: "Fon +49(0) 5193 85 0\n\n" },
                { text: "www.dw-systembau.de" },
                { text: "info@dw-systembau.de" },
              ],
              margin: [50, 10, 0, 0],
              alignment: "left",
              style: "adresse",
            },
          ],
          alignment: "right",
          margin: [400, 0, 0, 0],
        },
        {
          text: [
            "BV: " + eg.bauvorhaben + " - Position: " + eg.bauposition + "\n",
            { text: "", style: "subheader" },
          ],
          style: "header",
          margin: [0, 0, 0, 3],
        },
        { canvas: genhline2(), margin: [0, 3] },
      ],
    },
  ];
  var docDefinition = {
    pageSize: "A4",
    pageMargins: [30, 40, 30, 40],
    footer: function (currentPage, pageCount) {
      return {
        columns: [
          {
            text:
              "Version: " +
              version +
              "/ Stand: " +
              moment().locale("de").format("L"),
            alignment: "left",
            fontSize: 9,
          },
          {
            text: "Seite " + currentPage.toString() + "/" + pageCount,
            alignment: "right",
            fontSize: 9,
          },
        ],
        margin: [80, 0, 80, 0],
      };
    },
    content: [
      {
        stack: [
          { image: "dwlogo.png", width: 150 },
          {
            stack: [
              { text: "DW SYSTEMBAU GMBH" },
              { text: "Stockholmer Straße 1" },
              { text: "29640 Schneverdingen\n\n" },
              { text: "Fon +49(0) 5193 85 0\n\n" },
              { text: "www.dw-systembau.de" },
              { text: "info@dw-systembau.de" },
            ],
            margin: [50, 10, 0, 0],
            alignment: "left",
            style: "adresse",
          },
        ],
        alignment: "right",
        margin: [400, 0, 0, 0],
      },

      {
        text: "Statischer Nachweis für BRESPA®-Decken (Vorbemessung)",
        style: "header",
        margin: [50, 70, 0, 5],
      },
      {
        text: [
          "BV: " + eg.bauvorhaben + " - Position: " + eg.bauposition + "\n",
          { text: "", style: "subheader" },
        ],
        style: "subheader",
        margin: [50, 0, 0, 60],
      },
    ]
      .concat(pageTop)
      .concat(header2)
      .concat(calculationen)
      .concat(genScheiben()),

    defaultStyle: {
      font: "joey",
      fontSize: 12,
    },
    styles: {
      header: {
        fontSize: 13,
        bold: true,
      },
      lasttext: {
        fontsize: 9,

        color: "gray",
      },
      lasttextsm: {
        fontsize: 7,

        color: "gray",
      },

      subheader: {
        fontSize: 12,
        bold: true,
      },
      tableStyle: {
        fontSize: 10,
      },
      fliess: {
        fontSize: 12,
        bold: false,
      },
      footnotes: {
        fontSize: 8,
        bold: false,
      },
      subtext: {
        fontSize: 7,
        bold: false,
      },
      subs: {
        fontSize: 7,
        bold: false,
      },
      wallrow: {
        margin: [0, 10, 0, 0],
      },
      adresse: {
        fontSize: 9,
      },
    },
    images: {
      deckenskizze: GrundDatenStore.getSkizzen().datauri,
      lasten: lastenImage.datauri,
      scheibe: Scheibenstore.getImage(),
    },
  };
  var tmp = [];
  console.log("tmp", tmp.length);
  var isChromium = window.chrome,
    winNav = window.navigator,
    vendorName = winNav.vendor,
    isOpera = winNav.userAgent.indexOf("OPR") > -1,
    isIEedge = winNav.userAgent.indexOf("Edge") > -1,
    isIOSChrome = winNav.userAgent.match("CriOS");

  if (isIOSChrome) {
    // is Google Chrome on IOS
  } else if (
    isChromium !== null &&
    isChromium !== undefined &&
    vendorName === "Google Inc." &&
    isOpera == false &&
    isIEedge == false
  ) {
    // is Google Chrome
  } else {
    // not Google Chrome
  }

  if (print) {
    pdfMake.createPdf(docDefinition).getBuffer(function (buff) {
      printWithPdfJS(buff);
    });
    /*if(isChromium !== null && isChromium !== undefined && vendorName === "Google Inc." && isOpera == false && isIEedge == false) {
     }else{

     window.print();
     }*/
    //
  } else {
    pdfMake.createPdf(docDefinition).download("DW Systembau Statik.pdf");
  }
}

function _drawElements() {
  console.log("pixie", PIXI);
  var lx = CalcStore.getLasten();
  var cl = lx.length >= 3 ? lx.slice(3) : [];
  var l2 = []; //CalcStore.getEigenLasten();

  let gq = CalcStore.getLasten4Picture();
  let rlasten = l2.concat(cl);

  gq.g = gq.g.concat(rlasten);
  gq.q = gq.q.concat(rlasten);

  var lc =
    _.filter(gq.g, function (ig) {
      return ig != 0.0;
    }).length +
    _.filter(gq.q, function (iq) {
      return iq != 0.0;
    }).length;

  // let breite = 1.2;
  let borderright = 130;
  let borderleft = 20;
  let border = borderright + borderleft;
  let laenge = GeometrieStore.getAll().geometriewerte.stuetzweite;
  //  let faktor = laenge / breite;

  let sw = 400;
  let sh = 130 + 27 * lc; //sw * 0.66;
  let drawlaenge = sw - border;
  let renderer = new PIXI.Application({
    width: sw,
    height: sh,
    backgroundColor: 0xffffff,
  }); //new PIXI.CanvasRenderer(sw, sh);
  // renderer.stage.backgroundColor = 0xFFFFFF;
  let mnode = document.createElement("div");
  mnode.id = "renderCanvasPdf";
  mnode.appendChild(renderer.view);
  let stage = new PIXI.Container();
  renderer.stage.addChild(stage);

  var graphics = new PIXI.Graphics();
  graphics.beginFill(0x7fb2f2, 0.0);
  graphics.lineStyle(1, 0x999999, 0.5);
  graphics.drawRect(1, 1, sw - 2, sh - 2);
  graphics.endFill();
  //graphics.lineStyle(1, 0x000000,1);
  //graphics.drawRect((sw - drawlaenge)/2, (sh- drawbreite)/2, drawlaenge, drawbreite);
  stage.addChild(graphics);

  function drawStutzweite(atY) {
    let uparrow1 = new PIXI.Sprite.from(arrowloadtex);
    uparrow1.x = borderleft - uparrow1.width / 2;
    uparrow1.y = atY - 30;
    stage.addChild(uparrow1);
    let uparrow2 = new PIXI.Sprite.from(arrowloadtex);
    uparrow2.x = sw - borderright - uparrow2.width / 2;
    uparrow2.y = atY - 30;
    stage.addChild(uparrow2);
    graphics.lineStyle(1, 0x000000, 1);
    graphics.drawRect(borderleft, atY - 30, drawlaenge, 1);
    let textstutzw = new PIXI.Text(laenge.toFixed(2), {
      fontFamily: "Arial",
      fontSize: 12,
      fill: 0x00,
      align: "center",
    });
    let textw = textstutzw.width;
    textstutzw.y = atY - 15;
    textstutzw.x = (sw - border) / 2 + borderleft - textw / 2;
    stage.addChild(textstutzw);
  }

  function drawArrow(ax, ay, aheight, color, alpha) {
    graphics.lineStyle(1, color, alpha | 1);
    let tmaxy = ay + aheight;
    graphics.moveTo(ax, tmaxy);
    graphics.lineTo(ax + 5, tmaxy - 5);
    graphics.moveTo(ax, tmaxy);
    graphics.lineTo(ax - 5, tmaxy - 5);
    graphics.moveTo(ax, ay);
    graphics.lineTo(ax, tmaxy);
  }

  function fillWithArrows(fstartx, fstarty, fheight, fwidth) {
    let gap = 20;
    var tx = fstartx + gap;
    graphics.lineStyle(1, 0x999999, 1);

    while (tx < fstartx + fwidth) {
      drawArrow(tx, fstarty, fheight, 0x999999, 1);
      tx += gap;
    }
  }

  function renderlasten(mlasten, mstarty, lasttype) {
    var starty = mstarty;
    let ltype = lasttype || "G";
    _.each(mlasten, function (last) {
      let lastvalue = last[ltype];
      if (lastvalue != 0.0) {
        var l = last.Lange;
        let lf = laenge / l;
        let af = laenge / last.Anfang;
        let dl = Math.max(1, drawlaenge / lf);
        graphics.lineStyle(1, 0x999999, 1);
        if (last.Lange != 0.0) {
          graphics.drawRect(drawlaenge / af + borderleft, starty, dl, 20);
        } else {
          drawArrow(drawlaenge / af + borderleft, starty, 20, 0x000000, 1);
        }
        let textval = new PIXI.Text(lastvalue.toFixed(2), {
          fontFamily: "Arial",
          fontSize: 11,
          fill: 0x00,
          align: "right",
        });
        textval.x = sw - borderright + 60 - textval.width;
        textval.y = starty + 10 - textval.height / 2;
        graphics.addChild(textval);
        fillWithArrows(drawlaenge / af + borderleft, starty, 20, dl);
        starty -= 25;
      }
    });
    return starty;
  }
  drawStutzweite(sh);
  let lasty = renderlasten(gq.q, sh - 55, "rawQ");
  let textval = new PIXI.Text("Veränderliche Lasten", {
    fontFamily: "Arial",
    fontSize: 12,
    fontWeight: "bold",
    fill: 0x00,
    align: "left",
  });
  textval.x = borderleft;
  textval.y = lasty - 5;
  graphics.addChild(textval);
  graphics.lineStyle(1, 0x999999, 0.5);
  graphics.drawRect(1, lasty - 12, sw - 2, 1);
  drawStutzweite(lasty - 15);
  let lasty2 = renderlasten(gq.g, lasty - 45 - textval.height - 10, "rawG");
  let textval2 = new PIXI.Text("Ständige Lasten", {
    fontFamily: "Arial",
    fontSize: 12,
    fontWeight: "bold",
    fill: 0x00,
    align: "left",
  });
  textval2.x = borderleft;
  textval2.y = lasty2 - 5;
  graphics.addChild(textval2);

  renderer.render(stage);

  PDFStore.setlastenImage(renderer.view.toDataURL("image/png"));

  renderer.destroy(true);
  // let child = document.getElementById('renderCanvaspdf');
  // mnode.parentNode.removeChild(mnode);
}

// Facebook style store creation.
const PDFStore = assign({}, BaseStore, {
  // public methods used by Controller-View to operate on data
  getAll() {
    return {
      tasks: _data,
    };
  },
  setlastenImage(datauri) {
    lastenImage.datauri = datauri;
  },

  // register store with dispatcher, allowing actions to flow through
  dispatcherIndex: Dispatcher.register(function handleAction(payload) {
    const action = payload.action;

    switch (action.type) {
      case Constants.ActionTypes.CREATE_PDF:
        _drawElements();
        createPdf(false,action.opts);
        PDFStore.emitChange();
        break;
      case Constants.ActionTypes.PRINT_PDF:
        _drawElements();
        createPdf(true,action.opts);
        PDFStore.emitChange();
        break;

      // add more cases for other actionTypes...

      // no default
    }
  }),
});

export default PDFStore;
