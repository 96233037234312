import PropTypes from 'prop-types';
import React from 'react';
var ReactPropTypes = PropTypes;

class LNCheckBox extends React.Component {
    constructor() {
        super();
        this._onChange = this._onChange.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this.state = {};
    }

    _handleChange(ip) {
      this.props.onChange(ip.target.checked);
    }

    _onChange() {
    }

  

    render() {
        let cnames = this.props.classNames || [];
        return (
          <div className={"checkbox " + cnames.join(" ")}>
            <label>
              <input type="checkbox" checked={this.props.checked} onChange={this._handleChange}></input>
            </label>
          </div>
        );
    }
}

export default LNCheckBox;

LNCheckBox.propTypes = {
  id: ReactPropTypes.string,
  label:ReactPropTypes.string,
  onChange:ReactPropTypes.func,
  checked:ReactPropTypes.bool,
    classNames:ReactPropTypes.array
};
